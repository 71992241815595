import { node } from 'prop-types';

import '../style.css';
import './style.css';

const DangerLink = ({ children, ...rest }) => {
  return (
    <a className="link link-danger" {...rest}>
      {children}
    </a>
  );
};

DangerLink.defaultProps = {
  children: null,
};

DangerLink.propTypes = {
  children: node,
};

export default DangerLink;
