import { node } from 'prop-types';

const Page2 = ({ header, children }) => {
  return (
    <>
      <div className="card">
        {header && (
          <div className="card-header" style={{ backgroundColor: '#d9dcff' }}>
            {header}
          </div>
        )}
        <div className="card-body" style={{ backgroundColor: '#F2F3FF' }}>
          <div className="card-text">{children}</div>
        </div>
      </div>
    </>
  );
};

Page2.defaultProps = {
  header: undefined,
  children: undefined,
};

Page2.propTypes = {
  header: node,
  children: node,
};

export default Page2;
