import { Component } from 'react';
import { node } from 'prop-types';

class ErrorBoundry extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  componentDidCatch(error, info) {
    console.log(error, info);
  }

  render() {
    if (this.state.hasError) {
      return this.props.fallback;
    }

    return this.props.children;
  }
}

ErrorBoundry.propTypes = {
  fallback: node.isRequired,
  children: node.isRequired,
};

export default ErrorBoundry;
