import { useEffect, useState } from 'react';
import { uniqueId } from 'lodash';
import { faSquarePlus } from '@fortawesome/free-solid-svg-icons';
import { Popover } from 'bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { string } from 'prop-types';
import { useNavigate } from 'react-router-dom';

import PrimaryLink from 'components/_common/links/Primary';
import useEventListener from 'hooks/useEventListener';
import SecondaryLink from 'components/_common/links/Secondary';

const AddFiles = ({ id }) => {
  const navigate = useNavigate();
  const [uid] = useState(uniqueId());
  const [popover, setPopover] = useState();

  useEventListener(
    'click',
    function (e) {
      if (e) {
        e.preventDefault();
      }
      //popover.hide();
    },
    document.getElementById('no' + uid)
  );

  useEventListener(
    'click',
    function (e) {
      if (e) {
        e.preventDefault();
      }
      navigate('/upload/' + id + '/clone');
      //popover.hide();
    },
    document.getElementById('yes' + uid)
  );

  useEffect(() => {
    var pop = document.getElementById('popover' + uid);
    var temp = new Popover(pop, {
      sanitize: false,
      container: 'body',
      html: true,
      trigger: 'focus',
      placement: 'bottom',
      content: document.getElementById('popover-content' + uid),
    });

    setPopover(temp);
  }, []);

  return (
    <>
      <PrimaryLink
        href=""
        onClick={e => e.preventDefault()}
        id={'popover' + uid}
        data-bs-toggle="popover"
        data-bs-trigger="focus"
        role="button"
      >
        <FontAwesomeIcon icon={faSquarePlus} size="2x" />
      </PrimaryLink>
      <div hidden>
        <div id={'popover-content' + uid}>
          <div className="row">
            <div className="col-12">
              Are you sure you would like to add more files to this Job?
            </div>
            <div className="row justify-content-center">
              <div className="col-10 border-bottom my-2" />
            </div>
            <div className="row gy-1">
              <div className="col-12 fs-6 fw-bold">
                <PrimaryLink href="" id={'yes' + uid}>
                  Yes
                </PrimaryLink>
              </div>
              <div className="col-12 fs-6 fw-bold">
                <SecondaryLink href="" id={'no' + uid}>
                  No
                </SecondaryLink>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

AddFiles.propTypes = {
  id: string.isRequired,
};

export default AddFiles;
