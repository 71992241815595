import { arrayOf, bool, number, shape, string } from 'prop-types';
import userPropType from './userPropType';

export default shape({
  id: number,
  fileGuid: string,
  batchId: string,
  isCancelled: bool,
  inProcess: bool,
  startProcess: string,
  userId: number,
  user: userPropType,
  analystId: number,
  analyst: userPropType,
  summarizerId: number,
  summarizer: userPropType,
  createdAt: string,
  jobName: string,
  jobType: string,
  message: string,
  isExpress: bool,
  original: string,
  size: number,
  pageCount: number,
  statusId: number,
  summarize: bool,
  jobFiles: arrayOf(
    shape({
      size: number,
      filename: string,
      original: string,
    })
  ),
  parentId: number,
});
