import { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCheckSquare,
  faSquare,
  faEdit,
} from '@fortawesome/free-regular-svg-icons';
import { faUserPlus } from '@fortawesome/free-solid-svg-icons';

import fetchCompanies from 'api/companies/fetchCompanies';
import fetchUsersByCompanyGuid from 'api/users/fetchUsersByCompanyGuid';

import Page2 from 'components/_common/Page2';
import PrimaryLink from 'components/_common/links/Primary';

import EditUser from '../Users/Edit';

const Users = () => {
  const [loading, setLoading] = useState(false);
  const [companies, setCompanies] = useState();
  const [currentCompany, setCurrentCompany] = useState(undefined);
  const [users, setUsers] = useState();
  const [isUserEdit, setIsUserEdit] = useState(false);
  const [selectedUser, setSelectedUser] = useState([]);

  document.title = 'Users | DeepDoc by AAICO';

  useEffect(() => {
    setLoading(true);

    fetchCompanies()
      .then(response => {
        setLoading(false);

        response.sort((a, b) => {
          if (a.name.toLowerCase() > b.name.toLowerCase()) return 1;
          if (a.name.toLowerCase() < b.name.toLowerCase()) return -1;
          return 0;
        });

        setCompanies(response);
        setCurrentCompany(response[0].guidId);
      })
      .catch(() => setLoading(false));
  }, []);

  useEffect(() => {
    if (currentCompany) {
      setLoading(true);
      setUsers([]);

      fetchUsersByCompanyGuid(currentCompany)
        .then(response => {
          setLoading(false);

          if (response) {
            response.sort((a, b) => {
              if (a.email.toLowerCase() > b.email.toLowerCase()) return 1;
              if (a.email.toLowerCase() < b.email.toLowerCase()) return -1;
              return 0;
            });
          }

          setUsers(response);
        })
        .catch(error => {
          setLoading(false);

          if (
            error.response &&
            'status' in error.response &&
            error.response.status === 404
          ) {
            // no users error
            return;
          }
        });
    }
  }, [currentCompany]);

  const handleEditUserClick = (e, userId) => {
    e.preventDefault();

    const filteredUser = users.filter(x => x.id == userId);

    setSelectedUser(filteredUser);
    setIsUserEdit(true);
  };

  const handleAddUserClick = e => {
    e.preventDefault();
    setSelectedUser([]);
    setIsUserEdit(true);
  };

  const setReloadHandler = () => {
    fetchUsersByCompanyGuid(currentCompany)
      .then(response => {
        setLoading(false);
        if (response) {
          response.sort((a, b) => {
            if (a.email.toLowerCase() > b.email.toLowerCase()) return 1;
            if (a.email.toLowerCase() < b.email.toLowerCase()) return -1;
            return 0;
          });
        }

        setUsers(response);
      })
      .catch(error => {
        setLoading(false);

        if (
          error.response &&
          'status' in error.response &&
          error.response.status === 404
        ) {
          // no users error
          return;
        }
      });
  };

  const setUserModeHandler = value => {
    setLoading(true);
    setIsUserEdit(value);
    setLoading(false);
  };

  return (
    !loading && (
      <Page2 header="Users">
        <div className="d-flex flex-column flex-md-row justify-content-between mb-4 pb-md-0">
          {companies && !isUserEdit && (
            <div className="d-flex justify-content-end align-items-center">
              <div className="text-muted fs-6 fw-bold pe-2 text-nowrap">
                Companies
              </div>
              <select
                className="form-select"
                aria-label=".form-select"
                value={currentCompany}
                onChange={e => {
                  e.preventDefault();
                  setCurrentCompany(e.target.value);
                }}
              >
                {companies.map(company => {
                  return (
                    <option key={company.guidId} value={`${company.guidId}`}>
                      {company.name}
                    </option>
                  );
                })}
              </select>
            </div>
          )}
          {!isUserEdit && (
            <div className="align-items-start align-items-md-end">
              <PrimaryLink href="" onClick={handleAddUserClick}>
                <FontAwesomeIcon icon={faUserPlus} /> Add User
              </PrimaryLink>
            </div>
          )}
        </div>
        {users && !isUserEdit && (
          <table className="table">
            <thead>
              <tr>
                <th scope="col">Id</th>
                <th scope="col">Congnito Id</th>
                <th scope="col">First Name</th>
                <th scope="col">Last Name</th>
                <th scope="col">Email</th>
                <th scope="col">Phone</th>
                <th scope="col" className="text-center">
                  isActive
                </th>
                <th />
              </tr>
            </thead>
            <tbody>
              {users.map((user, idx) => (
                <tr key={idx}>
                  <td>{user.id}</td>
                  <td>{user.cognitoId}</td>
                  <td>{user.firstName}</td>
                  <td>{user.lastName}</td>
                  <td>{user.email}</td>
                  <td>{user.phone}</td>

                  <td className="text-center">
                    {!user.isActive && <FontAwesomeIcon icon={faSquare} />}
                    {user.isActive && <FontAwesomeIcon icon={faCheckSquare} />}
                  </td>
                  <td>
                    <PrimaryLink
                      href=""
                      onClick={e => handleEditUserClick(e, user.id)}
                      title="Edit"
                    >
                      <FontAwesomeIcon icon={faEdit} />
                    </PrimaryLink>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        )}
        {isUserEdit && (
          <EditUser
            isUserEdit={isUserEdit}
            user={selectedUser}
            setUserEdit={setUserModeHandler}
            setReload={setReloadHandler}
            getCompanies={companies}
          />
        )}
      </Page2>
    )
  );
};

export default Users;
