import { useRef, useState } from 'react';
import { bool, shape } from 'prop-types';
import { faCheck, faEdit, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import {
  useConfigurationPackage,
  useConfigurationPackageUpdate,
} from 'components/Settings/Configuration/context/configurationPackageContext';

import PrimaryLink from 'components/_common/links/Primary';

const Title = ({ section, readOnly }) => {
  const configurationPackage = useConfigurationPackage();
  const setConfigurationPackage = useConfigurationPackageUpdate();
  const [inEditMode, setInEditMode] = useState(false);
  const nameRef = useRef();

  const sectionToUpdate = configurationPackage.configuration.sections.find(
    item => item.id == section.id
  );

  const handleEditClick = e => {
    e.preventDefault();

    if (readOnly) {
      return;
    }

    setInEditMode(true);
  };

  const handleCancelClick = e => {
    e.preventDefault();

    if (readOnly) {
      return;
    }

    setInEditMode(false);
  };

  const handleSaveClick = e => {
    e.preventDefault();

    if (readOnly) {
      return;
    }

    sectionToUpdate.name = nameRef.current.value;

    setConfigurationPackage(existing => ({
      ...existing,
      ...configurationPackage.configuration,
    }));

    setInEditMode(false);
  };

  if (!inEditMode) {
    return (
      <div>
        {section.name}
        <PrimaryLink
          className={`ps-2 ${readOnly ? 'disabled-link' : ''}`}
          href="#"
          onClick={handleEditClick}
        >
          <FontAwesomeIcon icon={faEdit} />
        </PrimaryLink>
      </div>
    );
  }

  return (
    <div>
      <div className="input-group input-group-sm align-items-center">
        <input
          ref={nameRef}
          type="text"
          className="form-control"
          defaultValue={section.name}
        />
        <PrimaryLink
          className={`ps-3 ${readOnly ? 'disabled-link' : ''}`}
          href="#"
          onClick={handleCancelClick}
          title="Cancel"
        >
          <FontAwesomeIcon icon={faTimes} size="lg" />
        </PrimaryLink>
        <PrimaryLink
          className={`ps-3 ${readOnly ? 'disabled-link' : ''}`}
          href="#"
          onClick={handleSaveClick}
          title="Save"
        >
          <FontAwesomeIcon icon={faCheck} size="lg" />
        </PrimaryLink>
      </div>
    </div>
  );
};

Title.defaultProps = { readOnly: false };

Title.propTypes = {
  section: shape({}).isRequired,
  readOnly: bool,
};

export default Title;
