import { useEffect, useRef, useState } from 'react';
import { number, func } from 'prop-types';
import { toast } from 'react-toastify';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faEdit, faTimes } from '@fortawesome/free-solid-svg-icons';

import fetchConfigDocTypes from 'api/lookup/fetchConfigDocTypes';
import saveConfiguration from 'api/configuration/saveConfiguration';
import fetchDefaultConfigurationByPipelineId from 'api/configuration/fetchDefaultConfigurationByPipelineId';
import fetchConfigurationByConfigId from 'api/configuration/fetchConfigurationByConfigId';

import Primary from 'components/_common/buttons/Primary';
import PrimaryLink from 'components/_common/links/Primary';

import {
  useConfigurationPackage,
  useConfigurationPackageUpdate,
} from '../context/configurationPackageContext';

import DocumentSettings from './DocumentSettings';
import SectionSettings from './SectionSettings';
import DeleteConfiguration from './DeleteConfiguration';

const Content = ({ configId, updateFunc, pipelineId }) => {
  const [readOnly, setReadOnly] = useState(false);
  const [saving, setSaving] = useState(false);

  const [editNameMode, setEditNameMode] = useState(false);
  const configurationPackage = useConfigurationPackage();
  const setConfigurationPackage = useConfigurationPackageUpdate();

  const nameRef = useRef();

  useEffect(() => {
    if (configId && configId > 0) {
      setReadOnly(true);

      fetchConfigurationByConfigId(configId).then(configuration => {
        configuration.sections.sort((a, b) => {
          if (a.position > b.position) return 1;
          if (a.position < b.position) return -1;
          return 0;
        });

        configuration.sections.map(section => {
          section.documents.sort((a, b) => {
            if (a.position > b.position) return 1;
            if (a.position < b.position) return -1;
            return 0;
          });
        });

        setConfigurationPackage(existing => ({
          ...existing,
          ...{
            configuration: {
              ...configuration,
            },
          },
        }));

        setReadOnly(false);
      });
    } else {
      fetchDefaultConfigurationByPipelineId(pipelineId).then(configuration => {
        configuration.id = 0;
        configuration.name = 'New Configuration';
        configuration.isDefault = false;
        configuration.sections = [];

        setConfigurationPackage(existing => ({
          ...existing,
          ...{
            configuration: {
              ...configuration,
            },
          },
        }));

        setReadOnly(false);
      });
    }

    if (
      configurationPackage == undefined ||
      !('types' in configurationPackage)
    ) {
      setReadOnly(true);

      fetchConfigDocTypes().then(types => {
        types.sort((a, b) => {
          if (a.name.toLowerCase() > b.name.toLowerCase()) return 1;
          if (a.name.toLowerCase() < b.name.toLowerCase()) return -1;
          return 0;
        });
        types.forEach(type => {
          type.name = type.name.replaceAll('_', ' ');
        });

        setConfigurationPackage(existing => ({
          ...existing,
          ...{
            types: types,
          },
        }));

        setReadOnly(false);
      });
    }
  }, [configId]);

  const handleSaveClick = e => {
    e.preventDefault();

    if (readOnly) {
      return;
    }

    setReadOnly(true);
    setSaving(true);

    toast.promise(
      saveConfiguration(configurationPackage.configuration),
      {
        pending: 'Saving configuration...',
        success: {
          render({ data }) {
            setConfigurationPackage(existing => ({
              ...existing,
              ...{
                configuration: {
                  ...configurationPackage.configuration,
                  id: data,
                },
              },
            }));

            updateFunc(data);

            setReadOnly(false);
            setSaving(false);

            return 'Your settings have been saved. Please note it may take up to 48 hours for your changes to appear.';
          },
        },
        error: {
          render({ data }) {
            setReadOnly(false);
            setSaving(false);

            if (data.response && 'status' in data.response) {
              switch (data.response.status) {
                case 400:
                  if ('errors' in data.response.data) {
                    if (data.response.data.errors.length > 0) {
                      switch (data.response.data.errors[0].code) {
                        case 32: // Default Missing
                          return 'At least one default configuration is required.';
                      }
                    }
                  }
              }
            }

            return 'There was a problem saving configuration';
          },
        },
      },
      { autoClose: 5000 }
    );
  };

  const changeTitle = e => {
    e.preventDefault();

    if (readOnly) {
      return;
    }

    setConfigurationPackage(existing => ({
      ...existing,
      ...{
        configuration: {
          ...configurationPackage.configuration,
          name: nameRef.current.value,
        },
      },
    }));

    setEditNameMode(false);
  };

  const handleEditNameClick = e => {
    e.preventDefault();

    if (readOnly) {
      return;
    }

    setEditNameMode(prev => !prev);
  };

  const handleDefaultClick = e => {
    e.preventDefault();

    if (readOnly) {
      return;
    }

    setConfigurationPackage(existing => ({
      ...existing,
      ...{
        configuration: {
          ...configurationPackage.configuration,
          isDefault: !configurationPackage.configuration.isDefault,
        },
      },
    }));
  };

  const handleCancelNewConfiguration = e => {
    e.preventDefault();

    updateFunc(null);

    setReadOnly(false);
  };

  return configurationPackage &&
    configurationPackage.configuration &&
    configurationPackage.types ? (
    <>
      <div className="pb-3">
        <div className="d-flex justify-content-between align-items-center">
          {!editNameMode && (
            <div>
              <span className="fs-4">
                {configurationPackage.configuration.name}
              </span>
              <PrimaryLink
                className={`ps-2 ${readOnly ? 'disabled-link' : ''}`}
                href="#"
                onClick={handleEditNameClick}
              >
                <FontAwesomeIcon icon={faEdit} />
              </PrimaryLink>
            </div>
          )}
          {editNameMode && (
            <div className="input-group w-50 align-items-center">
              <span className="input-group-text">Name</span>
              <input
                type="text"
                ref={nameRef}
                className="form-control"
                defaultValue={configurationPackage.configuration.name}
              />
              <PrimaryLink
                className={`ps-3 ${readOnly ? 'disabled-link' : ''}`}
                href="#"
                onClick={handleEditNameClick}
                title="Cancel"
              >
                <FontAwesomeIcon icon={faTimes} size="lg" />
              </PrimaryLink>
              <PrimaryLink
                className={`ps-3 ${readOnly ? 'disabled-link' : ''}`}
                href="#"
                onClick={changeTitle}
                title="Save"
              >
                <FontAwesomeIcon icon={faCheck} size="lg" />
              </PrimaryLink>
            </div>
          )}
          <div>
            <div className="form-check form-switch">
              <input
                key={Math.random()}
                className="form-check-input"
                type="checkbox"
                role="switch"
                id={'chkIsDefault' + configurationPackage.configuration.id}
                checked={configurationPackage.configuration.isDefault}
                onChange={handleDefaultClick}
                disabled={readOnly}
              />
              <label
                className="form-check-label"
                htmlFor={'chkIsDefault' + configurationPackage.configuration.id}
              >
                Default
              </label>
            </div>
          </div>
        </div>
      </div>
      <div className="accordion" id="accordionExample">
        <div className="accordion-item">
          <span className="accordion-header" id="headingOne">
            <button
              className="accordion-button"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseOne"
              aria-expanded="true"
              aria-controls="collapseOne"
            >
              Document Settings
            </button>
          </span>
          <div
            id="collapseOne"
            className="accordion-collapse collapse show"
            aria-labelledby="headingOne"
            data-bs-parent="#accordionExample"
          >
            <div className="accordion-body">
              <DocumentSettings readOnly={readOnly} />
            </div>
          </div>
        </div>
        <div className="accordion-item">
          <span className="accordion-header" id="headingTwo">
            <button
              className="accordion-button collapsed"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseTwo"
              aria-expanded="false"
              aria-controls="collapseTwo"
            >
              Section Settings
            </button>
          </span>
          <div
            id="collapseTwo"
            className="accordion-collapse collapse"
            aria-labelledby="headingTwo"
            data-bs-parent="#accordionExample"
          >
            <div className="accordion-body">
              <SectionSettings readOnly={readOnly} />
            </div>
          </div>
        </div>
      </div>
      <div className="text-center pt-4">
        <div className="d-flex justify-content-between align-items-center pb-3">
          {configId != 0 && (
            <DeleteConfiguration
              readOnly={
                configurationPackage.configuration.isDefault || readOnly
              }
              setReadOnly={setReadOnly}
              updateFunc={updateFunc}
            />
          )}
          {configId == 0 && (
            <Primary onClick={handleCancelNewConfiguration}>Cancel</Primary>
          )}
          <Primary
            onClick={handleSaveClick}
            processing={saving}
            readOnly={readOnly}
          >
            Save
          </Primary>
        </div>
      </div>
    </>
  ) : (
    'Loading...'
  );
};

Content.propTypes = {
  pipelineId: number.isRequired,
  configId: number.isRequired,
  updateFunc: func.isRequired,
};

export default Content;
