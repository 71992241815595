import { shape } from 'prop-types';

import Primary from 'components/_common/buttons/Primary';

import { getDescription } from 'components/_common/constants/fileStatusEnum';

import bytesToSize from 'utils/formatBytes';
import { formatISODateTime } from 'utils/formatDateTme';

const FileView = ({ file }) => {
  return (
    <div className="modal fade" id="fileViewModal" tabIndex="-1">
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header">
            <h1 className="modal-title fs-5">File View</h1>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            />
          </div>
          <div className="modal-body">
            {file && (
              <div className="px-3">
                <div className="row py-3">
                  <div className="col-12">
                    <span className="fw-bold pe-2">Configuration:</span>
                    {file.configName ?? 'N/A'}
                  </div>
                </div>
                <div className="row py-3">
                  <div className="col-12">
                    <span className="fw-bold pe-2">File Guid:</span>
                    {file.fileGuid}
                  </div>
                </div>
                <div className="row py-3">
                  <div className="col-12">
                    <span className="fw-bold pe-2">File Name:</span>
                    {file.name}
                  </div>
                </div>
                <div className="row py-3">
                  <div className="col-12">
                    <span className="fw-bold pe-2">Original Name:</span>
                    {file.originalName}
                  </div>
                </div>
                <div className="row py-3">
                  <div className="col-12">
                    <span className="fw-bold pe-2">Job Name:</span>
                    {file.jobName}
                  </div>
                </div>
                <div className="row py-3">
                  <div className="col-6">
                    <span className="fw-bold pe-2">File Size:</span>
                    {bytesToSize(file.fileSize)}
                  </div>
                  <div className="col-6">
                    <span className="fw-bold pe-2">File Size Complete:</span>
                    {bytesToSize(file.fileSizeComplete ?? 0)}
                  </div>
                </div>
                <div className="row py-3">
                  <div className="col-6">
                    <span className="fw-bold pe-2">Page Count:</span>
                    {file.pageCount.toLocaleString()}
                  </div>
                  <div className="col-6">
                    <span className="fw-bold pe-2">Page Count Complete:</span>
                    {file.pageCountComplete?.toLocaleString() ?? 'n/a'}
                  </div>
                </div>
                <div className="row py-3">
                  <div className="col-6">
                    <span className="fw-bold pe-2">Rush Job:</span>
                    {file.rush ? 'Yes' : 'No'}
                  </div>
                  <div className="col-6">
                    <span className="fw-bold pe-2">Summarize:</span>
                    {file.summarize ? 'Yes' : 'No'}
                  </div>
                </div>
                <div className="row py-3">
                  <div className="col-6">
                    <span className="fw-bold pe-2">Created On:</span>
                    {formatISODateTime(file.createdAt)}
                  </div>
                  <div className="col-6">
                    <span className="fw-bold pe-2">Current Status:</span>
                    {getDescription(file.fileStatusId)}
                  </div>
                </div>
                {file.instructions && (
                  <div className="row py-3">
                    <div className="col-12">
                      <span className="fw-bold pe-2">Instructions:</span>
                      {file.instructions}
                    </div>
                  </div>
                )}
              </div>
            )}
          </div>
          <div className="modal-footer">
            <div className="mx-auto">
              <Primary data-bs-dismiss="modal">Close</Primary>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

FileView.defaultProps = {
  file: undefined,
};

FileView.propTypes = {
  file: shape({}),
};

export default FileView;
