import { arrayOf, func, number, shape } from 'prop-types';

const FileStatuses = ({ statuses, currentStatuses, statusClick }) => {
  return (
    <>
      <div className="fw-bold pb-2">File Statuses</div>
      <div className="d-flex flex-wrap">
        {statuses &&
          statuses.map(status => {
            return (
              <div className="form-check pe-4 py-1 form-switch" key={status.id}>
                <input
                  key={Math.random()}
                  className="form-check-input"
                  type="checkbox"
                  id={'flexCheckChecked' + status.id}
                  checked={
                    currentStatuses.findIndex(item => item == status.id) >= 0
                  }
                  onChange={e => statusClick(e, status.id)}
                />
                <label
                  className="form-check-label"
                  htmlFor={'flexCheckChecked' + status.id}
                >
                  {status.name}
                </label>
              </div>
            );
          })}
      </div>
    </>
  );
};

FileStatuses.propTypes = {
  statuses: arrayOf(shape({})).isRequired,
  currentStatuses: arrayOf(number).isRequired,
  statusClick: func.isRequired,
};

export default FileStatuses;
