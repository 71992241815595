import { string } from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';

import './style.css';

const Loader = ({ label }) => {
  return (
    <div className="deepdoc-loader-wrapper">
      <div className="text-white">
        <FontAwesomeIcon
          icon={faSpinner}
          size="4x"
          spinPulse={true}
          role="status"
        />
      </div>
      {label && <span className="text-white fs-4 ps-3">{label}</span>}
    </div>
  );
};

Loader.defaultProps = { label: 'Loading...' };

Loader.propTypes = { label: string };

export default Loader;
