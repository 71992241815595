import { useRef, useState } from 'react';

import fetchCompanies from 'api/companies/fetchCompanies';
import fetchByCompanyGuid from 'api/file/fetchByCompanyGuid';
import fetchPipelines from 'api/pipelines/fetchPipelines';
import fetchFileStatuses from 'api/lookup/fetchFileStatuses';

import formatDateTme from 'utils/formatDateTme';

import formatJobsData from './utils';
import JobsHeader from './Header';

const JobReport = () => {
  const [loading, setLoading] = useState();

  const [statuses, setStatuses] = useState();
  const [pipelines, setPipelines] = useState();
  const [companies, setCompanies] = useState();
  const [jobData, setJobData] = useState(null);

  const startDateRef = useRef();
  const endDateRef = useRef();

  document.title = 'Jobs Report | DeepDoc by AAICO';

  const loadStatuses = async () => {
    if (statuses) {
      return statuses;
    }

    var statusesArray = await fetchFileStatuses();
    setStatuses(statusesArray);

    return statusesArray;
  };

  const loadPipelines = async () => {
    if (pipelines) {
      return pipelines;
    }

    var pipelinesArray = await fetchPipelines();
    setPipelines(pipelinesArray);

    return pipelinesArray;
  };

  const loadCompanies = async () => {
    if (companies) {
      return companies;
    }

    var companiesArray = await fetchCompanies();
    setCompanies(companiesArray);

    return companiesArray;
  };

  const loadFiles = async (companyGuid, startDate, endDate) => {
    try {
      return await fetchByCompanyGuid(companyGuid);
    } catch (error) {
      if ('status' in error.response) {
        console.log('Error', error);
      }
    }

    return [];
  };

  const handleGetReport = async () => {
    setJobData(null);
    // if (startDateRef.current.value == '') {
    //   console.log('inside if');
    //   setErrorMessage('Invalid Start Date');
    //   return;
    // }
    // if (endDateRef.current.value == '') {
    //   setErrorMessage('Invalid End Date');
    //   return;
    // }

    setLoading(true);

    var pipelinesArray = await loadPipelines();
    var statusesArray = await loadStatuses();
    var companiesArray = await loadCompanies();

    const promises = companiesArray.map(async company => {
      return await loadFiles(company.guidId);
    });

    await Promise.all(promises).then(response => {
      const filesArray = response.reduce((accumulator, currentValue) =>
        accumulator.concat(currentValue)
      );

      setJobData(
        formatJobsData(
          pipelinesArray,
          companiesArray,
          statusesArray,
          filesArray
        )
      );

      setLoading(false);
    });
  };

  return (
    <>
      <div className="card">
        <div className="card-body">
          <div className="input-group" style={{ width: '50rem' }}>
            <span className="input-group-text">Start Date</span>
            <input
              ref={startDateRef}
              type="date"
              className="form-control"
              aria-label="start date"
              aria-describedby="button-addon2"
              disabled
            />
            <span className="input-group-text">End Date</span>
            <input
              ref={endDateRef}
              type="date"
              className="form-control"
              aria-label="end date"
              aria-describedby="button-addon2"
              disabled
            />
            <button
              className="btn btn-outline-secondary"
              type="button"
              id="button-addon2"
              onClick={handleGetReport}
            >
              Search
            </button>
          </div>
        </div>
      </div>

      <div className="card mt-3">
        <div className="card-header">
          <JobsHeader jobData={jobData} />
        </div>
        <div className="card-body">
          <div className="card-text">
            {loading && 'Loading...'}
            {!loading && jobData && jobData.length > 0 && (
              <>
                <table className="table">
                  <thead>
                    <tr>
                      <th scope="col">Company</th>
                      <th scope="col">Job Name</th>
                      <th scope="col">Type</th>
                      <th scope="col" className="text-end">
                        Page Count
                      </th>
                      <th scope="col" className="text-center">
                        Express
                      </th>
                      <th scope="col">Instructions</th>
                      <th scope="col" className="text-end">
                        Created
                      </th>
                      <th scope="col">Status</th>
                    </tr>
                  </thead>
                  <tbody>
                    {jobData.map((file, idx) => {
                      return (
                        <tr key={idx} scope="row">
                          <td>{file.company}</td>
                          <td>{file.jobName}</td>
                          <td>{file.type}</td>
                          <td className="text-end">
                            {file.pageCount.toLocaleString()}
                          </td>
                          <td className="text-center">{file.isExpress}</td>
                          <td>{file.instructions}</td>
                          <td className="text-end text-nowrap">
                            {formatDateTme.formatISODateTime(file.created)}
                          </td>
                          <td>{file.currentStatus}</td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default JobReport;
