import { useEffect, useState } from 'react';
import { object } from 'prop-types';
import { Formik, Form, Field } from 'formik';
import { toast } from 'react-toastify';

import fetchUserRoles from 'api/users/fetchUserRoles';
import saveUserRoles from 'api/users/saveUserRoles';

import Primary from 'components/_common/buttons/Primary';

import rolesEnum from 'components/_common/constants/rolesEnum';

const Roles = ({ user }) => {
  const [userRoles, setUserRoles] = useState([]);
  const [onUserRoleSubmit, setUserRoleOnSubmit] = useState(false);

  useEffect(() => {
    if (user) {
      fetchUserRoles(user.id).then(response => {
        setUserRoles(response);
      });
    }
  }, []);

  const saveUserRoleHandler = async ({
    admin,
    analystHl,
    manager,
    managerHl,
    client,
    adminHl,
    summarizer,
  }) => {
    setUserRoleOnSubmit(true);
    let userRolesArray = [];

    if (admin) {
      userRolesArray.push(rolesEnum.ADMINISTRATOR);
    }

    if (analystHl) {
      userRolesArray.push(rolesEnum.ANALYSTHL);
    }

    if (manager) {
      userRolesArray.push(rolesEnum.MANAGER);
    }

    if (managerHl) {
      userRolesArray.push(rolesEnum.MANAGERHL);
    }

    if (client) {
      userRolesArray.push(rolesEnum.CLIENT);
    }

    if (adminHl) {
      userRolesArray.push(rolesEnum.ADMINHL);
    }

    if (summarizer) {
      userRolesArray.push(rolesEnum.SUMMARIZER);
    }

    saveUserRoles(user.id, userRolesArray)
      .then(() => {
        toast.success('Roles saved');
        setUserRoleOnSubmit(false);
      })
      .catch(() => {
        toast.error('Roles not saved');
        setUserRoleOnSubmit(false);
      });
  };

  return (
    <>
      <div id="roles" className="container tab-pane fade">
        <Formik
          initialValues={{
            admin: userRoles.some(role => role == rolesEnum.ADMINISTRATOR),
            analystHl: userRoles.some(role => role == rolesEnum.ANALYSTHL),
            manager: userRoles.some(role => role === rolesEnum.MANAGER),
            managerHl: userRoles.some(role => role === rolesEnum.MANAGERHL),
            client: userRoles.some(role => role === rolesEnum.CLIENT),
            adminHl: userRoles.some(role => role === rolesEnum.ADMINHL),
            summarizer: userRoles.some(role => role === rolesEnum.SUMMARIZER),
          }}
          enableReinitialize
          onSubmit={saveUserRoleHandler}
        >
          <Form>
            <div className="card rounded-0 mt-3">
              <div className="card-body">
                <div className="form-check">
                  <Field
                    type="checkbox"
                    className="form-check-input"
                    name="admin"
                  />
                  <label className="form-check-label" htmlFor="isActive">
                    Administrator
                  </label>
                </div>
                <div className="form-check">
                  <Field
                    type="checkbox"
                    name="analystHl"
                    className="form-check-input"
                  />
                  <label className="form-check-label" htmlFor="analystHl">
                    Analyst HL
                  </label>
                </div>
                <div className="form-check">
                  <Field
                    type="checkbox"
                    className="form-check-input"
                    name="manager"
                  />
                  <label className="form-check-label" htmlFor="manager">
                    Manager
                  </label>
                </div>
                <div className="form-check">
                  <Field
                    type="checkbox"
                    name="managerHl"
                    className="form-check-input"
                  />
                  <label className="form-check-label" htmlFor="managerHl">
                    Manager HL
                  </label>
                </div>
                <div className="form-check">
                  <Field
                    type="checkbox"
                    name="client"
                    className="form-check-input"
                  />
                  <label className="form-check-label" htmlFor="managerHl">
                    Client
                  </label>
                </div>
                <div className="form-check">
                  <Field
                    type="checkbox"
                    name="adminHl"
                    className="form-check-input"
                  />
                  <label className="form-check-label" htmlFor="adminHl">
                    Admin HL
                  </label>
                </div>
                <div className="form-check">
                  <Field
                    type="checkbox"
                    name="summarizer"
                    className="form-check-input"
                  />
                  <label className="form-check-label" htmlFor="summarizer">
                    Summarizer
                  </label>
                </div>
              </div>
            </div>
            <div className="text-center mb-4 mt-2">
              <Primary type="submit" disabled={onUserRoleSubmit}>
                Update User Roles
              </Primary>
            </div>
          </Form>
        </Formik>
      </div>
    </>
  );
};

Roles.defaultProps = {
  user: {},
};

Roles.propTypes = {
  user: object,
};

export default Roles;
