export const validatePassword = value => {
  let error;

  if (!value) {
    error = 'Required';
  } else if (value.length < 8) {
    error = 'Your password needs to be at least eight (8) characters';
  } else if (!/^(?=.*[a-z]).+$/.test(value)) {
    error = 'Your password needs to include a lowercase character';
  } else if (!/^(?=.*[A-Z]).+$/.test(value)) {
    error = 'Your password needs to include a uppercase character';
  } else if (!/^(?=.*[_\W]).+$/.test(value)) {
    error = 'Your password needs to include a special character';
  } else if (!/\d/.test(value)) {
    error = 'Your password needs to include a number';
  }

  return error;
};

export const validateEmail = value => {
  let error;
  if (!value) {
    error = 'Required';
  } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)) {
    error = 'Invalid email address';
  }
  return error;
};

//Parameters can be added for additional validation like length
export const validateField = value => {
  let error;
  if (!value) {
    error = 'Required';
  }

  return error;
};

export const validatePhone = value => {
  let error;

  if (!value) {
    error = 'Required';
  } else if (
    !/([0-9\s\-]{7,})(?:\s*(?:#|x\.?|ext\.?|extension)\s*(\d+))?$/.test(value) // eslint-disable-line
  ) {
    error = 'Invalid phone number';
  }
  return error;
};

export const validatePhoneWithCountryCode = value => {
  let error;

  if (!value) {
    error = 'Required';
  } else if (
    !/^[+][0-9]{1,3}[0-9]{10}$/.test(value) // eslint-disable-line
  ) {
    error = 'Invalid phone number';
  }
  return error;
};

export const ValidateGUID = value => {
  let error;

  if (!value) {
    error = 'Required';
  } else if (
    !/^[0-9A-F]{8}-[0-9A-F]{4}-4[0-9A-F]{3}-[89AB][0-9A-F]{3}-[0-9A-F]{12}$/i.test(
      value
    )
  ) {
    error = 'Invalid GUID';
  }

  return error;
};

export default {
  validatePassword,
  validateEmail,
  validateField,
  validatePhone,
  ValidateGUID,
  validatePhoneWithCountryCode,
};
