import { Auth } from '@aws-amplify/auth';
import { Dropdown } from 'bootstrap';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import userAttributes from 'utils/userAttributes';

const Reports = () => {
  const [showMain, setShowMain] = useState();
  const [dropdown, setDropdown] = useState();

  useEffect(() => {
    Auth.currentAuthenticatedUser().then(user => {
      const userCreds = userAttributes(user);
      setShowMain(userCreds.isAdmin == 1);
    });
  }, []);

  // const showMain = () => {
  //   if (userCreds) {
  //     return (
  //       userCreds.isAdmin == 1
  //       // userCreds.isAdminHl == 1 ||
  //       // userCreds.isAnalystHl == 1 ||
  //       // userCreds.isManager == 1 ||
  //       // userCreds.isManagerHl == 1
  //     );
  //   }

  //   return false;
  // };

  const handleToggle = e => {
    e.preventDefault();

    if (dropdown == undefined) {
      var dd = document.getElementById('ddReports');
      var temp = new Dropdown(dd, {
        autoClose: 'outside',
        reference: 'parent',
      });

      setDropdown(temp);
      temp.toggle();
    } else {
      dropdown.toggle();
    }
  };

  return showMain ? (
    <li className="nav-item dropdown">
      <a
        className="nav-link dropdown-toggle"
        href="#"
        id="ddReports"
        role="button"
        data-bs-toggle="dropdown"
        aria-expanded="false"
        onClick={handleToggle}
      >
        Reports
      </a>
      <ul className="dropdown-menu" aria-labelledby="ddReports">
        <li>
          <Link className="dropdown-item" to="/reports/jobs">
            Jobs
          </Link>
        </li>
      </ul>
    </li>
  ) : null;
};

export default Reports;
