import { useState } from 'react';
import { v4 } from 'uuid';
import { useParams } from 'react-router-dom';

import Logo from 'components/_common/Logo';

import Promo1 from '../Promos/Promo1';

import { JobToCloneProvider } from './context/jobToCloneContext';
import Wizard from './Wizard';

const Upload = () => {
  const { id } = useParams();

  const [uploadId] = useState(v4());

  document.title = 'Upload | DeepDoc by AAICO';

  return (
    <JobToCloneProvider key={uploadId}>
      <div className="position-fixed ms-3 mt-3">
        <Logo dark={false} heightInRem={4} />
      </div>
      <div className="row h-100 g-0">
        <div className="col-md-6 col-12">
          <div className="d-flex align-items-center h-100 ps-md-5 px-3">
            <div className="" style={{ width: '100%', maxWidth: '30rem' }}>
              <Wizard uploadId={uploadId} cloneJobId={id} />
            </div>
          </div>
        </div>
        <div className="col-md-6 col-12 d-lg-block d-none">
          <div className="d-flex align-items-center h-100">
            <Promo1 />
          </div>
        </div>
      </div>
    </JobToCloneProvider>
  );
};

export default Upload;
