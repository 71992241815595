import userPropType from 'propTypes/userPropType';

import ProfileItem from '../ProfileItem';

const UserInfo = ({ user }) => {
  return (
    <>
      {user.firstName && (
        <ProfileItem label="First Name" text={user.firstName} />
      )}
      {user.lastName && <ProfileItem label="Last Name" text={user.lastName} />}
      <ProfileItem label="Email" text={user.email} />
      {user.phone && <ProfileItem label="Phone" text={user.phone} />}
    </>
  );
};

UserInfo.propTypes = {
  user: userPropType.isRequired,
};

export default UserInfo;
