import { validatePassword } from 'utils/validateFields';
import { useTranslation } from 'react-i18next';
import { Field } from 'formik';
import { object } from 'prop-types';

const newPasswordRequest = ({ touched, errors }) => {
  const { t } = useTranslation();

  return (
    <div className="input-group mb-4">
      <Field
        type="password"
        className={
          'form-control form-control-lg rounded-2 ' +
          (touched.newPassword && errors.newPassword ? 'is-invalid' : '')
        }
        placeholder={t('Common_New_Password')}
        aria-label={t('Common_New_Password')}
        name="newPassword"
        validate={validatePassword}
      />
      {touched.newPassword && errors.newPassword && (
        <div className="invalid-feedback text-center">{errors.newPassword}</div>
      )}
    </div>
  );
};

newPasswordRequest.propTypes = {
  touched: object.isRequired,
  errors: object.isRequired,
};

export default newPasswordRequest;
