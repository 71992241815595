import { Formik, Form, Field } from 'formik';

import PrimaryButton from 'components/_common/buttons/Primary';
import PrimaryLink from 'components/_common/links/Primary';
import Logo from 'components/_common/Logo';

import { validateEmail, validateField } from 'utils/validateFields';

const confirmForm = ({ username, reSendCode, confirmSignUp }) => {
  return (
    <>
      <Formik
        initialValues={{
          code: '',
          username: !username || username == 'null' ? '' : username,
        }}
        onSubmit={confirmSignUp}
      >
        {({ values, errors, touched }) => (
          <Form>
            <div className="text-center mb-4">
              <Logo />
            </div>
            <div className="mb-3 col text-center fs-6">Confirm Account</div>
            <div className="mb-3 col text-center fs-6">
              <div id="pageError" className="invalid-feedback">
                Please choose a username.
              </div>
            </div>
            <div className="mb-3">
              <div className="input-group mb-3">
                <Field
                  type="text"
                  className={
                    touched.code && errors.code
                      ? 'form-control form-control-lg is-invalid rounded-2'
                      : 'form-control form-control-lg'
                  }
                  placeholder="Code"
                  aria-label="Code"
                  name="code"
                  validate={validateField}
                />
                {touched.code && errors.code && (
                  <div className="invalid-feedback text-center">
                    {errors.code}
                  </div>
                )}
              </div>
            </div>
            <div className="mb-3">
              <div className="input-group mb-3">
                <Field
                  type="text"
                  className={
                    touched.username && errors.username
                      ? 'form-control form-control-lg is-invalid rounded-2'
                      : 'form-control form-control-lg'
                  }
                  placeholder="Email"
                  aria-label="Email"
                  name="username"
                  validate={validateEmail}
                />
                {touched.username && errors.username && (
                  <div className="invalid-feedback text-center">
                    {errors.username}
                  </div>
                )}
              </div>
            </div>
            <div className="row mb-4">
              <div className="col-12 text-center">
                Click{' '}
                <PrimaryLink
                  href="#"
                  onClick={e => reSendCode(e, values.username, errors)}
                >
                  here{' '}
                </PrimaryLink>
                for new verification code
              </div>
            </div>
            <div className="text-center">
              <PrimaryButton type="submit">Confirm Account</PrimaryButton>
            </div>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default confirmForm;
