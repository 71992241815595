import image from 'images/promo1.png';

const Promo2 = () => {
  return (
    <div
      className="d-flex justify-content-center w-100 h-100 "
      style={{
        width: '100%',
        height: '100%',
        backgroundImage: `url(${image})`,
        backgroundPosition: '120% 60%',
        backgroundRepeat: 'no-repeat',
        backgroundSize: '50%',
      }}
    >
      <div className="align-self-start pt-5 mt-5 text-white ps-3 ps-md-0 ">
        <div className="fs-1 lh-sm">Organize & summarize</div>
        <div className="fs-1 lh-sm">medical files</div>
        <div className="fs-5 mt-4">
          <div className="row g-0">
            <div className="col-lg-9 col-6">
              AI-powered Independent Medical Examination (IME)
              <br />
              and File Reviews
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Promo2;
