import { executeAnonymousApiCall } from 'utils/executeApiCall';

export default function (id) {
  try {
    const endpoint = `/api/router/${id}`;

    return executeAnonymousApiCall(endpoint, {
      method: 'GET',
    }).then(response => {
      return response.data;
    });
  } catch (error) {
    return Promise.reject(error);
  }
}
