import { bool } from 'prop-types';
import { useConfigurationPackage } from '../../context/configurationPackageContext';
import { uniqueDocumentTypes } from '../../utils';

import AddSection from './AddSection';

import Section from './Section';

const Documents = ({ readOnly }) => {
  const configurationPackage = useConfigurationPackage();

  return (
    configurationPackage &&
    configurationPackage.types && (
      <>
        <div className="row">
          <div className="col-9">
            <div className="mb-2 d-flex justify-content-between">
              <div className="fw-bold text-muted ">Sections</div>
              <AddSection readOnly={readOnly} />
            </div>
            {configurationPackage.configuration.sections.map(section => (
              <Section key={section.id} section={section} readOnly={readOnly} />
            ))}
            {configurationPackage &&
              configurationPackage.configuration &&
              configurationPackage.configuration.sections.length > 2 && (
                <div className="text-end">
                  <AddSection readOnly={readOnly} />
                </div>
              )}
            <label id="section" />
          </div>
          <div className="col-3">
            <div className="fs-6 pb-2 fw-bold text-muted">
              Document Types Library
            </div>
            <ul>
              {uniqueDocumentTypes(configurationPackage).map(item => (
                <li key={item.id}>{item.name}</li>
              ))}
            </ul>
          </div>
        </div>
        <div className="text-muted text-center text-smaller">
          * Any sections with no document types allocated will not be saved
        </div>
      </>
    )
  );
};

Documents.defaultProps = { readOnly: false };

Documents.propTypes = { readOnly: bool };

export default Documents;
