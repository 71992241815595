import { useRef } from 'react';
import { func } from 'prop-types';

import useEventListener from 'hooks/useEventListener';

import './style.css';

const DropZone = ({ onAddFile }) => {
  const dropzoneRef = useRef();
  const contentRef = useRef();

  useEventListener('dragenter', e => {
    e.preventDefault();

    dropzoneRef.current.classList.add('dragging');
    dropzoneRef.current.classList.remove('d-none');
    contentRef.current.classList.remove('d-none');
  });

  useEventListener(
    'dragenter',
    e => {
      e.preventDefault();

      dropzoneRef.current.classList.add('dragging');
      contentRef.current.classList.remove('d-none');
    },
    dropzoneRef.current
  );

  useEventListener(
    'dragover',
    e => {
      e.preventDefault();
    },
    dropzoneRef.current
  );

  useEventListener(
    'dragleave',
    e => {
      e.preventDefault();

      if (e.fromElement == null) {
        dropzoneRef.current.classList.remove('dragging');
        contentRef.current.classList.add('d-none');
      }
    },
    dropzoneRef.current
  );

  useEventListener(
    'drop',
    e => {
      e.preventDefault();

      dropzoneRef.current.classList.remove('dragging');
      contentRef.current.classList.add('d-none');
    },
    dropzoneRef.current
  );

  function dropHandler(e) {
    e.preventDefault();

    dropzoneRef.current.classList.remove('dragging');
    contentRef.current.classList.add('d-none');

    if (e.dataTransfer.items) {
      [...e.dataTransfer.items].forEach(item => {
        if (item.kind === 'file') {
          onAddFile(item.getAsFile());
        }
      });
    } else {
      [...e.dataTransfer.files].forEach(file => {
        onAddFile(file);
      });
    }
  }

  return (
    <div
      id="dropzone"
      className="d-none"
      onDrop={e => dropHandler(e)}
      ref={dropzoneRef}
    >
      <div
        className="position-absolute top-50 start-50 translate-middle d-none"
        id="dropzone-content"
        ref={contentRef}
      >
        <div className="text-center text-white opacity-100">
          <div className="fs-2">Drop it</div>
          <div className="fs-5 mt-3">
            Upload files or folder by dropping them in this window
          </div>
        </div>
      </div>
    </div>
  );
};

DropZone.propTypes = {
  onAddFile: func.isRequired,
};

export default DropZone;
