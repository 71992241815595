import { Outlet } from 'react-router-dom';

import RequireAuth from 'components/_common/auth/RequireAuth';
import Navigation from 'components/Navigation';

const LoggedInLayout = () => {
  return (
    <RequireAuth>
      <Navigation />
      <Outlet />
    </RequireAuth>
  );
};

export default LoggedInLayout;
