import { Auth } from '@aws-amplify/auth';
import { useEffect, useState } from 'react';

import fetchUser from 'api/users/fetchUser';
import fetchCompany from 'api/companies/fetchCompany';

import SectionHeader from 'components/_common/Page1/SectionHeader';

import UserInfo from './UserInfo';
import CompanyInfo from './CompanyInfo';

const Profile = () => {
  var [userInfo, setUserInfo] = useState();
  var [companyInfo, setCompanyInfo] = useState();

  document.title = 'Profile | DeepDoc by AAICO';

  useEffect(() => {
    Auth.currentAuthenticatedUser({
      bypassCache: false,
    })
      .then(user => {
        fetchUser(user.username).then(response => {
          setUserInfo(response);
          fetchCompany(response.companyId).then(companyResponse =>
            setCompanyInfo(companyResponse)
          );
        });
      })
      .catch(err => console.log(err));
  }, []);

  return userInfo && companyInfo ? (
    <div>
      <SectionHeader text={'About Yourself'} />
      <UserInfo user={userInfo} />
      <SectionHeader text="Billing" />
      <CompanyInfo company={companyInfo} />
    </div>
  ) : (
    'Loading...'
  );
};

export default Profile;
