import { node } from 'prop-types';

import '../style.css';
import './style.css';

const SecondaryLink = ({ children, ...rest }) => {
  return (
    <a className="link link-secondary" {...rest}>
      {children}
    </a>
  );
};

SecondaryLink.defaultProps = {
  children: null,
};

SecondaryLink.propTypes = {
  children: node,
};

export default SecondaryLink;
