import { Dropdown } from 'bootstrap/dist/js/bootstrap';
import { uniqueId } from 'lodash';
import { useRef, useState } from 'react';
import { Auth } from '@aws-amplify/auth';
import { toast } from 'react-toastify';

import useClickOutside from 'hooks/useClickOutside';
import useEventListener from 'hooks/useEventListener';

import { Mixpanel } from 'utils/mixpanel';

import summaryStatusEnum from 'components/_common/constants/summaryStatusEnum';

import PrimaryLink from 'components/_common/links/Primary';

import getPresignedJobUrl from 'api/downloads/getPresignedJobUrl';
import getPresignedSummaryUrl from 'api/downloads/getPresignedSummaryUrl';
import fetchSummaryStatusByFileId from 'api/summaries/fetchSummaryStatusByFileId';

import jobPropType from 'propTypes/jobPropType';

const DownloadFile = ({ job }) => {
  const [dropdown, setDropdown] = useState();
  const [id] = useState(uniqueId());
  const [downloading, setDownloading] = useState(false);
  const dropdownRef = useRef();

  const [summaryStatus, setSummaryStatus] = useState(undefined);

  useClickOutside(dropdownRef, () => {
    if (dropdown) {
      dropdown.hide();
    }
  });

  useEventListener(
    'click',
    function (e) {
      e.preventDefault();

      dropdown.hide();

      setDownloading(true);

      Auth.currentAuthenticatedUser()
        .then(() => {
          getPresignedJobUrl(job.fileGuid)
            .then(url => {
              var a = document.createElement('a');
              a.setAttribute('download', '');
              a.setAttribute('target', '_blank');

              a.setAttribute('href', url);
              document.body.appendChild(a);
              a.click();
              document.body.removeChild(a);

              setDownloading(false);

              Mixpanel.track('Job download', { batchId: job.fileGuid });
            })
            .catch(error => {
              if (error.response && 'status' in error.response) {
                switch (error.response.status) {
                  case 404:
                    toast.error(
                      'Job could not be found. Please try again later.',
                      { autoClose: 10000 }
                    );
                    break;
                }
              }
              setDownloading(false);
            });
        })
        .catch(() => setDownloading(false));
    },
    document.getElementById('file' + id)
  );

  useEventListener(
    'click',
    function (e) {
      e.preventDefault();
      dropdown.hide();
      setDownloading(true);

      Auth.currentAuthenticatedUser()
        .then(() => {
          getPresignedSummaryUrl(job.fileGuid)
            .then(url => {
              var a = document.createElement('a');
              a.setAttribute('download', '');
              a.setAttribute('target', '_blank');

              a.setAttribute('href', url);
              document.body.appendChild(a);
              a.click();
              document.body.removeChild(a);

              setDownloading(false);

              Mixpanel.track('Summary download', {
                batchId: job.fileGuid,
              });
            })
            .catch(error => {
              if (error.response && 'status' in error.response) {
                switch (error.response.status) {
                  case 404:
                    toast.error(
                      'Summary has not been uploaded yet. Please try again later.',
                      { autoClose: 10000 }
                    );
                    break;
                }
              }

              setDownloading(false);
            });
        })
        .catch(() => setDownloading(false));
    },
    document.getElementById('summary' + id)
  );

  function showDropdown(e, dropdownId) {
    e.preventDefault();

    if (summaryStatus == undefined && job.summarize) {
      fetchSummaryStatusByFileId(job.id)
        .then(status => {
          setSummaryStatus(status);

          show(dropdownId);
        })
        .catch(() => {
          show(dropdownId);
        });
    } else {
      show(dropdownId);
    }
  }

  const show = id => {
    if (dropdown != null) {
      dropdown.show();
    } else {
      var dd = document.getElementById('dropdown' + id);
      var temp = new Dropdown(dd, {
        autoClose: 'outside',
        reference: 'parent',
      });

      setDropdown(temp);
      temp.show();
    }
  };

  return (
    <>
      <div className="btn-group" ref={dropdownRef}>
        <button
          className="btn btn-sm dropdown-toggle"
          type="button"
          aria-expanded="false"
          data-bs-toggle="dropdown"
          onClick={e => showDropdown(e, id)}
        >
          Downloads
        </button>
        <ul id={'dropdown' + id} className="dropdown-menu">
          <li>
            <PrimaryLink id={'file' + id} className="dropdown-item" href="">
              Organized Job
            </PrimaryLink>
          </li>
          {job.summarize && (
            <li>
              <PrimaryLink
                id={'summary' + id}
                className={`dropdown-item ${
                  summaryStatus != summaryStatusEnum.COMPLETE ? 'disabled' : ''
                }`}
                href=""
              >
                {`Summary${
                  summaryStatus != summaryStatusEnum.COMPLETE
                    ? ' In Progress'
                    : ''
                }`}
              </PrimaryLink>
            </li>
          )}
        </ul>
      </div>
      {downloading && <div className="pt-1">Downloading...</div>}
    </>
  );
};

DownloadFile.propTypes = {
  job: jobPropType.isRequired,
};

export default DownloadFile;
