export default function (user) {
  if (user) {
    if ('attributes' in user) {
      var response = {
        isAdmin: 0,
        isAdminHl: 0,
        isAnalystHl: 0,
        isManager: 0,
        isManagerHl: 0,
        isSummarizer: 0,
      };

      if ('custom:isAdmin' in user.attributes) {
        response.isAdmin = parseInt(user.attributes['custom:isAdmin']);
      }
      if ('custom:isAdminHL' in user.attributes) {
        response.isAdminHl = parseInt(user.attributes['custom:isAdminHL']);
      }
      if ('custom:isAnalystHl' in user.attributes) {
        response.isAdminHl = parseInt(user.attributes['custom:isAnalystHL']);
      }
      if ('custom:isManager' in user.attributes) {
        response.isManager = parseInt(user.attributes['custom:isManager']);
      }
      if ('custom:isManagerHL' in user.attributes) {
        response.isManagerHl = parseInt(user.attributes['custom:isManagerHL']);
      }
      if ('custom:isSummarizer' in user.attributes) {
        response.isSummarizer = parseInt(
          user.attributes['custom:isSummarizer']
        );
      }
    }

    return response;
  }

  return undefined;
}
