export function formatISODateTime(isoDateTime) {
  if (isoDateTime) {
    return new Intl.DateTimeFormat(navigator.language, {
      dateStyle: 'short',
      timeStyle: 'short',
    }).format(new Date(isoDateTime));
  }

  return isoDateTime;
}

export function formatISODate(isoDateTime) {
  return new Intl.DateTimeFormat(navigator.language, {
    dateStyle: 'short',
  }).format(new Date(isoDateTime));
}

export function formatISOTime(isoDateTime) {
  return new Intl.DateTimeFormat(navigator.language, {
    timeStyle: 'short',
  }).format(new Date(isoDateTime));
}

export default { formatISODateTime, formatISODate, formatISOTime };
