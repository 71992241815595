import { string } from 'prop-types';

const Header2 = ({ text }) => {
  return <div className="fs-5">{text}</div>;
};

Header2.propTypes = {
  text: string.isRequired,
};

export default Header2;
