import { bool, number } from 'prop-types';

import logoWhite from 'images/ddWhite.png';

import logoBlack from 'images/ddBlack.png';

const Logo = ({ dark, heightInRem }) => {
  return (
    <img
      src={dark ? logoBlack : logoWhite}
      style={{ height: `${heightInRem}rem` }}
      loading="lazy"
      alt="logo"
      className="image-16"
    />
  );
};

Logo.defaultProps = {
  heightInRem: 5,
  dark: true,
};

Logo.propTypes = {
  heightInRem: number,
  dark: bool,
};

export default Logo;
