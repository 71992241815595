import RequireAuth from 'components/_common/auth/RequireAuth';
import { Outlet } from 'react-router-dom';

const Admin = () => {
  return (
    <div>
      <RequireAuth>
        <div className="px-3 pt-5">
          <div className="pt-5">
            <Outlet />
          </div>
        </div>
      </RequireAuth>
    </div>
  );
};

export default Admin;
