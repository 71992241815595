import { bool, node } from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';

const Primary = ({ children, processing, ...rest }) => {
  const { disabled } = rest;

  return (
    // eslint-disable-next-line react/button-has-type
    <button
      className="btn btn-primary rounded-pill px-5 py-2"
      disabled={disabled || processing}
      {...rest}
    >
      {processing && (
        <FontAwesomeIcon
          icon={faSpinner}
          spinPulse={true}
          role="status"
          className="me-2"
        />
      )}
      {children}
    </button>
  );
};

Primary.defaultProps = {
  processing: false,
};

Primary.propTypes = {
  children: node.isRequired,
  processing: bool,
};

export default Primary;
