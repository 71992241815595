import { node } from 'prop-types';
import { createContext, useContext, useState } from 'react';

const CurrentUserContext = createContext();
const CurrentUserUpdateContext = createContext();

export function useCurrentUser() {
  return useContext(CurrentUserContext);
}

export function useCurrentUserUpdate() {
  return useContext(CurrentUserUpdateContext);
}

export function CurrentUserProvider({ children }) {
  const [userInfo, setUserInfo] = useState();

  return (
    <CurrentUserContext.Provider value={userInfo}>
      <CurrentUserUpdateContext.Provider value={setUserInfo}>
        {children}
      </CurrentUserUpdateContext.Provider>
    </CurrentUserContext.Provider>
  );
}

CurrentUserProvider.propTypes = {
  children: node.isRequired,
};
