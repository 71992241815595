import { useEffect, useRef, useState } from 'react';
import { func, string } from 'prop-types';

const Countdown = ({ startProcess, onTimerFinish }) => {
  const Ref = useRef(null);
  const [timer, setTimer] = useState('00:00:00');

  const getTimeRemaining = () => {
    const total = new Date(startProcess) - Date.parse(new Date());
    const seconds = Math.floor((total / 1000) % 60);
    const minutes = Math.floor((total / 1000 / 60) % 60);
    const hours = Math.floor((total / 1000 / 60 / 60) % 24);

    return {
      total,
      hours,
      minutes,
      seconds,
    };
  };

  useEffect(() => {
    let id;
    if (getTimeRemaining().total > 0) {
      id = setInterval(() => {
        startTimer();
      }, 1000);

      Ref.current = id;
    }

    return () => {
      if (id) {
        return clearInterval(id);
      }
    };
  }, []);

  const startTimer = () => {
    let { total, hours, minutes, seconds } = getTimeRemaining();

    if (total >= 0) {
      setTimer(
        (hours > 9 ? hours : '0' + hours) +
          ':' +
          (minutes > 9 ? minutes : '0' + minutes) +
          ':' +
          (seconds > 9 ? seconds : '0' + seconds)
      );
    }

    if (total < 0) {
      setTimer('');

      if (Ref.current) {
        clearInterval(Ref.current);

        if (onTimerFinish != undefined) {
          onTimerFinish();
        }
      }
    }
  };

  return <div>{timer}</div>;
};

Countdown.defaultProps = {
  onTimerFinish: undefined,
};

Countdown.propTypes = {
  startProcess: string.isRequired,
  onTimerFinish: func,
};

export default Countdown;
