import executeApiCall from 'utils/executeApiCall';

export default async function (
  summaryStatusids,
  fileStatusIds,
  take,
  skip,
  sortInfo
) {
  try {
    var params = '';
    if (summaryStatusids && summaryStatusids.length > 0) {
      params = 'includestatuses=' + summaryStatusids.join('&includestatuses=');
    }

    if (fileStatusIds && fileStatusIds.length > 0) {
      params +=
        (params ? '&' : '') +
        'excludeFileStatuses=' +
        fileStatusIds.join('&excludeFileStatuses=');
    }

    if (take) {
      params += (params ? '&' : '') + 'take=' + take;
    }

    if (skip) {
      params += (params ? '&' : '') + 'skip=' + skip;
    }

    if (sortInfo) {
      params += (params ? '&' : '') + 'sort=' + sortInfo;
    }

    const endpoint = `/api/summaries${params ? '?' + params : ''}`;

    const response = await executeApiCall(endpoint, {
      method: 'GET',
    });
    var totalCount = parseInt(response.headers['x-total-count']);

    if (totalCount) {
      return { data: response.data, count: totalCount };
    }
    return response.data;
  } catch (error) {
    return Promise.reject(error);
  }
}
