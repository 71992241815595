import { number, string } from 'prop-types';
import randomColor from 'randomcolor';

const NameBadge = ({ name, sizeInEm }) => {
  const getSeed = () => {
    if (name) {
      const names = name.split(' ');
      if (names) {
        if (names.length === 1) {
          return names[0].charAt(0).toUpperCase().charCodeAt();
        } else if (name.length >= 2) {
          return (
            names[0].charAt(0).toUpperCase().charCodeAt() +
            names[1].charAt(0).toUpperCase().charCodeAt()
          );
        }
      }
    }
    return 0;
  };

  const getInitials = () => {
    if (name) {
      const names = name.split(' ');
      if (names) {
        if (names.length === 1) {
          return names[0].charAt(0).toUpperCase();
        } else if (name.length >= 2) {
          return (
            names[0].charAt(0).toUpperCase() + names[1].charAt(0).toUpperCase()
          );
        }
      }
    }
    return '--';
  };

  const color = randomColor({
    seed: getSeed(),
    luminosity: 'bright',
  });

  return (
    <div className="d-flex align-items-center justify-content-center">
      <div
        className="d-flex rounded-circle align-items-center justify-content-center"
        title={name}
        style={{
          backgroundColor: color,
          height: sizeInEm + 'em',
          width: sizeInEm + 'em',
          lineHeight: sizeInEm + 'em',
          fontWeight: 700,
        }}
      >
        <div className=" align-self-center text-center text-uppercase text-white">
          {getInitials()}
        </div>
      </div>
    </div>
  );
};

NameBadge.defaultProps = {
  name: undefined,
  defaultColor: undefined,
  sizeInEm: 2,
};

NameBadge.propTypes = {
  name: string,
  sizeInEm: number,
  defaultColor: string,
};

export default NameBadge;
