import { faDownload } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { array } from 'prop-types';

import White from 'components/_common/buttons/White';
import { CSVLink } from 'react-csv';
import { useEffect, useState } from 'react';

const JobsHeader = ({ jobData }) => {
  const [allowExport, setAllowExport] = useState(false);

  useEffect(() => {
    setAllowExport(jobData != null && jobData.length > 0);
  }, [jobData]);

  return (
    <>
      {allowExport && <></>}
      <div className="d-flex justify-space-between align-items-center">
        <div>Jobs Report</div>
        {jobData && jobData.length > 0 && (
          <div className="ms-auto">
            <CSVLink data={jobData} filename={'jobs.csv'}>
              <White>
                <FontAwesomeIcon icon={faDownload} className="pe-2" />
                Export
              </White>
            </CSVLink>
          </div>
        )}
      </div>
    </>
  );
};

JobsHeader.defaultProps = {
  jobData: [],
};

JobsHeader.propTypes = {
  jobData: array,
};

export default JobsHeader;
