import { Link } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { Auth } from '@aws-amplify/auth';
import { Dropdown } from 'bootstrap';

import userAttributes from 'utils/userAttributes';

const AdminMenu = () => {
  const [userCreds, setUserCreds] = useState();
  const [dropdown, setDropdown] = useState();

  useEffect(() => {
    Auth.currentAuthenticatedUser().then(user => {
      setUserCreds(userAttributes(user));
    });
  }, []);

  const showMain = () => {
    if (userCreds) {
      return (
        userCreds.isAdmin == 1 ||
        // userCreds.isAdminHl == 1 ||
        // userCreds.isAnalystHl == 1 ||
        userCreds.isManager == 1 ||
        // userCreds.isManagerHl == 1 ||
        userCreds.isSummarizer == 1
      );
    }

    return false;
  };

  const handleToggle = e => {
    e.preventDefault();

    if (dropdown == undefined) {
      var dd = document.getElementById('ddAdmin');
      var temp = new Dropdown(dd, {
        autoClose: 'outside',
        reference: 'parent',
      });

      setDropdown(temp);
      temp.toggle();
    } else {
      dropdown.toggle();
    }
  };

  return showMain() ? (
    <li className="nav-item dropdown">
      <a
        className="nav-link dropdown-toggle"
        href="#"
        id="ddAdmin"
        role="button"
        data-bs-toggle="dropdown"
        aria-expanded="false"
        onClick={handleToggle}
      >
        Admin
      </a>
      <ul className="dropdown-menu" aria-labelledby="ddAdmin">
        <>
          {userCreds.isAdmin || userCreds.isManager ? (
            <>
              <li>
                <Link className="dropdown-item" to="/admin/settings/pipelines">
                  Pipelines
                </Link>
              </li>
              <li>
                <Link className="dropdown-item" to="/admin/settings/companies">
                  Companies
                </Link>
              </li>
              <li>
                <Link className="dropdown-item" to="/admin/settings/users">
                  Users
                </Link>
              </li>
            </>
          ) : null}
          {(userCreds.isAdmin ||
            userCreds.isManager ||
            userCreds.isSummarizer) && (
            <li>
              <Link className="dropdown-item" to="admin/summaries">
                Summaries
              </Link>
            </li>
          )}
        </>
        {userCreds.isAdmin ? (
          <>
            <li>
              <hr className="dropdown-divider" />
            </li>
            <li>
              <Link className="dropdown-item disabled" to="/admin/settings">
                Settings
              </Link>
            </li>
          </>
        ) : null}
      </ul>
    </li>
  ) : null;
};

export default AdminMenu;
