import { bool, func } from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCircleCheck,
  faCircleXmark,
} from '@fortawesome/free-regular-svg-icons';

import bytesToSize from 'utils/formatBytes';

import filePropType from '../../propTypes/filePropType';

const FileToUpload = ({
  file: { bytes, original_fileName },
  onRemoveClick,
  existing,
}) => {
  function handleRemoveFileClick(e) {
    e.preventDefault();

    onRemoveClick();
  }

  return (
    <>
      <div
        className={`row g-0 align-items-start justify-content-between ${
          existing ? 'text-success' : ''
        }`}
      >
        <div className="col-11 ">
          <div className="d-flex flex-column">
            <div className="fw-bold text-truncate" title={original_fileName}>
              {existing && (
                <FontAwesomeIcon
                  icon={faCircleCheck}
                  className="pe-2"
                  title="Existing file"
                />
              )}
              {original_fileName}
            </div>
            <div>{bytesToSize(bytes)}</div>
          </div>
        </div>
        <div className="col-1 text-end">
          {!existing && (
            <a
              href=""
              onClick={handleRemoveFileClick}
              title="Remove file"
              className="pe-1"
            >
              <FontAwesomeIcon icon={faCircleXmark} size="lg" />
            </a>
          )}
        </div>
      </div>
      <div className="row justify-content-center g-0">
        <div className="col-12 my-3">
          <div className="uploader-line" />
        </div>
      </div>
    </>
  );
};

FileToUpload.defaultProps = {
  existing: false,
};

FileToUpload.propTypes = {
  file: filePropType.isRequired,
  onRemoveClick: func.isRequired,
  existing: bool,
};

export default FileToUpload;
