import { bool, func } from 'prop-types';
import { toast } from 'react-toastify';
import { Popover } from 'bootstrap/dist/js/bootstrap';

import Primary from 'components/_common/buttons/Primary';

import deleteConfigurationByConfigId from 'api/configuration/deleteConfigurationByConfigId';

import { useConfigurationPackage } from '../../context/configurationPackageContext';
import { useEffect, useState } from 'react';
import useEventListener from 'hooks/useEventListener';
import PrimaryLink from 'components/_common/links/Primary';
import SecondaryLink from 'components/_common/links/Secondary';

const DeleteConfiguration = ({ readOnly, setReadOnly, updateFunc }) => {
  const [popover, setPopover] = useState();
  const [deleting, setDeleting] = useState(false);

  const configurationPackage = useConfigurationPackage();

  useEventListener(
    'click',
    function (e) {
      e.preventDefault();
      popover.hide();
    },
    document.getElementById('deleteNo')
  );

  useEventListener(
    'click',
    function (e) {
      e.preventDefault();
      popover.hide();

      handleDeleteConfigurationClick();
    },
    document.getElementById('deleteYes')
  );

  useEffect(() => {
    if (popover == null) {
      var pop = document.getElementById('deletePopover');
      var temp = new Popover(pop, {
        sanitize: false,
        container: 'body',
        html: true,
        trigger: 'focus',
        placement: 'bottom',
        content: document.getElementById('deletePopover-content'),
      });

      setPopover(temp);
    }
  }, []);
  const handleDeleteConfigurationClick = () => {
    if (readOnly) {
      return;
    }

    setReadOnly(true);
    setDeleting(true);

    toast.promise(
      deleteConfigurationByConfigId(configurationPackage.configuration.id),
      {
        pending: 'Deleting configuration...',
        success: {
          render({ data }) {
            const configName = configurationPackage.configuration.name;
            updateFunc(null);

            setReadOnly(false);
            setDeleting(false);

            return `Configuration '${configName}' has been deleted.`;
          },
        },
        error: {
          render({ data }) {
            setReadOnly(false);
            setDeleting(false);

            if (data.response && 'status' in data.response) {
              switch (data.response.status) {
                case 400:
                  if ('errors' in data.response.data) {
                    if (data.response.data.errors.length > 0) {
                      switch (data.response.data.errors[0].code) {
                        case 33: // In Use
                          return 'This configuration is in use and cannot be deleted.';
                        case 34: // Cannot Delete
                          if (configurationPackage.configuration.isDefault) {
                            return 'Default configuration cannot be deleted.';
                          } else {
                            return 'Last configuration cannot be deleted.';
                          }
                      }
                    }
                  }
              }
            }

            return 'There was a problem saving configuration';
          },
        },
      },
      { autoClose: 5000 }
    );
  };

  return (
    <>
      <Primary
        disabled={readOnly}
        type="button"
        id="deletePopover"
        processing={deleting}
      >
        Delete Configuration
      </Primary>
      <div hidden>
        <div id="deletePopover-content">
          <div className="row">
            <div className="col-12">
              Are you sure you want to delete this configuration?
            </div>
            <div className="row justify-content-center">
              <div className="col-10 border-bottom my-2" />
            </div>
            <div className="row gy-1">
              <div className="col-12 fs-6 fw-bold">
                <PrimaryLink href="" id="deleteYes">
                  Yes
                </PrimaryLink>
              </div>
              <div className="col-12 fs-6 fw-bold">
                <SecondaryLink href="" id="deleteNo">
                  No
                </SecondaryLink>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

DeleteConfiguration.propTypes = {
  readOnly: bool.isRequired,
  setReadOnly: func.isRequired,
  updateFunc: func.isRequired,
};

export default DeleteConfiguration;
