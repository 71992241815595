const convertFileLobbyToJob = fileLobby => {
  var config = JSON.parse(fileLobby.config);

  var job = {
    id: fileLobby.id,
    fileGuid: undefined,
    batchId: fileLobby.batchId,
    isCancelled: fileLobby.isCancelled,
    inProcess: fileLobby.inProcess,
    startProcess: fileLobby.startProcess,
    userId: undefined,
    analystId: undefined,
    summarizerId: undefined,
    createdAt: fileLobby.createdAt,
    jobName: config.jobName,
    jobType:
      config.jobType.toLowerCase() == 'organize'
        ? 'Organize'
        : 'Organize & Summarize',
    message: config.message,
    isExpress: config.isExpress,
    pageCount: undefined,
    statusId: undefined,
    summarize: config.jobType.toLowerCase() == 'organize' ? false : true,
    jobFiles: [],
    configName: undefined,
  };

  if (config.files && config.files.length === 1) {
    job.original = config.files[0].original_fileName;
    job.size = config.files[0].bytes;
    job.filename = config.files[0].fileName;
  } else {
    config.files.map(jobFile => {
      job.jobFiles.push({
        size: jobFile.bytes,
        filename: jobFile.fileName,
        original: jobFile.original_fileName,
      });
    });
  }

  return job;
};

export default convertFileLobbyToJob;
