import { useEffect, useState } from 'react';
import { Auth } from '@aws-amplify/auth';

import fetchPipelines from 'api/pipelines/fetchPipelines';
import fetchByPipelineIdComplete from 'api/file/fetchByPipelineIdComplete';
import fetchByPipelineIdInProgress from 'api/file/fetchByPipelineIdInProgress';

import jobFilterEnum from 'components/_common/constants/jobFilterEnum';
import convertFileToJob from 'converters/fileToJob';

import Page2 from 'components/_common/Page2';
import Filter from 'components/_common/Filter';
import JobItemPlaceHolder from 'components/_common/JobItemPlaceholder';
import JobItem from 'components/_common/JobItem';
import Shell from 'components/_common/Shell';

const Dashboard = () => {
  const [loading, setLoading] = useState();
  const [pipelines, setPipelines] = useState();
  const [currentPipeline, setCurrentPipeline] = useState(13);
  const [filter, setFilter] = useState(jobFilterEnum.INPROGRESS);
  const [files, setFiles] = useState();
  const [errorMessage, setErrorMessage] = useState();

  document.title = 'Admin Dashboard | DeepDoc by AAICO';

  useEffect(() => {
    fetchPipelines().then(response => setPipelines(response));
  }, []);

  useEffect(() => {
    setErrorMessage('');
    setFiles([]);
    setLoading(true);

    Auth.currentUserInfo().then(() => {
      if (filter == jobFilterEnum.COMPLETE) {
        fetchByPipelineIdComplete(currentPipeline)
          .then(response => {
            processFiles(response);
          })
          .catch(error => {
            errorFiles(error);
          });
      } else {
        fetchByPipelineIdInProgress(currentPipeline)
          .then(response => {
            processFiles(response);
          })
          .catch(error => {
            errorFiles(error);
          });
      }
    });
  }, [filter, currentPipeline]);

  const processFiles = files => {
    if (files.length == 0) {
      setErrorMessage(
        'Sorry, there are no jobs matching the filter you have selected'
      );
      return;
    }

    files.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));

    var filteredFiles = files.map(file => {
      return convertFileToJob(file);
    });

    setFiles(filteredFiles);

    if (filteredFiles.length === 0) {
      setErrorMessage(
        'Sorry, there are no jobs matching the filter you have selected'
      );
    }

    setLoading(false);
  };

  const errorFiles = error => {
    setLoading(false);
    setFiles([]);

    if (
      error.response &&
      'status' in error.response &&
      error.response.status === 404
    ) {
      setErrorMessage(
        'Sorry, there are no jobs matching the filter you have selected'
      );
      return;
    }
    // setAlertMessage(
    //   'Unable to load jobs from processing. Please <a href="/jobs" class="link link-danger">try again</a> later.'
    // );
  };

  return (
    <Page2 header="Active Files in DeepDoc">
      <>
        <div className="d-flex flex-column flex-md-row justify-content-between mb-4 pb-md-0">
          {pipelines && (
            <div className="d-flex justify-content-end align-items-center">
              <div className="text-muted fs-6 fw-bold pe-2 text-nowrap">
                Pipelines
              </div>
              <select
                className="form-select"
                aria-label=".form-select"
                onChange={e => {
                  e.preventDefault();
                  setCurrentPipeline(e.target.value);
                }}
              >
                {pipelines.map(pipeline => {
                  return (
                    <option key={pipeline.id} value={pipeline.id}>
                      {pipeline.nameFamiliar}
                    </option>
                  );
                })}
              </select>
            </div>
          )}
          <div className="align-items-start align-items-md-end">
            <Filter
              label="Filter by"
              onClick={filter => setFilter(parseInt(filter))}
              defaultFilter={filter}
              filterToSkip={jobFilterEnum.QUEUED}
            />
          </div>
        </div>
        <>
          {loading && (
            <div className="mb-3">
              <JobItemPlaceHolder />
            </div>
          )}
          {!loading && (
            <div>
              {files &&
                files.map(file => {
                  return (
                    <div className="mb-4" key={file.id}>
                      <JobItem key={file.id} job={file} isAdmin={true} />
                    </div>
                  );
                })}
            </div>
          )}
          {!loading && errorMessage && (
            <Shell>
              <div className="fs-6 py-3">{errorMessage}</div>
            </Shell>
          )}
        </>
      </>
    </Page2>
  );
};

export default Dashboard;
