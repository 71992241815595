import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Auth } from '@aws-amplify/auth';
import { faDownload, faCopy } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import getPresignedSummaryUrl from 'api/downloads/getPresignedSummaryUrl';
import fetchByFileId from 'api/file/fetchByFileId';
import fetchSummaryByFileId from 'api/summaries/fetchSummaryByFileId';
import saveSummary from 'api/summaries/saveSummary';
import saveSummaryStatusApprove from 'api/summaries/saveSummaryStatusApprove';
import fetchStatusesByFileId from 'api/file/fetchStatusesByFileId';
import getPresignedJobUrl from 'api/downloads/getPresignedJobUrl';

import { Mixpanel } from 'utils/mixpanel';
import formatDateTme from 'utils/formatDateTme';

import summaryStatusEnum from 'components/_common/constants/summaryStatusEnum';
import fileStatusEnum from 'components/_common/constants/fileStatusEnum';

import Page2 from 'components/_common/Page2';

import './style.css';

import Countdown from './_common/Countdown';

import Draft from './Draft';
import Arrows from './Arrows';
import Working from './Working';
import Finish from './Finish';

import PrimaryLink from 'components/_common/links/Primary';

const EditSummary = () => {
  const navigate = useNavigate();

  const { id } = useParams();

  const [summary, setSummary] = useState();
  const [file, setFile] = useState();
  const [fileStatus, setFileStatus] = useState();
  const [loading, setLoading] = useState();

  document.title = 'Summary | DeepDoc by AAICO';

  useEffect(() => {
    setLoading(true);

    fetchSummaryByFileId(id)
      .then(response => {
        setLoading(false);
        setSummary(response);

        if (response.status == summaryStatusEnum.IN_PROGRESS) {
          fetchStatusesByFileId(response.fileId, fileStatusEnum.COMPLETE)
            .then(status => {
              setFileStatus(status);
            })
            .catch(error => {
              if (error.response && 'status' in error.response) {
                switch (error.response.status) {
                  case 404:
                    navigate('/notfound');
                    break;
                }
              }
            });
        }

        fetchByFileId(response.fileId).then(fileResponse => {
          setFile(fileResponse);
        });
      })
      .catch(error => {
        setLoading(false);

        if (error.response && 'status' in error.response) {
          switch (error.response.status) {
            case 400:
              if ('errors' in error.response.data) {
                if (error.response.data.errors.length > 0) {
                  switch (error.response.data.errors[0].code) {
                    case 17: //invalid summarizer
                      navigate('/notfound');
                      break;
                  }
                }
              }
              break;
            case 404:
              navigate('/notfound');
              break;
          }
        }
      });

    const autoSave = setInterval(() => {}, 30000);

    setLoading(false);

    return () => {
      clearInterval(autoSave);
    };
  }, []);

  const handleSaveClick = (e, status, setFunc) => {
    setFunc(true);

    saveSummary({
      ...summary,
      status: status,
    })
      .then(() => {
        setSummary(prev => ({ ...prev, status: status }));
        setFunc(false);

        switch (status) {
          case summaryStatusEnum.IN_PROGRESS:
            toast.success('Summary updated...', { autoClose: 3000 });
            break;
          case summaryStatusEnum.AWAITING_APPROVAL:
            toast.success('Summary saved, awaiting approval...', {
              autoClose: 3000,
            });
            break;
          case summaryStatusEnum.APPROVED:
          case summaryStatusEnum.COMPLETE:
            toast.success('Summary complete and will be sent to the customer!');
            break;
        }
      })
      .catch(setFunc(false));
  };

  const handleApproveClick = () => {
    const prevStatus = summary.status;

    saveSummaryStatusApprove(summary.summaryGuid)
      .then(() => {
        setSummary(prev => ({ ...prev, status: summaryStatusEnum.APPROVED }));

        toast.success('Summary complete and will be sent to the customer!');
      })
      .catch(error => {
        if (error.response && 'status' in error.response) {
          switch (error.response.status) {
            case 400:
              if ('errors' in error.response.data) {
                if (error.response.data.errors.length > 0) {
                  switch (error.response.data.errors[0].code) {
                    case 17: //invalid summarizer
                      navigate('/notfound');
                      break;
                    case 24: //FileNotFound
                      setSummary(prev => ({
                        ...prev,
                        status: prevStatus,
                      }));

                      toast.error(
                        'Working summary file was not found. Upload the summary file and try again.',
                        { autoClose: 5000 }
                      );
                  }
                }
              }
              break;
            case 404:
              navigate('/notfound');
              break;
          }
        }
      });
  };

  const handleSummaryDownloadClick = type => {
    Auth.currentAuthenticatedUser().then(() => {
      fetchByFileId(summary.fileId).then(file => {
        getPresignedSummaryUrl(file.fileGuid, type)
          .then(url => {
            var a = document.createElement('a');
            a.setAttribute('download', '');
            a.setAttribute('target', '_blank');

            a.setAttribute('href', url);
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);

            Mixpanel.track('Summary download', {
              batchId: summary.fileGuid,
            });
          })
          .catch(error => {
            if (error.response && 'status' in error.response) {
              switch (error.response.status) {
                case 404:
                  toast.error(
                    'Summary has not been uploaded yet. Please try again later.',
                    { autoClose: 10000 }
                  );
                  break;
              }
            }
          });
      });
    });
  };

  const downloadPrepHandler = (e, fileGuid) => {
    e.preventDefault();
    Auth.currentAuthenticatedUser().then(() => {
      getPresignedJobUrl(fileGuid)
        .then(url => {
          if (!url) {
            toast.error('Job could not be found. Please try again later.', {
              autoClose: 10000,
            });
            return;
          }
          var a = document.createElement('a');
          a.setAttribute('download', '');
          a.setAttribute('target', '_blank');
          a.setAttribute('href', url);
          document.body.appendChild(a);
          a.click();
          document.body.removeChild(a);
          Mixpanel.track('Job download', { batchId: fileGuid });
        })
        .catch(error => {
          if (error.response && 'status' in error.response) {
            switch (error.response.status) {
              case 404:
                toast.error('Job could not be found. Please try again later.', {
                  autoClose: 10000,
                });
                break;
            }
          }
        });
    });
  };

  const copyJobGuidHandler = async (e, fileGuid) => {
    e.preventDefault();

    try {
      await navigator.clipboard.writeText(fileGuid);
    } catch (err) {
      toast.error('Failed to Copy');
    }
  };

  return !loading && summary && file ? (
    <form>
      <Page2 header="Summary Info">
        {file && (
          <div className="px-3 pt-3">
            <table>
              <tbody>
                <tr>
                  <td className="fw-bold">Job Name:</td>
                  <td>{file.jobName ?? file.name}</td>
                  <td>
                    <PrimaryLink
                      href=""
                      title="Download Job"
                      className="ps-1"
                      onClick={e => downloadPrepHandler(e, file.fileGuid)}
                    >
                      <FontAwesomeIcon icon={faDownload} />
                    </PrimaryLink>
                  </td>
                </tr>
                <tr>
                  <td className="fw-bold">Job Guid:</td>
                  <td>{file.fileGuid}</td>
                  <td>
                    <PrimaryLink
                      href=""
                      title="Copy Job Guid"
                      className="ps-1"
                      onClick={e => copyJobGuidHandler(e, file.fileGuid)}
                    >
                      <FontAwesomeIcon icon={faCopy} />
                    </PrimaryLink>
                  </td>
                </tr>
                <tr>
                  <td className="pe-3 fw-bold">Uploaded Date:</td>
                  <td>{formatDateTme.formatISODateTime(file.createdAt)}</td>
                </tr>
              </tbody>
            </table>
          </div>
        )}
        <div className="p-3">
          <div className="d-flex justify-content-between flex-column flex-md-row">
            <Draft onDownloadClick={handleSummaryDownloadClick} />
            <Arrows />
            {summary.status == summaryStatusEnum.IN_PROGRESS &&
              fileStatus &&
              fileStatus.length > 0 && (
                <>
                  <div className="align-self-center">
                    <Countdown endProcess={fileStatus[0].dateCreated} />
                  </div>
                  <Arrows />
                </>
              )}
            {summary.status == summaryStatusEnum.IN_PROGRESS && (
              <Working
                summary={summary}
                onDownloadClick={handleSummaryDownloadClick}
                onSaveClick={handleSaveClick}
              />
            )}
            {(summary.status == summaryStatusEnum.AWAITING_APPROVAL ||
              summary.status == summaryStatusEnum.APPROVED ||
              summary.status == summaryStatusEnum.COMPLETE) && (
              <Finish
                onDownloadClick={handleSummaryDownloadClick}
                summary={summary}
                onSaveClick={handleSaveClick}
                onApproveClick={handleApproveClick}
              />
            )}
          </div>
        </div>
      </Page2>
    </form>
  ) : (
    ''
  );
};

export default EditSummary;
