import { executeLocalApiCall } from 'utils/executeApiCall';

export default function () {
  try {
    const endpoint = '/api/configuration?value=environment';

    return executeLocalApiCall(endpoint, {
      method: 'GET',
    }).then(response => response.data);
  } catch (error) {
    return Promise.reject(error);
  }
}
