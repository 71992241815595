import { bool, node, number, oneOf } from 'prop-types';
import { useEffect, useState } from 'react';
import { Alert } from 'bootstrap/dist/js/bootstrap';
import { uniqueId } from 'lodash';

const AlertMessage = ({ type, message, showClose, dismissSeconds }) => {
  const [id] = useState(uniqueId());

  useEffect(() => {
    let alertType = 'success';

    switch (type.toLowerCase()) {
      case 'error':
        alertType = 'danger';
        break;
      case 'success':
        alertType = 'success';
        break;
      case 'info':
        alertType = 'info';
        break;
      case 'secondary':
        alertType = 'secondary';
        break;
      default:
        alertType = type;
        break;
    }

    var elemParent = document.createElement('div');
    elemParent.classList.add('alert', `alert-${alertType}`, 'sticky-top');
    elemParent.setAttribute('role', 'alert');
    elemParent.setAttribute('id', `alert${id}`);

    var elemMessage = document.createElement('div');
    elemMessage.classList.add('fw-bold', 'text-center');
    elemMessage.innerHTML = message;
    elemParent.appendChild(elemMessage);

    if (showClose) {
      var elemCloseButton = document.createElement('button');
      elemCloseButton.classList.add('btn-close');
      elemCloseButton.setAttribute('type', 'button');
      elemCloseButton.setAttribute('data-bs-dismiss', 'alert');
      elemCloseButton.setAttribute('aria-label', 'Close');
      elemParent.appendChild(elemCloseButton);
    }

    document.getElementById('parentAlert').appendChild(elemParent);

    let timerId;
    if (dismissSeconds && dismissSeconds > 0) {
      timerId = setTimeout(() => {
        var element = document.getElementById(`alert${id}`);
        var comp = new Alert(element);
        if (comp) {
          comp.close();
        }
      }, dismissSeconds * 1000);
    }

    return () => {
      if (timerId) {
        clearTimeout(timerId);
      }
    };
  }, [type, message]);

  return <div id={'parentAlert'} />;
};

AlertMessage.defaultProps = {
  type: 'success',
  showClose: false,
  onCloseClick: undefined,
  dismissSeconds: undefined,
};

AlertMessage.propTypes = {
  type: oneOf(['success', 'error', 'warning', 'info']),
  message: node.isRequired,
  showClose: bool,
  dismissSeconds: number,
};

export default AlertMessage;
