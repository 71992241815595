import { useState, lazy, Suspense } from 'react';
import { useNavigate } from 'react-router-dom';
import { Auth } from '@aws-amplify/auth';
import { Formik, Form } from 'formik';
import { toast } from 'react-toastify';

import { Mixpanel } from 'utils/mixpanel';

import fetchEnvironment from 'api/settings/fetchEnvironment';

const Signup = () => {
  const navigate = useNavigate();

  const Part1 = lazy(() => import('components/Signup/Part1'));
  const Part2 = lazy(() => import('components/Signup/Part2'));

  const [showCompany, setShowCompany] = useState(false);
  const [baaDateTime, setBaaDateTime] = useState();
  const [tosDateTime, setTosDateTime] = useState();

  document.title = 'Sign Up | DeepDoc by AAICO';

  const Part1Form = ({ errors, touched, handleSubmit }) => {
    let numOfErrors = 0;

    if (!touched.username || hasProperty(errors, 'username')) {
      numOfErrors++;
    }

    if (!touched.firstName || hasProperty(errors, 'firstName')) {
      numOfErrors++;
    }

    if (!touched.lastName || hasProperty(errors, 'lastname')) {
      numOfErrors++;
    }

    if (!touched.password || hasProperty(errors, 'password')) {
      numOfErrors++;
    }

    if (numOfErrors == 0) {
      setShowCompany(true);
    } else {
      handleSubmit(); //forces errors to be shown
    }
  };

  const TosOnClickHandler = value => {
    if (value) {
      setTosDateTime(new Date().toUTCString());
    } else {
      setTosDateTime('');
    }
  };

  const baaOnClickHandler = value => {
    if (value) {
      setBaaDateTime(new Date().toUTCString());
    } else {
      setBaaDateTime('');
    }
  };

  const hasProperty = (object, property) => {
    let result = false;
    result = Object.prototype.hasOwnProperty.call(object, property);
    return result;
  };

  const addUser = async ({
    firstName,
    username,
    lastName,
    password,
    companyName,
    jobTitle,
    address,
    address2,
    city,
    state,
    zip,
  }) => {
    fetchEnvironment()
      .then(async environment => {
        // NOTE: prevent submit if all this field are not filled
        if (!companyName || !address || !city || !state || !zip) return;

        await Auth.signUp({
          username,
          password,
          attributes: {
            given_name: firstName,
            family_name: lastName,
            phone_number: '+19999999999', //default number until user updates
            // TODO: prevent if the required are not fill
            'custom:tos_date_acceptance': tosDateTime,
            'custom:baa_date_acceptance': baaDateTime,
            'custom:company_name': companyName,
            'custom:job_title': jobTitle,
            'custom:address': address,
            'custom:address_2': address2,
            'custom:city': city,
            'custom:state': state,
            'custom:zip': zip,
            'custom:source_environment': environment,
          },
          autoSignIn: {
            // optional - enables auto sign in after user is confirmed
            enabled: true,
          },
        })
          .then(response => {
            Mixpanel.track('Signup requested', {
              username: response.user.username,
            });
            navigate(
              `/signup/confirm?action=emailsent&user_name=${encodeURIComponent(
                response.user.username
              )}`
            );
          })
          .catch(error => {
            switch (error.code) {
              case 'UsernameExistsException':
                toast.error('An account with the given email already exists.', {
                  autoClose: 10000,
                });
                break;

              default:
                toast.error('Please contact support', { autoClose: 5000 });
                break;
            }
          });
      })
      .catch(() => {
        toast.error('Please contact support', { autoClose: 5000 });
      });
  };

  return (
    <Formik
      initialValues={{
        firstName: '',
        username: '',
        lastName: '',
        password: '',
        companyName: '',
        jobTitle: '',
        address: '',
        address2: '',
        city: '',
        state: '',
        zip: '',
        tosCheck: false,
        boaCheck: false,
      }}
      onSubmit={addUser}
      enableReinitialize
    >
      {({ errors, touched }) => (
        <Form className="text-center pt-3 pt-md-0">
          <Suspense>
            {!showCompany && (
              <Part1 errors={errors} touched={touched} onClick={Part1Form} />
            )}
            {showCompany && (
              <Part2
                errors={errors}
                touched={touched}
                baaDateTime={baaOnClickHandler}
                tosDateTime={TosOnClickHandler}
                onClickBack={() => setShowCompany(false)}
              />
            )}
          </Suspense>
        </Form>
      )}
    </Formik>
  );
};

export default Signup;
