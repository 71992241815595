import { Auth } from '@aws-amplify/auth';
import { number } from 'prop-types';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import expiryEvent from 'api/auth/expiryEvent';

import keepAlive from 'utils/keepAlive';

const SiteTimeout = ({ timeOutInterval }) => {
  const navigate = useNavigate();

  let timer = undefined;
  const events = ['click', 'load', 'keydown'];

  const eventHandler = () => {
    keepAlive();

    if (timer) {
      startTimer();
    }
  };

  useEffect(() => {
    addEvents();

    return () => {
      removeEvents();
      clearTimeout(timer);
    };
  }, []);

  const startTimer = () => {
    if (timer) {
      clearTimeout(timer);
    }

    timer = setTimeout(() => {
      Auth.currentAuthenticatedUser()
        .then(() => {
          let lastInteractionTime = localStorage.getItem('lastInteractionTime');

          const total = new Date() - Date.parse(lastInteractionTime);
          const seconds = Math.floor((total / 1000) % 60);

          // console.log(
          //   's',
          //   seconds,
          //   timeOutInterval,
          //   (timeOutInterval / 1000) % 60
          // );

          if (seconds < (timeOutInterval / 1000) % 60) {
            startTimer();
          } else {
            //removeEvents();

            //clearTimeout(timer);

            expiryEvent()
              .then(() => {
                navigate('/logout');
              })
              .catch(() => {
                navigate('/logout');
              });
          }
        })
        .catch(() => {
          keepAlive();
          startTimer();
        });
    }, timeOutInterval);
  };

  const addEvents = () => {
    events.forEach(eventName => {
      window.addEventListener(eventName, eventHandler);
    });

    startTimer();
  };

  const removeEvents = () => {
    events.forEach(eventName => {
      window.removeEventListener(eventName, eventHandler);
    });
  };

  return <></>;
};

SiteTimeout.defaultProps = {
  timeOutInterval: 1200000, // 20 minutes
};

SiteTimeout.propTypes = {
  timeOutInterval: number,
};

export default SiteTimeout;
