import executeApiCall from 'utils/executeApiCall';

export default function (guid) {
  try {
    const endpoint = `/api/users/company/${guid}`;

    return executeApiCall(endpoint, {
      method: 'GET',
    }).then(response => response.data);
  } catch (error) {
    return Promise.reject(error);
  }
}
