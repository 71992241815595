export function formatJobsData(pipelines, companies, statuses, files) {
  var returnArray = [];

  if (pipelines) {
    if (companies) {
      if (statuses) {
        if (files) {
          files.map(file => {
            returnArray.push({
              company: companies.find(
                company =>
                  pipelines.find(pipeline => pipeline.id == file.pipelineId)
                    .companyId == company.id
              ).name,
              id: file.id,
              jobName: file.jobName,
              type: file.summarize ? 'Organize/Summarize' : 'Organize',
              pageCount: file.pageCount,
              isExpress: file.rush ? 'Yes' : 'No',
              instructions: file.instructions,
              created: file.createdAt,
              currentStatus: statuses.find(
                item => item.id == file.currentStatus.statusId
              ).name,
            });
          });
        }
      }
    }
  }

  return returnArray;
}

export default formatJobsData;
