import { faDownload } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { func } from 'prop-types';
import { useState } from 'react';
import { toast } from 'react-toastify';

import Primary from 'components/_common/buttons/Primary';
import PrimaryLink from 'components/_common/links/Primary';
import Uploader from 'components/_common/Uploader';

import uploadSummaryFile from 'api/summaries/uploadSummaryFile';

import summaryStatusEnum from 'components/_common/constants/summaryStatusEnum';

import summaryPropType from 'propTypes/summaryPropType';

import ConfirmPopup from '../_common/ConfirmPopup';

const Finish = ({ summary, onDownloadClick, onSaveClick, onApproveClick }) => {
  const [loading, setLoading] = useState(false);

  const handleDownloadClick = e => {
    e.preventDefault();

    onDownloadClick(
      summary.status == summaryStatusEnum.APPROVED ||
        summary.status == summaryStatusEnum.COMPLETE
        ? 'final'
        : 'working'
    );
  };

  const handleSaveClick = e => {
    if (e) {
      e.preventDefault();
    }
    onSaveClick(e, summaryStatusEnum.IN_PROGRESS, setLoading);
  };

  const handleApproveClick = e => {
    if (e) {
      e.preventDefault();
    }
    onApproveClick();
  };

  const handleFiles = files => {
    toast.promise(uploadSummaryFile(summary.summaryGuid, files), {
      pending: 'Uploading summary...',
      success: 'Summary has been uploaded successfully',
      error: 'There was a problem uploading summary',
    });
  };

  return (
    <div className="card">
      <div className="card-body">
        <div className="card-title">
          <span className="fs-5">Final Summary</span>
        </div>
        <div className="pb-3">
          <PrimaryLink
            onClick={handleDownloadClick}
            title="Download finished summary"
            href=""
          >
            <FontAwesomeIcon icon={faDownload} className="pe-2" />
            Download
            {summary.status != summaryStatusEnum.COMPLETE &&
            summary.status != summaryStatusEnum.APPROVED
              ? ' (working)'
              : ''}
          </PrimaryLink>
        </div>
        {summary.status == summaryStatusEnum.AWAITING_APPROVAL && (
          <>
            <div className="pb-3">
              <Uploader
                caption="Upload"
                title="Upload final summary"
                onFileChange={handleFiles}
                accept=".docx"
              />
            </div>
            <div className="pb-3">
              <Primary
                type="button"
                onClick={handleSaveClick}
                style={{ width: '100%' }}
                processing={loading}
              >
                Not Ready
              </Primary>
            </div>
            <ConfirmPopup
              processing={loading}
              prompt="Do you want to approve this summary?"
              caption="Approve"
              onSaveClick={handleApproveClick}
            />
          </>
        )}
      </div>
    </div>
  );
};

Finish.propTypes = {
  onDownloadClick: func.isRequired,
  summary: summaryPropType.isRequired,
  onSaveClick: func.isRequired,
  onApproveClick: func.isRequired,
};

export default Finish;
