import logo from 'images/finishedHands.png';

const Finish = () => {
  return (
    <>
      <div className="uploader-top">
        <div className="row align-items-center justify-content-center py-2 g-0">
          <img src={logo} />
          <div className="fs-4 text-center mt-4">You&#39;re done!</div>
          <div className="text-center w-75 pt-3">
            Check your Jobs page to see your upload, completed Jobs and recent
            history
          </div>
        </div>
      </div>
      <div className="uploader-line" />
    </>
  );
};

export default Finish;
