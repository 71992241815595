import { useEffect, useRef, useState } from 'react';
import { func, string, bool, array, number } from 'prop-types';
import { v4 } from 'uuid';
import { toast } from 'react-toastify';
import { faCogs } from '@fortawesome/free-solid-svg-icons';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCirclePlus } from '@fortawesome/free-solid-svg-icons/faCirclePlus';

import PrimaryLink from 'components/_common/links/Primary';

import { useJobToClone } from 'components/Upload/context/jobToCloneContext';

import FileToUpload from './FileToUpload';
import DropZone from './DropZone';
import Configurations from './Configurations';

import { pdfjs } from 'react-pdf';

// pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;
pdfjs.GlobalWorkerOptions.workerSrc =
  process.env.PUBLIC_URL + '/vendor/pdf.worker.min.js';

const Uploader = ({
  filesToUpload,
  setFilesToUpload,
  fileBatchId,
  configId,
  isExpress,
  summarize,
  jobName,
  message,
  setConfigId,
  setIsExpress,
  setJobName,
  setMessage,
  setSummarize,
}) => {
  const isExpressRef = useRef();
  const [expressError, setExpressError] = useState();
  const [rerun, setRerun] = useState();
  const jobClone = useJobToClone();
  const uploadRef = useRef(null);

  function handleRemoveFile(file) {
    setFilesToUpload(prev =>
      prev.filter(prevFile => prevFile.fileName !== file.fileName)
    );
  }

  async function handleFileListChange(files) {
    var errors = [];
    for (let i = 0; i < files.length; i++) {
      if (files[i].size == 0) {
        errors.push(files[i].name);
      } else if (files[i].type == 'application/pdf') {
        const reader = new FileReader();
        reader.onloadend = function () {
          const pdfData = new Uint8Array(reader.result);
          pdfjs
            .getDocument({ data: pdfData })
            .promise.then(async pdf => {
              // Check if the PDF file is password-protected
              if (pdf.numPages > 0) {
                const fileGuid = v4();
                const inputObj = {
                  fileName: `${fileGuid}.pdf`,
                  original_fileName: files[i].name,
                  bytes: files[i].size,
                  type: files[i].type,
                  bucket: 'file-lobby',
                  key: `${fileBatchId}/${fileGuid}.pdf`,
                  file: files[i],
                  existing: false,
                };
                setFilesToUpload(prev => [...prev, inputObj]);
              } else {
                // The PDF is password-protected, handle accordingly
                errors.push(files[i].name + ' (Password-protected)');
              }
            })
            .catch(error => {
              let msg = '';
              // InvalidPDFException: Invalid PDF structure.
              // PasswordException: No password given
              if (error.name === 'PasswordException') {
                msg =
                  'File password-protected.  Please un-encrypt the file and try again.';
              } else if (error.name === 'InvalidPDFException') {
                msg = 'File corrupted.  Please upload a valid file.';
              } else {
                msg = 'File corrupted.  Please upload a valid file.';
              }

              toast.error(msg, {
                autoClose: 5000,
              });
              uploadRef.current.value = null;
            })
            .finally(() => {});
        };

        reader.readAsArrayBuffer(files[i]);
      } else if (
        files[i].type ==
        'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
      ) {
        const fileGuid = v4();
        const inputObj = {
          fileName: `${fileGuid}.docx`,
          original_fileName: files[i].name,
          bytes: files[i].size,
          type: files[i].type,
          bucket: 'file-lobby',
          key: `${fileBatchId}/${fileGuid}.docx`,
          file: files[i],
          existing: false,
        };
        setFilesToUpload(prev => [...prev, inputObj]);
      } else {
        errors.push(files[i].name);
      }
    }

    if (errors.length > 0) {
      toast.error('Bad Files: ' + errors.join(', '), { autoClose: 5000 });
    }
  }

  const onFileClick = e => {
    e.preventDefault();
    uploadRef.current?.click();
  };

  function handleAddFiles(file) {
    handleFileListChange([file]);
  }

  function isExpressEnabled() {
    setExpressError('');

    if (isExpressRef.current) {
      var currentTime = new Date();
      var time = currentTime.getHours();

      var available = true;

      var endDateTime = new Date();

      if (time >= 16) {
        endDateTime.setDate(currentTime.getDate() + 1);
        endDateTime.setHours(16, 0);

        available = false;

        setRerun(diff_minutes(currentTime, endDateTime));
        setExpressError('Express cut-off of 12pm has passed for Organize');
      } else if (summarize) {
        endDateTime.setHours(16, 0);

        available = false;

        setRerun(diff_minutes(currentTime, endDateTime));
        setExpressError('Express not available for Summarize');
      }

      isExpressRef.current.disabled = !available;
      if (!available) {
        isExpressRef.current.checked = available;
        setIsExpress(available);
      }

      return available;
    }
  }

  function diff_minutes(dt2, dt1) {
    var diff = (dt2.getTime() - dt1.getTime()) / 1000;
    diff /= 60;

    return Math.abs(Math.round(diff));
  }

  useEffect(() => {
    if (jobClone && filesToUpload.length == 0) {
      setFilesToUpload([]);

      setConfigId(jobClone.configId);
      setJobName(jobClone.jobName);
      setSummarize(jobClone.summarize);
      setMessage(jobClone.message);
      if (isExpressEnabled()) {
        setIsExpress(jobClone.isExpress);
      }

      if (jobClone.jobFiles && jobClone.jobFiles.length > 0) {
        jobClone.jobFiles.map(jobFile => {
          const uniqueId = v4();
          const existingFile = {
            fileName: jobFile.original,
            original_fileName: jobFile.original,
            bytes: jobFile.size ?? 0,
            type: undefined,
            bucket: undefined,
            key: uniqueId,
            file: {},
            existing: true,
          };
          setFilesToUpload(prev => [...prev, existingFile]);
        });
      } else {
        const uniqueId = v4();
        const existingFile = {
          fileName: jobClone.original,
          original_fileName: jobClone.original,
          bytes: jobClone.size,
          type: undefined,
          bucket: undefined,
          key: uniqueId,
          file: {},
          existing: true,
        };
        setFilesToUpload(prev => [...prev, existingFile]);
      }
    }
  }, [jobClone]);

  useEffect(() => {
    isExpressEnabled();
  }, [summarize]);

  useEffect(() => {
    var timer = setTimeout(() => {
      isExpressEnabled();
    }, rerun * 1000);

    return () => {
      clearTimeout(timer);
    };
  }, [rerun]);

  return (
    <>
      <DropZone onAddFile={handleAddFiles} />
      {filesToUpload?.length > 0 && (
        <div
          className="uploader-files overflow-auto"
          style={{ maxHeight: '10rem' }}
        >
          {filesToUpload.map(file => (
            <FileToUpload
              key={file.key}
              file={file}
              onRemoveClick={() => handleRemoveFile(file)}
              existing={file.existing}
            />
          ))}
        </div>
      )}
      <div className="uploader-top">
        <div className="row align-items-center justify-content-center">
          <div className="form-group">
            <div className="col-sm-12 text-center">
              <span className="btn btn-default btn-file">
                <div
                  className="d-flex flex-row justify-content-center align-items-center"
                  onClick={e => onFileClick(e)}
                >
                  <div>
                    <div className="text-primary">
                      <FontAwesomeIcon icon={faCirclePlus} size="2x" />
                    </div>
                  </div>
                  <div className="ps-2 text-center lh-sm">
                    <div className="fs-5 text-center lh-sm">Upload File</div>
                    <div className="text-center text-muted text-decoration-underline text-smaller">
                      or select a folder
                    </div>
                  </div>
                </div>
                <input
                  id="uploadInput"
                  name="uploadInput[]"
                  type="file"
                  className="file d-none"
                  multiple
                  accept="application/pdf,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                  data-show-upload="true"
                  data-show-caption="true"
                  onChange={e => handleFileListChange(e.target.files)}
                  ref={uploadRef}
                />
              </span>
            </div>
          </div>
        </div>
      </div>
      <div className="uploader-line" />
      {jobClone && (
        <>
          <div className="uploader-item bg-light">
            <div
              className="form-floating text-success"
              title={jobClone.jobName}
            >
              <span className="fw-bold pe-3 text-truncate">Original Job:</span>
              {jobClone.jobName}
            </div>
          </div>
          <div className="uploader-line" />
        </>
      )}
      <div className="uploader-item">
        <div className="row g-0 align-items-center">
          <div className="form-floating col-10">
            <Configurations
              id="ddConfigurations"
              configId={configId}
              setConfigId={setConfigId}
            />
            <label htmlFor="ddConfigurations">Configuration</label>
          </div>
          <div className="col-2 text-center">
            <PrimaryLink href="/settings/configuration" title="Configurations">
              <FontAwesomeIcon icon={faCogs} size="2xl" />
            </PrimaryLink>
          </div>
        </div>
      </div>
      <div className="uploader-line" />
      <div className="uploader-item">
        <div className="form-check form-check-inline">
          <input
            className="form-check-input"
            type="radio"
            name="inlineRadioOptions"
            id="inlineRadio1"
            value={'organize'}
            onChange={() => setSummarize(false)}
            checked={!summarize}
          />
          <label className="form-check-label" htmlFor="inlineRadio1">
            Organize
          </label>
        </div>
        <div className="form-check form-check-inline">
          <input
            className="form-check-input"
            type="radio"
            name="inlineRadioOptions"
            id="inlineRadio2"
            value="summarize"
            onChange={() => setSummarize(true)}
            checked={summarize}
          />
          <label className="form-check-label" htmlFor="inlineRadio2">
            Organize & Summarize
          </label>
        </div>
      </div>
      <div className="uploader-line" />
      <div className="uploader-item">
        <div className="form-check ">
          <input
            ref={isExpressRef}
            className="form-check-input"
            type="checkbox"
            onChange={e => setIsExpress(e.target.checked)}
            id="chkExpress"
            checked={isExpress}
          />
          <label className="form-check-label" htmlFor="chkExpress">
            Express (6 hours)
          </label>
          {expressError && <div className="text-danger">{expressError}</div>}
        </div>
      </div>
      <div className="uploader-line" />
      <div className="uploader-item">
        <div className="form-floating">
          <input
            type="text"
            className="form-control"
            id="txtJobName"
            style={{ border: 'none' }}
            onChange={e => setJobName(e.target.value)}
            value={jobName}
            required
          />
          <label htmlFor="txtJobName">Job Name *</label>
        </div>
      </div>
      <div className="uploader-line" />
      <div className="uploader-item">
        <div className="form-floating">
          <textarea
            className="form-control"
            id="txtMessage"
            onChange={e => setMessage(e.target.value)}
            value={message ?? ''}
          />
          <label htmlFor="txtMessage">Message</label>
        </div>
      </div>
      <div className="uploader-line" />
    </>
  );
};

Uploader.defaultProps = { message: undefined, configId: undefined };

Uploader.propTypes = {
  filesToUpload: array.isRequired,
  setFilesToUpload: func.isRequired,
  fileBatchId: string.isRequired,

  isExpress: bool.isRequired,
  configId: number,
  jobName: string.isRequired,
  summarize: bool.isRequired,
  message: string,
  setConfigId: func.isRequired,
  setIsExpress: func.isRequired,
  setJobName: func.isRequired,
  setMessage: func.isRequired,
  setSummarize: func.isRequired,
};

export default Uploader;
