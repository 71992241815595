import { bool, func, oneOf, string } from 'prop-types';
import { Auth } from '@aws-amplify/auth';
import { useEffect, useState } from 'react';

import fetchByCompanyIdInProgress from 'api/file/fetchByCompanyIdInProgress';
import fetchByCompanyIdComplete from 'api/file/fetchByCompanyIdComplete';
import fetchFilesByUserGuid from 'api/file/fetchByUserGuid';
import fetchUser from 'api/users/fetchUser';
import fetchCompany from 'api/companies/fetchCompany';

import convertFileToJob from 'converters/fileToJob';

import JobItem from 'components/_common/JobItem';
import JobItemPlaceHolder from 'components/_common/JobItemPlaceholder';
import Shell from 'components/_common/Shell';

import filterEnum from 'components/_common/constants/jobFilterEnum';

const Files = ({ search, filter, companyFiles, setAlertMessage }) => {
  const [loading, setLoading] = useState(true);
  const [errorMessage, setErrorMessage] = useState();

  const [files, setFiles] = useState([]);
  const [searchFiles, setSearchFiles] = useState();

  const processFiles = files => {
    files.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));

    var filteredFiles = files
      .filter(
        file =>
          (filter === filterEnum.COMPLETE &&
            (file.currentStatus.statusId == 24 ||
              file.currentStatus.statusId == 29)) ||
          (filter === filterEnum.INPROGRESS &&
            file.currentStatus.statusId != 8 &&
            file.currentStatus.statusId != 9 &&
            file.currentStatus.statusId != 10 &&
            file.currentStatus.statusId != 11 &&
            file.currentStatus.statusId != 12 &&
            file.currentStatus.statusId != 13 &&
            file.currentStatus.statusId != 14 &&
            file.currentStatus.statusId != 15 &&
            file.currentStatus.statusId != 16 &&
            file.currentStatus.statusId != 17 &&
            file.currentStatus.statusId != 24 &&
            file.currentStatus.statusId != 29 &&
            file.currentStatus.statusId != 30)
      )
      .map(file => {
        return convertFileToJob(file);
      });

    setFiles(filteredFiles);

    applySearch(filteredFiles);

    setLoading(false);
  };

  const applySearch = f => {
    setErrorMessage();

    var searchedFiles = [];

    if (search) {
      searchedFiles = f.filter(item => {
        return item.jobName.toLowerCase().indexOf(search) != -1;
      });
    } else {
      searchedFiles = f;
    }

    setSearchFiles(searchedFiles);

    if (searchedFiles.length === 0) {
      setErrorMessage(
        'Sorry, there are no jobs matching the filter you have selected'
      );
    }
  };

  const errorFiles = error => {
    setLoading(false);
    setFiles([]);

    if (
      error.response &&
      'status' in error.response &&
      error.response.status === 404
    ) {
      setErrorMessage(
        'Sorry, there are no jobs matching the filter you have selected'
      );
      return;
    }
    setAlertMessage(
      'Unable to load jobs from processing. Please <a href="/jobs" class="link link-danger">try again</a> later.'
    );
  };

  useEffect(() => {
    setErrorMessage();
    setFiles([]);
    setLoading(true);

    Auth.currentUserInfo().then(response => {
      if (filter === filterEnum.COMPLETE || filter === filterEnum.INPROGRESS) {
        if (!companyFiles) {
          fetchFilesByUserGuid(response.username)
            .then(files => {
              processFiles(files);
            })
            .catch(error => {
              errorFiles(error);
            });
        } else {
          fetchUser(response.username)
            .then(user => {
              fetchCompany(user.companyId)
                .then(company => {
                  if (filter == filterEnum.INPROGRESS) {
                    fetchByCompanyIdInProgress(company.id)
                      .then(files => {
                        processFiles(files);
                      })
                      .catch(error => {
                        errorFiles(error);
                      });
                  } else if (filter == filterEnum.COMPLETE) {
                    fetchByCompanyIdComplete(company.id)
                      .then(files => {
                        processFiles(files);
                      })
                      .catch(error => {
                        errorFiles(error);
                      });
                  }
                })
                .catch(error => {
                  errorFiles(error);
                });
            })
            .catch(error => {
              errorFiles(error);
            });
        }
      }
    });
  }, [filter, companyFiles]);

  useEffect(() => {
    applySearch(files);
  }, [search]);

  return (
    <>
      {loading && (
        <div className="mb-3">
          <JobItemPlaceHolder />
        </div>
      )}
      {!loading && !errorMessage && (
        <div>
          {searchFiles &&
            searchFiles.map(file => {
              return (
                <div className="mb-4" key={file.id}>
                  <JobItem key={file.id} job={file} />
                </div>
              );
            })}
        </div>
      )}
      {!loading && errorMessage && (
        <Shell>
          <div className="fs-6 py-3">{errorMessage}</div>
        </Shell>
      )}
    </>
  );
};

Files.defaultProps = {
  search: undefined,
  setAlertMessage: () => {},
};

Files.propTypes = {
  search: string,
  filter: oneOf(Object.values(filterEnum)).isRequired,
  companyFiles: bool.isRequired,
  setAlertMessage: func,
};

export default Files;
