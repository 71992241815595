import { Auth } from 'aws-amplify';
import { func, number, string } from 'prop-types';
import { useEffect, useState } from 'react';

import fetchConfigurationsByPipelineId from 'api/configuration/fetchConfigurationsByPipelineId';
import fetchPipelineByCompanyId from 'api/pipelines/fetchPipelineByCompanyId';
import fetchUser from 'api/users/fetchUser';

const Configurations = ({ id, configId, setConfigId }) => {
  const [configurations, setConfigurations] = useState();

  useEffect(() => {
    Auth.currentAuthenticatedUser()
      .then(authUser => {
        fetchUser(authUser.username)
          .then(user => {
            fetchPipelineByCompanyId(user.companyId).then(async pipeline => {
              fetchConfigurationsByPipelineId(pipeline.id).then(configs => {
                configs.sort((a, b) => {
                  if (a.name > b.name) return 1;
                  if (a.name < b.name) return -1;
                  return 0;
                });
                setConfigurations(configs);

                if (!configId) {
                  configId = configs[0].id;

                  setConfigId(configId);
                }
              });
            });
          })
          .catch(err => console.log('fetchUser', err, authUser));
      })
      .catch(err => console.log('currentAuthenticatedUser', err));
  }, []);

  const handleConfigurationChange = e => {
    setConfigId(parseInt(e.target.value));
  };

  return (
    configurations && (
      <select
        id={id ?? 'ddConfigurations'}
        className="form-select"
        aria-label=".form-select"
        defaultValue={configId}
        onChange={handleConfigurationChange}
      >
        {configurations.map(configuration => {
          return (
            <option key={configuration.id} value={configuration.id}>
              {configuration.name}
              {configuration.isDefault ? ' (default)' : ''}
            </option>
          );
        })}
      </select>
    )
  );
};

Configurations.defaultProps = {
  id: undefined,
  configId: undefined,
  setConfigId: undefined,
};

Configurations.propTypes = {
  id: string,
  configId: number,
  setConfigId: func,
};

export default Configurations;
