import { bool, func, string } from 'prop-types';
import { useEffect, useState } from 'react';
import { Popover } from 'bootstrap/dist/js/bootstrap';

import Primary from 'components/_common/buttons/Primary';
import PrimaryLink from 'components/_common/links/Primary';
import SecondaryLink from 'components/_common/links/Secondary';

import useEventListener from 'hooks/useEventListener';

const ConfirmPopup = ({ onSaveClick, prompt, caption, processing }) => {
  const [popover, setPopover] = useState();

  useEventListener(
    'click',
    function (e) {
      e.preventDefault();
      popover.hide();
    },
    document.getElementById('summaryNo')
  );

  useEventListener(
    'click',
    function (e) {
      e.preventDefault();
      popover.hide();

      onSaveClick();
    },
    document.getElementById('summaryYes')
  );

  useEffect(() => {
    if (popover == null) {
      var pop = document.getElementById('summaryPopover');
      var temp = new Popover(pop, {
        sanitize: false,
        container: 'body',
        html: true,
        trigger: 'focus',
        placement: 'bottom',
        content: document.getElementById('summaryPopover-content'),
      });

      setPopover(temp);
    }
  }, []);

  return (
    <>
      <Primary
        type="button"
        id="summaryPopover"
        style={{ width: '100%' }}
        processing={processing}
      >
        {caption}
      </Primary>
      <div hidden>
        <div id="summaryPopover-content">
          <div className="row">
            <div className="col-12">{prompt}</div>
            <div className="row justify-content-center">
              <div className="col-10 border-bottom my-2" />
            </div>
            <div className="row gy-1">
              <div className="col-12 fs-6 fw-bold">
                <PrimaryLink href="" id="summaryYes">
                  Yes
                </PrimaryLink>
              </div>
              <div className="col-12 fs-6 fw-bold">
                <SecondaryLink href="" id="summaryNo">
                  No
                </SecondaryLink>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

ConfirmPopup.propTypes = {
  processing: bool.isRequired,
  prompt: string.isRequired,
  caption: string.isRequired,
  onSaveClick: func.isRequired,
};

export default ConfirmPopup;
