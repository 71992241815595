import companyPropType from 'propTypes/companyPropType';
import ProfileItem from '../ProfileItem';

const CompanyInfo = ({ company }) => {
  return (
    <>
      <ProfileItem label="Company Name" text={company.name} />
      {company.addressLine1 && (
        <ProfileItem label=" Address" text={company.addressLine1} />
      )}
      {company.addressLine2 && (
        <ProfileItem label="Address 2" text={company.addressLine2} />
      )}
      {company.city && <ProfileItem label="City" text={company.city} />}
      {company.state && <ProfileItem label="State" text={company.state} />}
      {company.postalCode && (
        <ProfileItem label="Zip" text={company.postalCode} />
      )}
      {company.phone && <ProfileItem label="Phone" text={company.phone} />}
    </>
  );
};

CompanyInfo.propTypes = {
  company: companyPropType.isRequired,
};

export default CompanyInfo;
