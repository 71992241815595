import { func, node, string } from 'prop-types';
import { useTranslation } from 'react-i18next';

import PrimaryLink from 'components/_common/links/Primary';

import './style.css';

const Legal = ({
  onClickBack,
  onClickConfirm,
  title,
  formCheckLabel,
  children,
}) => {
  const { t } = useTranslation();

  const onClickBackhandle = e => {
    e.preventDefault();
    onClickBack();
  };

  const onClickConfirmHandler = e => {
    onClickConfirm(e.target.checked);
  };

  return (
    <>
      <div className="uploader-top">
        <div className="row align-items-center justify-content-center">
          <div className="col-3">
            <PrimaryLink href="" onClick={onClickBackhandle}>
              {'< ' + t('Common_Go_Back_Prompt')}
            </PrimaryLink>
          </div>
          <div className="col text-center">
            <div className="text-center fs-5">{title}</div>
          </div>
          <div className="col-3" />
        </div>
      </div>
      <div className="uploader-line" />
      <div className="uploader-item">
        <div className="col-12">
          <div className="form-check mb-1">
            <input
              className="form-check-input"
              type="checkbox"
              value=""
              id="flexCheckDefault"
              onClick={onClickConfirmHandler}
            />
            <label className="form-check-label" htmlFor="flexCheckDefault">
              {formCheckLabel}
            </label>
            {children}
          </div>
        </div>
      </div>
      <div className="uploader-line" />
    </>
  );
};

Legal.propTypes = {
  onClickBack: func.isRequired,
  onClickConfirm: func.isRequired,
  title: string.isRequired,
  children: node.isRequired,
  formCheckLabel: string.isRequired,
};

export default Legal;
