import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons';
import { toast } from 'react-toastify';
import { bool } from 'prop-types';

import {
  useConfigurationPackage,
  useConfigurationPackageUpdate,
} from '../../context/configurationPackageContext';

import PrimaryLink from 'components/_common/links/Primary';
import getPresignedUrl from 'api/downloads/getPresignedUrl';
import fetchEnvironment from 'api/settings/fetchEnvironment';
import Uploader from 'components/_common/Uploader';
import uploadCoverPage from 'api/configuration/uploadCoverPage';

const DocumentSettings = ({ readOnly }) => {
  const configurationPackage = useConfigurationPackage();
  const setConfigurationPackage = useConfigurationPackageUpdate();

  const handleTocChange = () => {
    if (readOnly) {
      return;
    }

    setConfigurationPackage(existing => ({
      ...existing,
      ...{
        configuration: {
          ...configurationPackage.configuration,
          toC_Date_Range: !configurationPackage.configuration.toC_Date_Range,
        },
      },
    }));
  };

  const handlePreviewClick = e => {
    e.preventDefault();

    if (readOnly) {
      return;
    }

    fetchEnvironment().then(environment => {
      getPresignedUrl(
        `deepdoc-config-bucket-${environment}`,
        'cover-pages/DeepDoc_Default_Cover_Page.pdf',
        1
      ).then(url => {
        window.open(url, '_blank');
      });
    });
  };

  const handleFiles = files => {
    if (readOnly) {
      return;
    }

    toast.promise(uploadCoverPage(configurationPackage.pipelineId, files), {
      pending: 'Uploading cover page...',
      success: {
        render({ data }) {
          setConfigurationPackage(existing => ({
            ...existing,
            ...{
              configuration: {
                ...configurationPackage.configuration,
                coverPageUrl: data,
              },
            },
          }));

          return 'Cover page has been uploaded successfully';
        },
      },
      error: 'There was a problem uploading cover page',
    });
  };

  return configurationPackage.configuration ? (
    <>
      <div className="border bg-white mb-3 shadow-sm">
        <div className="bg-light d-flex justify-content-between p-2">
          Cover Page
        </div>
        <div className="p-3">
          <PrimaryLink
            href="#"
            onClick={handlePreviewClick}
            disabled={readOnly}
          >
            <FontAwesomeIcon icon={faExternalLinkAlt} className="pe-2" />
            View Existing Cover Page
          </PrimaryLink>
          <div className="mt-3">
            <label className="form-label">Upload a new Cover Page</label>
            <div className="input-group w-50">
              <input
                type="text"
                className="form-control"
                placeholder="cover page url"
                aria-label="cover page url"
                aria-describedby="button-addon2"
                value={configurationPackage.configuration.coverPageUrl}
                disabled
              />
            </div>
            <div className="text-muted small pt-1">
              * Cover Page is limited to 1 page only
            </div>
            <div className="mt-3">
              <Uploader
                caption="Upload"
                title="Upload cover page"
                onFileChange={handleFiles}
                accept=".pdf"
                disabled={readOnly}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="border bg-white mb-3 shadow-sm">
        <div className="bg-light d-flex justify-content-between p-2">
          Table of Contents
        </div>
        <div className="p-3">
          <div className="form-check form-switch">
            <label className="form-check-label pe-2" htmlFor="toc">
              Date Range Compressed View
            </label>
            <input
              id="toc"
              className="form-check-input"
              type="checkbox"
              checked={configurationPackage.configuration.toC_Date_Range}
              onChange={handleTocChange}
              disabled={readOnly}
            />
          </div>
          <div className="text-muted text-smaller">
            Bundle documents within a section with a date range instead of one
            row for each document
          </div>
        </div>
      </div>
    </>
  ) : (
    'Loading...'
  );
};

DocumentSettings.defaultProps = {
  readOnly: false,
};

DocumentSettings.propTypes = {
  readOnly: bool,
};

export default DocumentSettings;
