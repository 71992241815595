import { Link } from 'react-router-dom';
import Options from './Options';

const Consumer = () => {
  return (
    <>
      <li className="nav-item">
        <Link to="upload" className="nav-link text-nowrap">
          Upload
        </Link>
      </li>
      <li className="nav-item">
        <Link to="jobs" className="nav-link text-nowrap">
          My Jobs
        </Link>
      </li>
      <Options />
    </>
  );
};

export default Consumer;
