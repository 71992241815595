import executeApiCall from 'utils/executeApiCall';

export default function () {
  try {
    const endpoint = '/api/companies';

    return executeApiCall(endpoint, {
      method: 'GET',
    }).then(response => {
      return response.data;
    });
  } catch (error) {
    return Promise.reject(error);
  }
}
