import { Suspense } from 'react';
import { Outlet } from 'react-router-dom';

import Promo2 from 'components/Promos/Promo2';

const AuthParent = () => {
  return (
    <div className="row h-100 g-0">
      <div className="col-md-6 col-12">
        <div className="d-flex justify-content-center align-items-center h-100">
          <Promo2 />
        </div>
      </div>
      <div className="col-md-6 col-12 bg-white">
        <div className="d-flex justify-content-center align-items-center h-100">
          <div className="" style={{ width: '20rem' }}>
            <Suspense>
              <Outlet />
            </Suspense>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AuthParent;
