import { string } from 'prop-types';

const SectionHeader = ({ text }) => {
  return <div className="fw-bold py-3">{text}</div>;
};

SectionHeader.propTypes = {
  text: string.isRequired,
};

export default SectionHeader;
