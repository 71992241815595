export const uniqueDocumentTypes = configurationPackage => {
  var docsToRemove = [];
  configurationPackage.configuration.sections.map(section => {
    if (section.documents.length > 0) {
      section.documents.map(document => {
        docsToRemove.push(document.documentTypeId);
      });
    }
  });

  return configurationPackage.types.filter(
    element => !docsToRemove.includes(element.id)
  );
};

export const reorderDocuments = section => {
  section.documents.forEach((document, idx) => {
    document.position = idx;
  });

  return section.documents;
};

export const reorderSections = configuration => {
  configuration.sections.forEach((section, idx) => {
    section.position = idx;
  });

  return configuration.sections;
};

export const swapElements = (array, index1, index2) => {
  array[index1] = array.splice(index2, 1, array[index1])[0];
};

export const emptySection = () => {
  return {
    id: 0,
    configId: 0,
    name: '',
    position: 0,
    bookmarkType: false,
    bookmarkPhysician: false,
    bookmarkFacility: false,
    documents: [],
  };
};

export const emptyConfiguration = () => {
  return {
    id: 0,
    name: 'New Configuration',
    coverPageUrl: '',
    toC_Date_Range: false,
    pipelineId: 0,
    isDefault: false,
    sections: [],
  };
};
