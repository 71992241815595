import White from 'components/_common/buttons/White';

import promo1 from 'images/promo1.png';

const Promo1 = () => {
  return (
    <div className="d-flex align-items-center justify-content-between">
      <div className="w-50">
        <h2 className="display-5 text-white">Welcome</h2>
        <div className="fs-5 text-white pb-5">
          Please be patient as we roll out the new DeepDoc platform over the
          coming weeks
        </div>
        <White
          type="button"
          onClick={() =>
            (window.location.href = 'mailto:support@aaico.zendesk.com')
          }
        >
          Contact Support
        </White>
      </div>
      <div className="w-50 overflow-hidden" style={{ maxWidth: '20rem' }}>
        <img
          src={promo1}
          style={{ height: '100%', maxHeight: '35rem' }}
          loading="lazy"
          alt="promo"
        />
      </div>
    </div>
  );
};

export default Promo1;
