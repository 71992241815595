import executeApiCall from 'utils/executeApiCall';

export default function (guid_id) {
  try {
    const endpoint = `/api/users/${guid_id}`;

    return executeApiCall(endpoint, {
      method: 'GET',
    }).then(response => response.data);
  } catch (error) {
    return Promise.reject(error);
  }
}
