import { bool, func, string } from 'prop-types';
import { Auth } from '@aws-amplify/auth';
import { useEffect, useState } from 'react';

import fetchFileLobbyByUserId from 'api/fileLobby/fetchByUserId';
import fetchFileLobbyByCompanyGuid from 'api/fileLobby/fetchByCompanyGuid';
import cancelFileLobby from 'api/fileLobby/cancel';
import processFileLobbyNow from 'api/fileLobby/processNow';
import fetchUser from 'api/users/fetchUser';
import fetchCompany from 'api/companies/fetchCompany';

import JobItemPlaceHolder from 'components/_common/JobItemPlaceholder';
import JobItem from 'components/_common/JobItem';
import Shell from 'components/_common/Shell';

import { Mixpanel } from 'utils/mixpanel';

import convertFileLobbyToJob from 'converters/fileLobbyToJob';

const FileLobbyItems = ({ search, setAlertMessage, companyFiles }) => {
  const [loading, setLoading] = useState(true);
  const [errorMessage, setErrorMessage] = useState();

  const [files, setFiles] = useState([]);
  const [searchFiles, setSearchFiles] = useState();

  const processFileLobbyFiles = files => {
    files.sort((a, b) => new Date(b.startProcess) - new Date(a.startProcess));

    setFiles(files.map(fileLobby => convertFileLobbyToJob(fileLobby)));

    applySearch(files.map(fileLobby => convertFileLobbyToJob(fileLobby)));

    setLoading(false);
  };

  const errorFileLobbyFiles = error => {
    setLoading(false);
    setFiles([]);

    if (
      error.response &&
      'status' in error.response &&
      error.response.status === 404
    ) {
      noJobsFound();

      return;
    }
    setAlertMessage(
      'Unable to load jobs from staging. Please <a href="/jobs" class="link link-danger">try again</a> later.'
    );
  };

  const applySearch = f => {
    setErrorMessage();

    var searchedFiles = [];

    if (search) {
      searchedFiles = f.filter(item => {
        return item.jobName.toLowerCase().indexOf(search) != -1;
      });
    } else {
      searchedFiles = f;
    }

    setSearchFiles(searchedFiles);

    if (searchedFiles.length === 0) {
      setErrorMessage(
        'Sorry, there are no jobs matching the filter you have selected'
      );
    }
  };

  useEffect(() => {
    fetchData();
  }, [companyFiles]);

  useEffect(() => {
    applySearch(files);
  }, [search]);

  const fetchData = () => {
    setErrorMessage();
    setFiles([]);
    setLoading(true);

    Auth.currentUserInfo().then(response => {
      if (!companyFiles) {
        fetchFileLobbyByUserId(response.username)
          .then(file_lobby_files => {
            processFileLobbyFiles(file_lobby_files);
          })
          .catch(error => {
            errorFileLobbyFiles(error);
          });
      } else {
        fetchUser(response.username)
          .then(user => {
            fetchCompany(user.companyId)
              .then(company => {
                fetchFileLobbyByCompanyGuid(company.guidId)
                  .then(file_lobby_files => {
                    processFileLobbyFiles(file_lobby_files);
                  })
                  .catch(error => {
                    errorFileLobbyFiles(error);
                  });
              })
              .catch(error => {
                errorFileLobbyFiles(error);
              });
          })
          .catch(error => {
            errorFileLobbyFiles(error);
          });
      }
    });
  };

  const handleCancelClick = batchId => {
    if (batchId) {
      cancelFileLobby(batchId).then(() => {
        Mixpanel.track('Job cancelled', { batchId: batchId });

        var deletedIndex = files.findIndex(
          element => element.batchId === batchId
        );
        setFiles(a => [
          ...a.slice(0, deletedIndex),
          ...a.slice(deletedIndex + 1, a.length),
        ]);

        if (files.length == 0) {
          noJobsFound();
        }
      });
    }
  };

  const handleOnTimerFinish = batchId => {
    console.log('Time finished. Do something' + batchId);
  };

  const handleProcessJobClick = batchId => {
    if (batchId) {
      processFileLobbyNow(batchId).then(() => {
        Mixpanel.track('Job waiting period cancelled');

        var deletedIndex = files.findIndex(
          element => element.batchId === batchId
        );
        setFiles(a => [
          ...a.slice(0, deletedIndex),
          ...a.slice(deletedIndex + 1, a.length),
        ]);

        fetchData();
      });
    }
  };

  const noJobsFound = () => {
    setErrorMessage(
      'Sorry, there are no jobs matching the filter you have selected'
    );
  };

  return (
    <>
      {loading && (
        <div className="mb-3">
          <JobItemPlaceHolder />
        </div>
      )}
      {!loading && (
        <div>
          {searchFiles.length > 0 && (
            <div className="row g-0 pb-3">
              <div className="col">
                <div className="badge rounded-lg-pill bg-success">
                  {searchFiles.length} new
                </div>
              </div>
            </div>
          )}
          {searchFiles.map(fileLobby => {
            return (
              <div className="mb-4" key={fileLobby.id}>
                <JobItem
                  key={fileLobby.id}
                  job={fileLobby}
                  onCancelClick={() => handleCancelClick(fileLobby.batchId)}
                  onTimerFinish={() => handleOnTimerFinish(fileLobby.batchId)}
                  onProcessJobClick={() =>
                    handleProcessJobClick(fileLobby.batchId)
                  }
                />
              </div>
            );
          })}
        </div>
      )}
      {!loading && errorMessage && (
        <Shell>
          <div className="fs-6 py-3">{errorMessage}</div>
        </Shell>
      )}
    </>
  );
};

FileLobbyItems.defaultProps = {
  search: undefined,
};

FileLobbyItems.propTypes = {
  search: string,
  companyFiles: bool.isRequired,
  setAlertMessage: func.isRequired,
};

export default FileLobbyItems;
