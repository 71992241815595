import { func, array, bool } from 'prop-types';
import { useEffect, useState } from 'react';
import { Formik, Form, Field } from 'formik';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { toast } from 'react-toastify';

import {
  validateEmail,
  validateField,
  validatePhoneWithCountryCode,
} from 'utils/validateFields';

import Primary from 'components/_common/buttons/Primary';
import PrimaryLink from 'components/_common/links/Primary';
import SecondaryLink from 'components/_common/links/Secondary';

import saveUser from 'api/users/saveUser';

import Roles from './Roles';

const EditUser = ({ user, setUserEdit, setReload, getCompanies }) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [companies, setCompanies] = useState();
  const [onSubmit, setOnSubmit] = useState(false);
  const [userObj, setUserObj] = useState({});
  const [enableRoles, setEnableRoles] = useState(false);

  document.title = 'Edit/Add User | DeepDoc by AAICO';

  useEffect(() => {
    setLoading(true);
    setCompanies(getCompanies);

    if (user.length > 0) {
      setUserObj(...user);
      setEnableRoles(true);
    }

    setLoading(false);
  }, [userObj]);

  const saveUserHandler = values => {
    setOnSubmit(true);

    saveUser(values)
      .then(response => {
        setUserObj(response);
        setReload(true);
        toast.success('User saved');

        setEnableRoles(true);
        setOnSubmit(false);
      })
      .catch(() => {
        setOnSubmit(false);
        toast.error('User was not saved');
      });
  };

  const goBackHandler = e => {
    e.preventDefault();
    setUserEdit(false);
  };

  return (
    <>
      {!loading && (
        <div className="">
          <div className="row">
            <div className="d-flex justify-content-end pe-4">
              <PrimaryLink id="goBackLink" href="" onClick={goBackHandler}>
                <FontAwesomeIcon icon={faArrowLeft} size="sm" /> Back
              </PrimaryLink>
            </div>
            <ul className="nav nav-pills ps-4" role="tablist" id="userTabs">
              <li className="nav-item">
                <a
                  className="nav-link active"
                  data-bs-toggle="tab"
                  href="#user"
                >
                  User
                </a>
              </li>
              <li className="nav-item">
                <a
                  className={enableRoles ? 'nav-link' : 'nav-link disabled'}
                  data-bs-toggle="tab"
                  href="#roles"
                >
                  Roles
                </a>
              </li>
            </ul>
            <div className="tab-content">
              <div id="user" className="tab-pane active">
                <Formik
                  initialValues={{
                    id: userObj.id || null,
                    cognitoId:
                      userObj.cognitoId ||
                      '00000000-0000-0000-0000-000000000000',
                    firstName: userObj.firstName || '',
                    lastName: userObj.lastName || '',
                    email: userObj.email || '',
                    phone: userObj.phone || '',
                    companyId: userObj.companyId || '',
                    isActive:
                      Object.keys(userObj).length === 0
                        ? true
                        : userObj.isActive,
                  }}
                  onSubmit={saveUserHandler}
                  enableReinitialize
                >
                  {({ errors, touched, setFieldValue }) => (
                    <Form>
                      <div className="card rounded-0 mt-3">
                        <div className="card-body">
                          <div className="form-check text-start d-flex justify-content-end mb-3">
                            <Field
                              type="checkbox"
                              className={
                                errors.isActive && touched.isActive
                                  ? 'form-check-input is-invalid me-1'
                                  : 'form-check-input me-1'
                              }
                              name="isActive"
                            />
                            <label
                              className="form-check-label"
                              htmlFor="isActive"
                            >
                              Active
                            </label>
                          </div>
                          <div className="form-floating mb-3">
                            <Field
                              type="text"
                              className={
                                errors.cognitoId && touched.cognitoId
                                  ? 'form-control is-invalid'
                                  : 'form-control'
                              }
                              name="cognitoId"
                              placeholder="Cognito ID"
                              disabled
                            />
                            {errors.cognitoId && touched.cognitoId && (
                              <div className="invalid-feedback text-center">
                                {errors.cognitoId}
                              </div>
                            )}
                            <label>Cognito ID</label>
                          </div>
                          <div className="form-floating mb-3">
                            <Field
                              name="companyId"
                              as="select"
                              className={
                                errors.companyId && touched.companyId
                                  ? 'form-control is-invalid'
                                  : 'form-control'
                              }
                              validate={validateField}
                            >
                              <option />
                              {companies?.map(company => {
                                return (
                                  <option
                                    key={company.guidId}
                                    value={`${company.id}`}
                                  >
                                    {company.name}
                                  </option>
                                );
                              })}
                            </Field>
                            {errors.companyId && touched.companyId && (
                              <div className="invalid-feedback text-center">
                                {errors.companyId}
                              </div>
                            )}
                            <label>Company Name</label>
                          </div>
                          <div className="form-floating mb-3">
                            <Field
                              type="text"
                              className={
                                errors.firstName && touched.firstName
                                  ? 'form-control is-invalid'
                                  : 'form-control'
                              }
                              name="firstName"
                              placeholder="First Name"
                              validate={validateField}
                            />
                            {errors.firstName && touched.firstName && (
                              <div className="invalid-feedback text-center">
                                {errors.firstName}
                              </div>
                            )}
                            <label>First Name</label>
                          </div>
                          <div className="form-floating mb-3">
                            <Field
                              type="text"
                              className={
                                errors.lastName && touched.lastName
                                  ? 'form-control is-invalid'
                                  : 'form-control'
                              }
                              name="lastName"
                              placeholder="Last Name"
                              validate={validateField}
                            />
                            {errors.lastName && touched.lastName && (
                              <div className="invalid-feedback text-center">
                                {errors.lastName}
                              </div>
                            )}
                            <label>Last Name</label>
                          </div>
                          <div className="form-floating mb-3">
                            <Field
                              type="text"
                              className={
                                errors.email && touched.email
                                  ? 'form-control is-invalid'
                                  : 'form-control'
                              }
                              name="email"
                              placeholder="Email"
                              validate={validateEmail}
                            />
                            {errors.email && touched.email && (
                              <div className="invalid-feedback text-center">
                                {errors.email}
                              </div>
                            )}
                            <label>Email</label>
                          </div>
                          <div className="form-floating mb-3">
                            <Field
                              type="text"
                              className={
                                errors.phone && touched.phone
                                  ? 'form-control is-invalid'
                                  : 'form-control'
                              }
                              name="phone"
                              placeholder="Phone"
                              validate={validatePhoneWithCountryCode}
                            />
                            {errors.phone && touched.phone && (
                              <div className="invalid-feedback text-center">
                                {errors.phone}
                              </div>
                            )}
                            <small className="text-muted">
                              Enter a phone number, including + and the country
                              code, for example +12065551212.
                            </small>
                            <SecondaryLink
                              href=""
                              className="float-end"
                              onClick={e => {
                                e.preventDefault();
                                setFieldValue('phone', '+19999999999');
                              }}
                            >
                              Use Default Number
                            </SecondaryLink>
                            <label>Phone</label>
                          </div>
                        </div>
                      </div>
                      <div className="text-center mb-4 mt-2">
                        <Primary type="submit" disabled={onSubmit}>
                          {Object.keys(userObj).length === 0
                            ? t('Common_Add_User_Prompt')
                            : t('Common_Edit_User_Prompt')}
                        </Primary>
                      </div>
                    </Form>
                  )}
                </Formik>
              </div>
              {enableRoles && <Roles user={userObj} key={userObj} />}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

EditUser.defaultProps = {
  isUserEdit: false,
  user: [],
  setUserEdit: undefined,
  setReload: undefined,
  getCompanies: [],
};

EditUser.propTypes = {
  isUserEdit: bool,
  user: array,
  setUserEdit: func,
  setReload: func,
  getCompanies: array,
};

export default EditUser;
