const JobItemPlaceHolder = () => {
  return (
    <div className="mb-2 bg-white p-3 rounded shadow" aria-hidden="true">
      <div className="row g-3 placeholder-glow">
        <div className="col-md-3 col-12">
          <span className="placeholder col-6" />
        </div>
        <div className="col-md-3 col-12">
          <span className="placeholder col-6" />
        </div>
        <div className="col-md-4 col-12">
          <span className="placeholder col-8" />
        </div>
        <div className="col-md-2 col-12 align-self-start">
          <div className="row">
            <div className="col-6 align-self-center">
              <span className="bg-danger placeholder col-11" />
              <br />
              <span className="placeholder col-12" />
            </div>
          </div>
        </div>
      </div>
      <div className="row g-3 placeholder-glow">
        <div className="col-md-3 col-12">
          <span className="bg-primary placeholder col-4" />
        </div>
        <div className="col-md-3 col-12">
          <span className="placeholder col-5" />
        </div>
        <div className="col-md-4 col-12">
          <span className="placeholder col-0" />
        </div>
      </div>
    </div>
  );
};

export default JobItemPlaceHolder;
