export default {
  UPLOAD_ERROR: 1,
  PREPROC_ERROR: 2,
  OCR_ERROR: 3,
  ANALYZE_ERROR: 4,
  COMPLETE_ERROR: 5,
  UPLOAD: 6,
  UPLOAD_CONFIRMATION: 7,
  REMOVED: 8,
  REMOVE_ERROR: 9,
  REMOVE_PIPELINE_ISSUE: 10,
  REMOVE_DUPLICATE_ERROR: 11,
  REMOVED_DUPLICATE_ERROR: 12,
  REMOVED_DUPLICATE: 13,
  DUPLICATE: 14,
  REMOVE: 15,
  DUPLICATE_PIPELINE_ERROR: 16,
  ARCHIVED: 17,
  PREPROC: 18,
  QA_HOLD: 19,
  QA_HOLD_FILE_READY: 20,
  QA_COMPLETE: 21,
  AWAITING_QA: 22,
  IN_QA: 23,
  COMPLETE: 24,
  OCR: 25,
  PDF_GENERATION: 26,
  ANALYZE: 27,
  ASSIGNED_QA: 28,
  DOWNLOAD: 29,
  CLONED_HIDE: 30,
};

export const getDescription = id => {
  switch (id) {
    case 1:
      return 'Upload Error';
    case 2:
      return 'PreProc Error';
    case 3:
      return 'OCR Error';
    case 4:
      return 'Analyze Error';
    case 5:
      return 'Complete Error';
    case 6:
      return 'Upload';
    case 7:
      return 'Upload Confirmation';
    case 8:
      return 'Removed';
    case 9:
      return 'Remove Error';
    case 10:
      return 'Remove Pipeline Issue';
    case 11:
      return 'Remove Duplicate Error';
    case 12:
      return 'Removed Duplicate Error';
    case 13:
      return 'Removed Duplicate';
    case 14:
      return 'Duplicate';
    case 15:
      return 'Remove';
    case 16:
      return 'Duplicate Pipeline Error';
    case 17:
      return 'Archived';
    case 18:
      return 'PreProc';
    case 19:
      return 'QA Hold';
    case 20:
      return 'QA Hold File Ready';
    case 21:
      return 'QA Complete';
    case 22:
      return 'Awaiting QA';
    case 23:
      return 'In QA';
    case 24:
      return 'Complete';
    case 25:
      return 'OCR';
    case 26:
      return 'PDF Generation';
    case 27:
      return 'Analyze';
    case 28:
      return 'Assigned QA';
    case 29:
      return 'Download';
    case 30:
      return 'Cloned/Hide';
  }
};
