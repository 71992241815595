import { useTranslation } from 'react-i18next';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { useState, useEffect } from 'react';
import { Auth } from '@aws-amplify/auth';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { Formik, Form, Field } from 'formik';

import {
  validatePassword,
  validateEmail,
  validateField,
} from 'utils/validateFields';

import PrimaryButton from 'components/_common/buttons/Primary';
import AlertMessage from 'components/_common/alertMessage';

import Logo from 'components/_common/Logo';

const ChangePassword = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  let location = useLocation();

  const [alertType, setAlertType] = useState();
  const [alertMessage, setAlertMessage] = useState();
  const [username, setUsername] = useState('');

  document.title = 'Change Password';

  useEffect(() => {
    if (location.state != undefined) {
      setUsername(location.state.username);
      setAlertType('success');
      setAlertMessage(location.state.message);
    }
  }, []);

  const errorClass =
    'form-control form-control-lg shadow-sm is-invalid rounded-2';

  async function resendConfirmationCode(event, email) {
    event.preventDefault();
    try {
      await Auth.resendSignUp(email);
      setAlertType('success');
      setAlertMessage('A new code has been sent. Please check your email.');
    } catch (err) {
      setAlertType('error');
      setAlertMessage('Error resending code.');
    }
  }

  const formSubmitHandler = ({ email, code, password }) => {
    Auth.forgotPasswordSubmit(email, code, password)
      .then(data => {
        if (data === 'SUCCESS') {
          navigate('/login', { username: email });
        }
      })
      .catch(err => {
        setAlertType('error');
        setAlertMessage(err.message);
      });
  };

  return (
    <>
      {alertType && alertMessage && (
        <AlertMessage
          type={alertType}
          message={alertMessage}
          showClose={false}
          dismissSeconds={10}
        />
      )}
      <Formik
        enableReinitialize
        onSubmit={formSubmitHandler}
        initialValues={{
          code: '',
          email: username,
          password: '',
        }}
      >
        {({ errors, touched, values }) => (
          <Form className="needs-validation" noValidate="" autoComplete="off">
            <div className="text-center mb-4 d-flex justify-content-center">
              <div className="align-self-center">
                <Link to="/reset" className="pe-3" title="Back">
                  <FontAwesomeIcon icon={faArrowLeft} size="xl" />
                </Link>
                <Logo />
              </div>
            </div>
            <div className="mb-3 col text-center fs-6">
              {t('Common_Change_Password_Prompt')}
            </div>
            <div className="row">
              <div className="input-group mb-3">
                <Field
                  type="text"
                  name="code"
                  className={
                    errors.code && touched.code
                      ? errorClass
                      : 'form-control form-control-lg'
                  }
                  placeholder={t('Common_Verification_Code')}
                  aria-label={t('Common_Verification_Code')}
                  validate={validateField}
                />
                {errors.code && touched.code && (
                  <div className="invalid-feedback text-center">
                    {errors.code}
                  </div>
                )}
              </div>
            </div>
            <div className="row">
              <div className="input-group mb-3">
                <Field
                  type="email"
                  className={
                    errors.email && touched.email
                      ? errorClass
                      : 'form-control form-control-lg'
                  }
                  placeholder="Email"
                  aria-label="Email"
                  name="email"
                  validate={validateEmail}
                />
                {errors.email && touched.email && (
                  <div className="invalid-feedback text-center">
                    {errors.email}
                  </div>
                )}
              </div>
            </div>
            <div className="row mb-2">
              <div className="input-group mb-3">
                <Field
                  type="password"
                  name="password"
                  className={
                    errors.password && touched.password
                      ? errorClass
                      : 'form-control form-control-lg'
                  }
                  placeholder={t('Common_New_Password')}
                  aria-label={t('Common_New_Password')}
                  validate={validatePassword}
                />
                {errors.password && touched.password && (
                  <div className="invalid-feedback text-center">
                    {errors.password}
                  </div>
                )}
              </div>
            </div>
            <div className="text-center">
              <PrimaryButton type="submit">
                {t('Common_Change_Password')}
              </PrimaryButton>
            </div>
            {values.email && !errors.email && (
              <div className="mt-3 text-center fs-6">
                Click{' '}
                <a
                  href="#"
                  className="pe-auto"
                  onClick={event => resendConfirmationCode(event, values.email)}
                >
                  here
                </a>{' '}
                to resend your code
              </div>
            )}
          </Form>
        )}
      </Formik>
    </>
  );
};

export default ChangePassword;
