import { node } from 'prop-types';

const White = ({ children, ...rest }) => {
  return (
    // eslint-disable-next-line react/button-has-type
    <button
      className="btn btn-light btn rounded-pill px-5 py-2 text-black fw-bold"
      {...rest}
    >
      {children}
    </button>
  );
};

White.propTypes = {
  children: node.isRequired,
};

export default White;
