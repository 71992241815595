import executeApiCall from 'utils/executeApiCall';

export default function (pipelineId, files) {
  try {
    if (files && files.length > 0) {
      const endpoint = `/api/configuration/${pipelineId}/coverpage?name=${files[0].name}`;

      let formData = new FormData();
      formData.append('file', files[0]);

      return executeApiCall(endpoint, {
        method: 'POST',
        data: formData,
        headers: { 'Content-Type': 'multipart/form-data' },
      }).then(response => {
        return response.data;
      });
    }
  } catch (error) {
    return Promise.reject(error);
  }
}
