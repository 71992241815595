import { faDownload } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { func } from 'prop-types';

import PrimaryLink from 'components/_common/links/Primary';

const Draft = ({ onDownloadClick }) => {
  const handleDownloadClick = e => {
    e.preventDefault();

    onDownloadClick('draft');
  };

  return (
    <div className="card">
      <div className="card-body">
        <div className="card-title">
          <span className="fs-5">AI Generated Summary</span>
        </div>
        <PrimaryLink
          href=""
          onClick={handleDownloadClick}
          title="Download summary draft"
        >
          <FontAwesomeIcon icon={faDownload} className="pe-2" />
          Download
        </PrimaryLink>
      </div>
    </div>
  );
};

Draft.propTypes = {
  onDownloadClick: func.isRequired,
};

export default Draft;
