import { number } from 'prop-types';
import { useEffect, useState } from 'react';
import { Loader } from '@aws-amplify/ui-react';

import '@aws-amplify/ui-react/styles.css';

import bytesToSize from 'utils/formatBytes';

const Transfer = ({
  filesCount,
  filesUploaded,
  percentComplete,
  totalBytes,
}) => {
  const [bytesTransferred, setBytesTransferred] = useState(0);
  const [totalBytesFormatted, setTotalBytesFormatted] = useState();

  useEffect(() => {
    setTotalBytesFormatted(bytesToSize(totalBytes));
  }, []);

  useEffect(() => {
    if (totalBytes != 0 && percentComplete != 0) {
      setBytesTransferred(
        bytesToSize(Math.floor(totalBytes * (percentComplete / 100)))
      );
    }
  }, [percentComplete]);

  return (
    <>
      <div className="uploader-item pb-4">
        <div className="row align-items-center justify-content-center py-5">
          <div className="d-flex justify-content-center">
            <div className="position-relative">
              <Loader
                percentage={percentComplete}
                isDeterminate
                width="10rem"
                height="10rem"
                isPercentageTextHidden
                filledColor={getComputedStyle(
                  document.documentElement
                ).getPropertyValue('--bs-primary')}
              />
              <div className="position-absolute top-50 start-50 translate-middle">
                <span className="fs-1 fw-bold">{percentComplete}</span>
                <span className="text-muted">%</span>
              </div>
            </div>
          </div>
        </div>
        <div className="row text-center">
          <div className="col fs-5">Transferring...</div>
        </div>
        <div className="row text-center py-2">
          <div className="col fs-6" key={filesUploaded}>
            {`Sending ${filesUploaded} of ${filesCount} file${
              filesCount > 1 ? '(s)' : ''
            }`}
          </div>
        </div>
        <div className="d-flex justify-content-center py-2">
          <div className="uploader-line w-50" />
        </div>
        <div className="d-flex justify-content-center py-2">
          {`${bytesTransferred} of ${totalBytesFormatted} uploaded`}
        </div>
      </div>
      <div className="uploader-line" />
    </>
  );
};

Transfer.defaultProps = {
  percentComplete: 0,
};

Transfer.propTypes = {
  filesCount: number.isRequired,
  filesUploaded: number.isRequired,
  percentComplete: number,
  totalBytes: number.isRequired,
};

export default Transfer;
