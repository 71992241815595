import { bool, shape, string } from 'prop-types';
import {
  faChevronDown,
  faChevronUp,
  faTrashAlt,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import PrimaryLink from 'components/_common/links/Primary';
import {
  useConfigurationPackage,
  useConfigurationPackageUpdate,
} from 'components/Settings/Configuration/context/configurationPackageContext';
import {
  reorderDocuments,
  swapElements,
} from 'components/Settings/Configuration/utils';

const Document = ({ document, title, readOnly }) => {
  const configurationPackage = useConfigurationPackage();
  const setConfigurationPackage = useConfigurationPackageUpdate();

  const section = configurationPackage.configuration.sections.find(
    item => item.id == document.configSectionId
  );

  const docIndex = section.documents.findIndex(item => {
    return item.documentTypeId == document.documentTypeId;
  });

  const isDownAvailable = document.position != section.documents.length - 1;
  const isUpAvailable = document.position != 0;

  const handleRemoveDocument = e => {
    e.preventDefault();

    if (readOnly) {
      return;
    }

    section.documents = [
      ...section.documents.slice(0, docIndex),
      ...section.documents.slice(docIndex + 1, section.documents.length),
    ];

    section.documents = reorderDocuments(section);

    setConfigurationPackage(existing => ({
      ...existing,
      ...configurationPackage.configuration,
    }));
  };

  const handleMoveDown = e => {
    e.preventDefault();

    if (readOnly) {
      return;
    }

    swapElements(section.documents, docIndex, docIndex + 1);

    section.documents = reorderDocuments(section);

    setConfigurationPackage(existing => ({
      ...existing,
      ...configurationPackage.configuration,
    }));
  };

  const handleMoveUp = e => {
    e.preventDefault();

    if (readOnly) {
      return;
    }

    swapElements(section.documents, docIndex, docIndex - 1);

    section.documents = reorderDocuments(section);

    setConfigurationPackage(existing => ({
      ...existing,
      ...configurationPackage.configuration,
    }));
  };

  return (
    <div className="d-flex justify-content-between border-bottom py-2">
      <div>{title}</div>
      <div>
        <PrimaryLink
          className={`pe-2 ${readOnly ? 'disabled-link' : ''}`}
          href="#"
          title="Remove document"
          onClick={e => handleRemoveDocument(e)}
        >
          <FontAwesomeIcon icon={faTrashAlt} />
        </PrimaryLink>
        <PrimaryLink
          className={`px-1 ${
            isUpAvailable && !readOnly ? '' : 'disabled-link'
          }`}
          href="#"
          title="Move up"
          onClick={isUpAvailable ? handleMoveUp : void 0}
        >
          <FontAwesomeIcon icon={faChevronUp} />
        </PrimaryLink>
        <PrimaryLink
          className={`px-1 ${
            isDownAvailable && !readOnly ? '' : 'disabled-link'
          }`}
          href="#"
          title="Move down"
          onClick={isDownAvailable ? handleMoveDown : void 0}
        >
          <FontAwesomeIcon icon={faChevronDown} />
        </PrimaryLink>
      </div>
    </div>
  );
};

Document.defaultProps = { readOnly: false };

Document.propTypes = {
  document: shape().isRequired,
  title: string.isRequired,
  readOnly: bool,
};

export default Document;
