import { useEffect, useState } from 'react';
import { bool, func } from 'prop-types';
import { uniqueId } from 'lodash';
import { Collapse } from 'bootstrap/dist/js/bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faChevronUp,
  faPersonChalkboard,
} from '@fortawesome/free-solid-svg-icons';

import PrimaryLink from 'components/_common/links/Primary';
import jobPropType from 'propTypes/jobPropType';

import { formatISODate, formatISOTime } from 'utils/formatDateTme';
import bytesToSize from 'utils/formatBytes';

import Shell from '../Shell';

import JobStatus from './JobStatus';
import AddFiles from './AddFiles';

const JobItem = ({
  job,
  onCancelClick,
  onTimerFinish,
  onProcessJobClick,
  isAdmin,
}) => {
  const [id] = useState('collapse' + uniqueId());
  const [collapse, setCollapse] = useState();
  const [isOpen, setIsOpen] = useState(true);

  useEffect(() => {
    var col = document.getElementById(id);
    if (col) {
      var temp = new Collapse(col, {
        toggle: false,
      });
      setCollapse(temp);
    }
  }, []);

  const formatUserName = user => {
    if (user == undefined) {
      return 'N/A';
    }

    if (user.firstName != undefined && user.lastName != undefined) {
      return user.firstName + ' ' + user.lastName;
    }

    if (user.firstName != undefined && user.lastName == undefined) {
      return user.firstName;
    }

    return user.lastName;
  };

  return (
    job && (
      <Shell>
        <div className="row align-items-start">
          <div className="col-12 col-md-3">
            <div className="row">
              <div className="col-12 fw-bold">Job Name</div>
              <div
                className="col-12 text-truncate"
                title={job.jobName ?? 'Job: ' + job.originalName}
              >
                {job.jobName}
              </div>
            </div>
            <div className="row align-items-start align-self-center align-items-end">
              <div className="col-12 py-2 text-truncate">
                {job.jobFiles && job.jobFiles.length <= 1 && (
                  <div
                    className="text-truncate"
                    title={job.original + ' (' + bytesToSize(job.size) + ')'}
                  >
                    {job.original}{' '}
                    {job.size ? '(' + bytesToSize(job.size) + ')' : ''}
                  </div>
                )}
                {job.jobFiles && job.jobFiles.length > 1 && (
                  <PrimaryLink
                    href=""
                    onClick={e => {
                      e.preventDefault();

                      collapse.toggle();

                      setIsOpen(prev => !prev);
                    }}
                  >
                    Uploaded files {isOpen ? '(collapse)' : '(expand)'}
                  </PrimaryLink>
                )}
              </div>
            </div>
          </div>
          <div className="col-12 col-md-3">
            <div className="row">
              <div className="col-12 text-truncate">
                <span className="pe-2 fw-bold">Job:</span>
                {job.summarize ? 'Organize & Summarize' : 'Organize'}
                {job.pageCount
                  ? ` (${job.pageCount.toLocaleString()} pages)`
                  : ''}
              </div>
            </div>
            <div className="row">
              <div className="col-12 py-2">
                <span className="pe-2 fw-bold">Date:</span>
                {formatISODate(job.createdAt)}
                <span className="px-2 fw-bold">at</span>
                {formatISOTime(job.createdAt)}
              </div>
            </div>
            {job.user && (
              <div className="row">
                <div className="col-12">
                  <span className="pe-2 fw-bold">User:</span>
                  {formatUserName(job.user)}
                </div>
              </div>
            )}
          </div>
          <div className="col-md-4 col-12 text-break">
            {job.message && (
              <div className="row g-0">
                <div className="col-12 fw-bold">Instructions</div>
                <div className="col-12">{job.message ?? 'No instructions'}</div>
              </div>
            )}
          </div>
          <div className="col-md-2 col-12 align-self-start">
            <div className="row justify-content-between">
              <div className="col align-self-center">
                <JobStatus
                  job={job}
                  onCancelClick={onCancelClick}
                  onTimerFinish={onTimerFinish}
                  onProcessJobClick={onProcessJobClick}
                  isAdmin={isAdmin}
                />
              </div>
              {job.fileGuid && (
                <div className="col-auto align-self-end">
                  <AddFiles id={job.fileGuid} />
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <span className="pe-2 fw-bold">Configuration</span>
            {job.configName}
          </div>
        </div>
        {isAdmin && (
          <div className="row gx-3 align-items-start border-top pt-2">
            <div className="col-12 col-md-3">
              <div className="row">
                <div className="col-12 fw-bold">Manager</div>
                <div className="col-12 text-truncate">
                  {formatUserName(job.manager)}
                </div>
              </div>
            </div>
            <div className="col-12 col-md-3">
              <div className="row">
                <div className="col-12 fw-bold">Analyst</div>
                <div className="col-12 text-truncate">
                  {formatUserName(job.analyst)}
                </div>
              </div>
            </div>
            {job.summarize && (
              <div className="col-12 col-md-3">
                <div className="row">
                  <div className="col-12 fw-bold">Summarizer</div>
                  <div className="col-12 text-truncate">
                    {formatUserName(job.summarizer)}
                  </div>
                </div>
              </div>
            )}
            <div className="col-12 col-md-3 text-start text-md-end align-self-center">
              <div className="col-12">
                <PrimaryLink
                  href=""
                  onClick={() => {}}
                  title="Human In The Loop"
                >
                  <FontAwesomeIcon
                    icon={faPersonChalkboard}
                    className="ps-2"
                    size="xl"
                  />
                </PrimaryLink>
              </div>
            </div>
          </div>
        )}
        {job.jobFiles && job.jobFiles.length > 1 && (
          <div className="row gx-0 align-items-start collapse show" id={id}>
            <div className="border-bottom" />
            {job.jobFiles.map((file, idx) => (
              <div className="px-2 py-2" key={idx}>
                {file.original}{' '}
                {file.size ? '(' + bytesToSize(file.size) + ')' : ''}
              </div>
            ))}
            <div className="border-bottom" />
            <div className="col-12 pt-3 text-center">
              <PrimaryLink
                href=""
                onClick={e => {
                  e.preventDefault();

                  collapse.hide();

                  setIsOpen(prev => !prev);
                }}
              >
                <FontAwesomeIcon icon={faChevronUp} className="pe-2" />
                Uploaded files (collapse)
                <FontAwesomeIcon icon={faChevronUp} className="ps-2" />
              </PrimaryLink>
            </div>
          </div>
        )}
      </Shell>
    )
  );
};

JobItem.defaultProps = {
  onCancelClick: undefined,
  onTimerFinish: undefined,
  onProcessJobClick: undefined,
  isAdmin: false,
};

JobItem.propTypes = {
  job: jobPropType.isRequired,
  onCancelClick: func,
  onTimerFinish: func,
  onProcessJobClick: func,
  isAdmin: bool,
};

export default JobItem;
