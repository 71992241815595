import { Auth } from '@aws-amplify/auth';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { Mixpanel } from 'utils/mixpanel';

import Primary from 'components/_common/buttons/Primary';
import Logo from 'components/_common/Logo';

import logoutEvent from 'api/auth/logoutEvent';

const Logout = () => {
  const navigate = useNavigate();

  useEffect(() => {
    Auth.currentSession()
      .then(() => {
        Auth.currentAuthenticatedUser().then(() => {
          logoutEvent().catch();

          Auth.signOut();
        });
      })
      .catch(() => {
        console.log('');
      });

    Mixpanel.track('Logout');
  }, []);

  return (
    <div className="text-center">
      <div className="mb-4">
        <Logo />
      </div>
      <div className="fs-4 pb-4">
        You have been logged out. Please login again to continue working.
      </div>
      <div>
        <Primary type="button" onClick={() => navigate('/login')}>
          Login
        </Primary>
      </div>
    </div>
  );
};

export default Logout;
