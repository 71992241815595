import { faUpload } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { bool, func, string } from 'prop-types';

import PrimaryLink from 'components/_common/links/Primary';

const Uploader = ({ caption, title, onFileChange, accept, disabled }) => {
  const handleFileListChange = files => {
    onFileChange(files);
  };

  const handleFileClick = e => {
    e.preventDefault();

    var el = document.getElementById('uploadInput');
    el.value = '';
    el.click();
  };

  return (
    <div>
      <PrimaryLink
        onClick={handleFileClick}
        title={title}
        href=""
        disabled={disabled}
      >
        <FontAwesomeIcon icon={faUpload} className="pe-2" />
        {caption}
      </PrimaryLink>
      <input
        id="uploadInput"
        name="uploadInput[]"
        type="file"
        className="file d-none"
        accept={accept}
        data-show-upload="true"
        data-show-caption="true"
        onChange={e => handleFileListChange(e.target.files)}
      />
    </div>
  );
};

Uploader.defaultProps = {
  disabled: false,
};

Uploader.propTypes = {
  caption: string.isRequired,
  title: string.isRequired,
  onFileChange: func.isRequired,
  accept: string.isRequired,
  disabled: bool,
};

export default Uploader;
