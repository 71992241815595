import executeApiCall from 'utils/executeApiCall';

export default function (id, userId) {
  try {
    const endpoint = `/api/downloads/job/${id}/user/${userId}`;

    return executeApiCall(endpoint, {
      method: 'GET',
      responseType: 'blob',
    }).then(response => {
      return response.data;
    });
  } catch (error) {
    return Promise.reject(error);
  }
}
