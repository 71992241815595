import { node, string } from 'prop-types';

import Header1 from './Header1';
import Header2 from './Header2';

const Page = ({ title, subTitle, children }) => {
  return (
    <>
      <div className="pb-4">
        <Header1 text={title} />
        <Header2 text={subTitle} />
      </div>
      <div>{children}</div>
    </>
  );
};

Page.defaultProps = {
  children: '',
};

Page.propTypes = {
  title: string.isRequired,
  subTitle: string.isRequired,
  children: node,
};

export default Page;
