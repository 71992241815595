import { bool, func } from 'prop-types';

import jobPropType from 'propTypes/jobPropType';

import DownloadFile from './DownloadFile';
import Countdown from './Countdown';
import CancelJob from './CancelJob';
import ProcessJob from './ProcessJob';
import fileStatusEnum from 'components/_common/constants/fileStatusEnum';

const JobStatus = ({
  job,
  onCancelClick,
  onTimerFinish,
  onProcessJobClick,
}) => {
  const wrapper = status => {
    return (
      <div className="row">
        <div className="col-12 fw-bold">Status</div>
        <div className="col-12">{status}</div>
      </div>
    );
  };

  if (job.isCancelled) {
    return wrapper('Cancelled');
  }

  if (job.startProcess) {
    if (new Date(job.startProcess) - Date.parse(new Date()) < 1) {
      return wrapper('Processing');
    }
    return (
      <>
        {onCancelClick != undefined && (
          <CancelJob onCancelClick={onCancelClick} />
        )}
        <Countdown
          startProcess={job.startProcess}
          onTimerFinish={onTimerFinish}
        />
        {onProcessJobClick && (
          <ProcessJob href="" onProcessJobClick={onProcessJobClick}>
            Process Now
          </ProcessJob>
        )}
      </>
    );
  }

  if (
    job.statusId == fileStatusEnum.COMPLETE ||
    job.statusId == fileStatusEnum.DOWNLOAD
  ) {
    return <DownloadFile job={job} />;
  }

  if (job.isExpress) {
    return wrapper('ETA: Today');
  }

  return wrapper('In Progress');
};

JobStatus.defaultProps = {
  onCancelClick: undefined,
  onTimerFinish: undefined,
  onProcessJobClick: undefined,
};

JobStatus.propTypes = {
  job: jobPropType.isRequired,
  onCancelClick: func,
  onTimerFinish: func,
  onProcessJobClick: func,
  isAdmin: bool.isRequired,
};

export default JobStatus;
