/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-2",
    "aws_cognito_identity_pool_id": "us-east-2:007b0841-ebb2-4363-9cf8-3f35c745ed92",
    "aws_cognito_region": "us-east-2",
    "aws_user_pools_id": "us-east-2_DrFdbgok9",
    "aws_user_pools_web_client_id": "5nm3c3vop0iv0lv1blg3l0io0o",
    "oauth": {
        "domain": "auth.deepdoc.net.auth.us-east-2.amazoncognito.com"
    },
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "GIVEN_NAME",
        "FAMILY_NAME",
        "EMAIL",
        "PHONE_NUMBER"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": [
            "REQUIRES_LOWERCASE",
            "REQUIRES_UPPERCASE",
            "REQUIRES_NUMBERS",
            "REQUIRES_SYMBOLS"
        ]
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_user_files_s3_bucket": "file-lobby",
    "aws_user_files_s3_bucket_region": "us-east-2",
    "region": "us-east-2"
};


export default awsmobile;
