import { Route, Routes } from 'react-router-dom';

import SiteLayout from 'components/Layouts/SiteLayout';

import AuthParent from 'components/AuthParent';
import Login from 'components/AuthParent/Login';
import Reset from 'components/AuthParent/ResetPassword';
import ChangePassword from 'components/AuthParent/ChangePassword';
import Signup from 'components/Signup';
import Confirm from 'components/Signup/Confirm';

import LoggedInLayout from 'components/Layouts/LoggedInLayout';
import PagesLayout from 'components/Layouts/LoggedInLayout/Pages';
import Jobs from 'components/Jobs';
import Upload from 'components/Upload';

import ClientSettings from 'components/Settings';
import tabEnum from 'components/Settings/constants/tabEnum';

import AdminLayout from 'components/Layouts/Admin';
import Settings from 'components/Admin/Settings';
import Pipelines from 'components/Admin/Settings/Pipelines';
import Companies from 'components/Admin/Settings/Companies';
import Users from 'components/Admin/Settings/Users';
import EditUser from 'components/Admin/Settings/Users/Edit';

import Router from 'components/Router';

import NotFound from 'components/Errors/NotFound';
import NetworkError from 'components/Errors/NetworkError';

import EditSummary from 'components/Admin/Summary/Edit';
import Download from 'components/Download';
import JobReport from 'components/Reports/Jobs';

import Dashboard from 'components/Admin/Dashboard';
import Logout from 'components/AuthParent/Logout';
import Summaries from 'components/Admin/Summaries';

const AppRoutes = () => {
  return (
    <Routes>
      <Route element={<SiteLayout />}>
        <Route element={<AuthParent />}>
          <Route path="/" element={<Login />} />
          <Route path="/login" element={<Login />} />
          <Route path="/logout" element={<Logout />} />
          <Route path="/reset" element={<Reset />} />
          <Route path="/change" element={<ChangePassword />} />
          <Route path="/signup/confirm" element={<Confirm />} />
          <Route path="/signup" element={<Signup />} />
        </Route>
        <Route element={<LoggedInLayout />}>
          <Route element={<PagesLayout />}>
            <Route path="/jobs" element={<Jobs />} />
            <Route path="/settings" element={<ClientSettings />} />
            <Route
              path="/settings/configuration"
              element={<ClientSettings tab={tabEnum.CONFIGURATION} />}
            />
          </Route>
          <Route path="/upload/:id/clone" element={<Upload />} />
          <Route path="/upload" element={<Upload />} />
          <Route element={<AdminLayout />}>
            <Route path="/admin/summaries" element={<Summaries />} />
            <Route path="/admin/summary/:id" element={<EditSummary />} />
            <Route path="/admin/dashboard" element={<Dashboard />} />
            <Route element={<Settings />}>
              <Route path="/admin/settings/pipelines" element={<Pipelines />} />
              <Route path="/admin/settings/companies" element={<Companies />} />
              <Route path="/admin/settings/users" element={<Users />} />
              <Route
                path="/admin/settings/users/:id/edit"
                element={<EditUser />}
              />
              <Route
                path="/admin/settings/users/create/company/:companyid"
                element={<EditUser />}
              />
              <Route path="/reports/jobs" element={<JobReport />} />
            </Route>
          </Route>
        </Route>
        <Route path="/download" element={<Download />} />
        <Route path="/router/:id" element={<Router />} />
        <Route path="*" element={<NotFound />} />
        <Route path="/errors/404" element={<NotFound />} />
        <Route path="/errors/networkerror" element={<NetworkError />} />
      </Route>
    </Routes>
  );
};

export default AppRoutes;
