import {
  faChevronDown,
  faChevronUp,
  faTrashAlt,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { bool, shape } from 'prop-types';

import PrimaryLink from 'components/_common/links/Primary';
import {
  useConfigurationPackage,
  useConfigurationPackageUpdate,
} from 'components/Settings/Configuration/context/configurationPackageContext';
import {
  reorderSections,
  swapElements,
} from 'components/Settings/Configuration/utils';

import Title from './Title';

const Header = ({ section, readOnly }) => {
  const configurationPackage = useConfigurationPackage();
  const setConfigurationPackage = useConfigurationPackageUpdate();

  const isDownAvailable =
    section.position != configurationPackage.configuration.sections.length - 1;
  const isUpAvailable = section.position != 0;
  const sectionIndex = configurationPackage.configuration.sections.findIndex(
    item => {
      return item.id == section.id;
    }
  );
  const handleMoveDown = e => {
    e.preventDefault();

    if (readOnly) {
      return;
    }

    swapElements(
      configurationPackage.configuration.sections,
      sectionIndex,
      sectionIndex + 1
    );

    configurationPackage.configuration.sections = reorderSections(
      configurationPackage.configuration
    );

    setConfigurationPackage(existing => ({
      ...existing,
      ...configurationPackage.configuration,
    }));
  };

  const handleMoveUp = e => {
    e.preventDefault();

    if (readOnly) {
      return;
    }

    swapElements(
      configurationPackage.configuration.sections,
      sectionIndex,
      sectionIndex - 1
    );

    configurationPackage.configuration.sections = reorderSections(
      configurationPackage.configuration
    );

    setConfigurationPackage(existing => ({
      ...existing,
      ...configurationPackage.configuration,
    }));
  };

  const handleRemoveSection = e => {
    e.preventDefault();

    if (readOnly) {
      return;
    }

    configurationPackage.configuration.sections = [
      ...configurationPackage.configuration.sections.slice(0, sectionIndex),
      ...configurationPackage.configuration.sections.slice(
        sectionIndex + 1,
        configurationPackage.configuration.sections.length
      ),
    ];

    section.documents = reorderSections(configurationPackage.configuration);

    setConfigurationPackage(existing => ({
      ...existing,
      ...configurationPackage.configuration,
    }));
  };

  return (
    <div className="bg-light d-flex justify-content-between p-2">
      <Title section={section} readOnly={readOnly} />
      <div>
        <PrimaryLink
          className={`pe-2 ${readOnly ? 'disabled-link' : ''}`}
          href="#"
          title="Remove document"
          onClick={e => handleRemoveSection(e)}
        >
          <FontAwesomeIcon icon={faTrashAlt} />
        </PrimaryLink>
        <PrimaryLink
          className={`p-2 ${isUpAvailable && !readOnly ? '' : 'disabled-link'}`}
          href="#"
          title="Move up"
          onClick={isUpAvailable ? handleMoveUp : void 0}
        >
          <FontAwesomeIcon icon={faChevronUp} />
        </PrimaryLink>
        <PrimaryLink
          className={`p-2 ${
            isDownAvailable && !readOnly ? '' : 'disabled-link'
          }`}
          href="#"
          title="Move down"
          onClick={isDownAvailable ? handleMoveDown : void 0}
        >
          <FontAwesomeIcon icon={faChevronDown} />
        </PrimaryLink>
      </div>
    </div>
  );
};

Header.defaultProps = { readOnly: false };

Header.propTypes = {
  section: shape({}).isRequired,
  readOnly: bool,
};

export default Header;
