import { Auth } from '@aws-amplify/auth';
import { arrayOf, number } from 'prop-types';
import { useCallback, useState } from 'react';
import { faEdit, faExpand } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Modal } from 'bootstrap';
import ReactDataGrid from '@inovua/reactdatagrid-community';
import '@inovua/reactdatagrid-community/index.css';
import { Link } from 'react-router-dom';

import PrimaryLink from 'components/_common/links/Primary';

import fileStatusEnum from 'components/_common/constants/fileStatusEnum';

import { formatISODateTime } from 'utils/formatDateTme';

import fetchSummariesByStatuses from 'api/summaries/fetchSummariesByStatuses';

import FileView from '../FileView';
import NameBadge from './NameBadge';

const SummariesGrid = ({ currentSummaryStatuses, currentFileStatuses }) => {
  const [limit, setLimit] = useState(10);
  const [fileView, setFileView] = useState();

  const handleFileViewClick = (e, item) => {
    e.preventDefault();

    setFileView(item);

    const myModal = new Modal('#fileViewModal', {
      keyboard: true,
      backdrop: true,
    });

    myModal.show();
  };

  const gridStyle = {
    border: '0',
  };

  const columns = [
    {
      name: 'preparer',
      header: '',
      defaultWidth: 50,
      render: ({ value }) => <NameBadge name={value} />,
      hideable: false,
    },
    {
      name: 'pipeline',
      header: 'Pipeline',
      defaultWidth: 200,
      defaultFlex: 1,
      render: ({ value }) => (
        <div className="text-truncate" title={value}>
          {value}
        </div>
      ),
    },
    {
      name: 'fileGuid',
      header: 'File Guid',
      defaultWidth: 350,
      render: ({ value, data }) => (
        <>
          <PrimaryLink
            href="#"
            onClick={e => handleFileViewClick(e, data)}
            title="File View"
          >
            <FontAwesomeIcon icon={faExpand} className="pe-2" />
          </PrimaryLink>
          {value}
        </>
      ),
    },
    { name: 'jobName', header: 'Job Name', defaultWidth: 200, defaultFlex: 2 },
    {
      name: 'pageCount',
      header: '# of Pages',
      defaultWidth: 120,
      type: 'number',
      style: { textAlign: 'right' },
      headerAlign: 'end',
      render: ({ value }) => value.toLocaleString(),
    },
    {
      name: 'summaryStatus',
      header: 'Status',
      defaultWidth: 200,
      render: ({ value, data }) => (
        <>
          {data.fileStatusId !== fileStatusEnum.CLONED_HIDE && value}
          {data.fileStatusId === fileStatusEnum.CLONED_HIDE ? (
            <>
              <span className="text-decoration-line-through">{value}</span>
              <span className="ps-2 text-danger">Cloned</span>
            </>
          ) : (
            ''
          )}
        </>
      ),
    },
    {
      name: 'createdAt',
      header: 'Created At',
      maxWidth: 150,
      render: ({ value }) => formatISODateTime(value),
      style: { textAlign: 'right' },
      headerAlign: 'end',
    },
    {
      name: 'approvedAt',
      maxWidth: 150,
      header: 'Approved At',
      render: ({ value }) => formatISODateTime(value),
      style: { textAlign: 'right' },
      headerAlign: 'end',
    },
    {
      name: 'id',
      header: '',
      defaultWidth: 50,
      resizable: false,
      style: { textAlign: 'center' },
      sortable: false,
      hideable: false,
      enableColumnFilterContextMenu: false,
      showColumnMenuTool: false,
      render: ({ data }) => (
        <Link to={`/admin/summary/${data.fileId}`} title="View Summary">
          <FontAwesomeIcon icon={faEdit} />
        </Link>
      ),
    },
  ];

  const loadData = ({ skip, limit, sortInfo }) => {
    return Auth.currentUserInfo()
      .then(() => {
        var sort;
        if (sortInfo) {
          sort = `${sortInfo.columnName} ${
            sortInfo.dir === 1 ? 'asc' : 'desc'
          }`;
        } else {
          sort = 'createdAt desc';
        }

        return fetchSummariesByStatuses(
          currentSummaryStatuses,
          currentFileStatuses,
          limit,
          skip,
          sort
        ).then(response => {
          return {
            data: response.data,
            count: response.count,
          };
        });
      })
      .catch(() => {
        return {
          data: [],
          count: 0,
        };
      });
  };

  const dataSource = useCallback(loadData, [
    currentSummaryStatuses,
    currentFileStatuses,
  ]);

  return (
    <>
      <ReactDataGrid
        idProperty="id"
        columns={columns}
        dataSource={dataSource}
        style={{
          minHeight: limit > 20 ? 880 : (limit + 2) * 40 + 4,
          border: 0,
        }}
        pagination
        limit={limit}
        onLimitChange={setLimit}
        showCellBorders="horizontal"
        showColumnMenuLockOptions={false}
        enableColumnAutosize={false}
        defaultLimit={10}
        pageSizes={[10, 20, 50, 100]}
      />
      <FileView file={fileView} />
    </>
  );
};

SummariesGrid.defaultProps = {
  currentSummaryStatuses: [],
  currentFileStatuses: [],
};

SummariesGrid.propTypes = {
  currentSummaryStatuses: arrayOf(number),
  currentFileStatuses: arrayOf(number),
};

export default SummariesGrid;
