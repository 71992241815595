import { bool, node } from 'prop-types';

import '../style.css';
import './style.css';

const PrimaryLink = ({ children, disabled, ...rest }) => {
  return (
    <a
      className={`link link-primary ${disabled ? 'disabled-link' : ''}`}
      {...rest}
    >
      {children}
    </a>
  );
};

PrimaryLink.defaultProps = {
  children: null,
  disabled: false,
};

PrimaryLink.propTypes = {
  children: node,
  disabled: bool,
};

export default PrimaryLink;
