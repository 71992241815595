import { Suspense, useEffect, useState } from 'react';
import { Outlet } from 'react-router-dom';

const SiteLayout = () => {
  const queryParams = new URLSearchParams(window.location.search);
  const action = queryParams.get('action');

  const [message, setMessage] = useState();

  useEffect(() => {
    switch (action) {
      case 'confirmed':
        setMessage('Account has been confirmed. Please login.');
        break;
      case 'emailsent':
        setMessage('An email has been sent to your account.');
        break;
      default:
        break;
    }
  }, []);

  return (
    <>
      {message && (
        <div
          className={
            'alert alert-primary alert-dismissible fade show fixed-top'
          }
          role="alert"
        >
          <div className="fw-bold text-center">{message}</div>
          <button
            type="button"
            className="btn-close"
            data-bs-dismiss="alert"
            aria-label="Close"
          />
        </div>
      )}
      <Suspense>
        <Outlet />
      </Suspense>
    </>
  );
};

export default SiteLayout;
