import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';

import White from 'components/_common/buttons/White';

const CompanyHeader = () => {
  const handleAddCompany = () => {};

  return (
    <div className="d-flex justify-space-between align-items-center">
      <div>Companies</div>
      <div className="ms-auto">
        <White onClick={handleAddCompany} disabled>
          <FontAwesomeIcon icon={faPlus} className="pe-2" />
          Add Company
        </White>
      </div>
    </div>
  );
};

export default CompanyHeader;
