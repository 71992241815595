import { string } from 'prop-types';

const ProfileItem = ({ label, text }) => {
  return (
    <div className="row gx-0 gy-1 align-items-start">
      <div className="col-5 my-1 p-3 bg-white text-truncate">{label}</div>
      <div className="col-7 my-1 p-3 bg-white fw-bold text-truncate">
        {text}
      </div>
    </div>
  );
};

ProfileItem.propTypes = {
  label: string.isRequired,
  text: string.isRequired,
};

export default ProfileItem;
