import executeApiCall from 'utils/executeApiCall';

export default function (bucketName, key, daysExpires, saveAsName) {
  try {
    let endpoint = `/api/downloads/url?bucketName=${bucketName}&key=${key}&daysExpires=${daysExpires}`;

    if (saveAsName) {
      endpoint += `&saveAsName=${saveAsName}`;
    }

    return executeApiCall(endpoint, {
      method: 'GET',
    }).then(response => {
      return response.data;
    });
  } catch (error) {
    return Promise.reject(error);
  }
}
