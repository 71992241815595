import { useState } from 'react';
import { func } from 'prop-types';
import { uniqueId } from 'lodash';
import { Popover } from 'bootstrap/dist/js/bootstrap';

import PrimaryLink from 'components/_common/links/Primary';
import SecondaryLink from 'components/_common/links/Secondary';
import DangerLink from 'components/_common/links/Danger';

import useEventListener from 'hooks/useEventListener';

const CancelJob = ({ onCancelClick }) => {
  const [id] = useState(uniqueId());
  const [popover, setPopover] = useState();

  useEventListener(
    'click',
    function (e) {
      e.preventDefault();
      popover.hide();
    },
    document.getElementById('no' + id)
  );

  useEventListener(
    'click',
    function (e) {
      e.preventDefault();
      popover.hide();
      onCancelClick();
    },
    document.getElementById('yes' + id)
  );

  function showPopover(e, popupId) {
    e.preventDefault();

    if (popover != null) {
      //popover.show();
    } else {
      var pop = document.getElementById('popover' + popupId);
      var temp = new Popover(pop, {
        sanitize: false,
        container: 'body',
        html: true,
        trigger: 'focus',
        placement: 'bottom',
        content: document.getElementById('popover-content' + popupId),
      });

      setPopover(temp);
      temp.show();
    }
  }

  return (
    <>
      <DangerLink href="" onClick={e => showPopover(e, id)} id={'popover' + id}>
        Cancel
      </DangerLink>
      <div hidden>
        <div id={'popover-content' + id}>
          <div className="row">
            <div className="col-12">
              Do you want to stop this job from being initiated?
            </div>
            <div className="row justify-content-center">
              <div className="col-10 border-bottom my-2" />
            </div>
            <div className="row gy-1">
              <div className="col-12 fs-6 fw-bold">
                <PrimaryLink href="" id={'yes' + id}>
                  Yes
                </PrimaryLink>
              </div>
              <div className="col-12 fs-6 fw-bold">
                <SecondaryLink href="" id={'no' + id}>
                  No
                </SecondaryLink>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

CancelJob.propTypes = { onCancelClick: func.isRequired };

export default CancelJob;
