import { number, shape, string } from 'prop-types';

export default shape({
  id: number,
  summaryGuid: string,
  fileId: number,
  createdAt: string,
  status: number,
  preparedBy: number,
  approvedBy: number,
  approvedAt: string,
});
