import { node } from 'prop-types';

const Shell = ({ children }) => {
  return (
    <div className="bg-white p-3 rounded text-muted shadow">{children}</div>
  );
};

Shell.propTypes = { children: node.isRequired };

export default Shell;
