import { Link, useNavigate } from 'react-router-dom';

import ConsumerMenu from './Consumer';
import AdminMenu from './Admin';
import ReportsMenu from './Reports';

const Navigation = () => {
  const navigate = useNavigate();

  return (
    <div
      style={{ top: '1rem', right: '1rem', zIndex: 1046, position: 'absolute' }}
    >
      <nav className="navbar navbar-expand-md navbar-light bg-white">
        <div className="container-fluid">
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarNav"
            aria-controls="navbarNav"
            aria-expanded="false"
          >
            <span className="navbar-toggler-icon" />
          </button>
          <div className="collapse navbar-collapse" id="navbarNav">
            <ul className="navbar-nav fw-bold text-darker">
              <ConsumerMenu />
              <ReportsMenu />
              <AdminMenu />
              <li className="nav-item">
                <Link
                  to=""
                  className="nav-link text-nowrap"
                  onClick={e => {
                    e.preventDefault();
                    navigate('/logout');
                  }}
                >
                  Logout
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </div>
  );
};

export default Navigation;
