import {
  faAngleDoubleDown,
  faAngleDoubleRight,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const Arrows = () => {
  return (
    <div className="align-self-center text-muted">
      <FontAwesomeIcon
        icon={faAngleDoubleRight}
        size="7x"
        className="opacity-25 d-none d-md-block"
      />
      <FontAwesomeIcon
        icon={faAngleDoubleDown}
        size="3x"
        className="opacity-25 d-md-none py-3"
      />
    </div>
  );
};

export default Arrows;
