import { oneOf } from 'prop-types';
import { useState } from 'react';

import tabEnum from './constants/tabEnum';

import Configuration from './Configuration';
import Profile from './Profile';

const Settings = ({ tab }) => {
  const [currentTab, setCurrentTab] = useState(tab ?? tabEnum.PROFILE);

  const handleTabClick = (e, tab) => {
    e.preventDefault();

    setCurrentTab(tab);
  };

  return (
    <>
      <ul className="nav nav-tabs">
        <li className="nav-item">
          <a
            className={`nav-link ${
              currentTab == tabEnum.PROFILE ? 'active' : ''
            }`}
            aria-current="page"
            href="#"
            onClick={e => handleTabClick(e, tabEnum.PROFILE)}
          >
            Profile
          </a>
        </li>
        <li className="nav-item">
          <a
            className={`nav-link ${
              currentTab == tabEnum.CONFIGURATION ? 'active' : ''
            }`}
            aria-current="page"
            href="#"
            onClick={e => handleTabClick(e, tabEnum.CONFIGURATION)}
          >
            Configuration
          </a>
        </li>
        <li className="nav-item d-none">
          <a
            className={`nav-link ${
              currentTab == tabEnum.BILLING ? 'active' : ''
            } disabled`}
            href="#"
            tabIndex="-1"
            aria-disabled="true"
            onClick={e => handleTabClick(e, tabEnum.BILLING)}
          >
            Billing
          </a>
        </li>
        <li className="nav-item d-none">
          <a
            className={`nav-link ${
              currentTab == tabEnum.LICENSING ? 'active' : ''
            } disabled`}
            href="#"
            tabIndex="-1"
            aria-disabled="true"
            onClick={e => handleTabClick(e, tabEnum.LICENSING)}
          >
            Licensing
          </a>
        </li>
      </ul>
      <div className="bg-white p-3 border border-top-0">
        {currentTab == tabEnum.PROFILE && <Profile />}
        {currentTab == tabEnum.CONFIGURATION && <Configuration />}
      </div>
    </>
  );
};

Settings.defaultProps = { tab: undefined };

Settings.propTypes = {
  tab: oneOf(Object.values(tabEnum)),
};

export default Settings;
