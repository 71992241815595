import { Link } from 'react-router-dom';

const Options = () => {
  return (
    <li className="nav-item">
      <Link className="nav-link text-nowrap" to="/settings">
        Settings
      </Link>
    </li>
  );
};

export default Options;
