import { string } from 'prop-types';

const Header1 = ({ text }) => {
  return <h2 className="display-5">{text}</h2>;
};

Header1.propTypes = {
  text: string.isRequired,
};

export default Header1;
