import { Popover } from 'bootstrap';
import { uniqueId } from 'lodash';
import { func } from 'prop-types';
import { useEffect, useState } from 'react';

import PrimaryLink from 'components/_common/links/Primary';
import SecondaryLink from 'components/_common/links/Secondary';

import useEventListener from 'hooks/useEventListener';

const ProcessJob = ({ onProcessJobClick }) => {
  const [id] = useState(uniqueId());
  const [popover, setPopover] = useState();

  useEventListener(
    'click',
    function (e) {
      e.preventDefault();
      popover.hide();
    },
    document.getElementById('no' + id)
  );

  useEventListener(
    'click',
    function (e) {
      e.preventDefault();
      popover.hide();
      onProcessJobClick();
    },
    document.getElementById('yes' + id)
  );

  useEffect(() => {
    var pop = document.getElementById('popover' + id);
    var temp = new Popover(pop, {
      sanitize: false,
      container: 'body',
      html: true,
      trigger: 'focus',
      placement: 'bottom',
      content: document.getElementById('popover-content' + id),
    });

    setPopover(temp);
  }, []);

  return (
    <>
      <PrimaryLink
        href=""
        onClick={e => e.preventDefault()}
        id={'popover' + id}
        data-bs-toggle="popover"
        data-bs-trigger="focus"
        role="button"
      >
        Process
      </PrimaryLink>
      <div hidden>
        <div id={'popover-content' + id}>
          <div className="row">
            <div className="col-12">
              Do you want to process this job right now?
            </div>
            <div className="row justify-content-center">
              <div className="col-10 border-bottom my-2" />
            </div>
            <div className="row gy-1">
              <div className="col-12 fs-6 fw-bold">
                <PrimaryLink href="" id={'yes' + id}>
                  Yes
                </PrimaryLink>
              </div>
              <div className="col-12 fs-6 fw-bold">
                <SecondaryLink href="" id={'no' + id}>
                  No
                </SecondaryLink>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

ProcessJob.propTypes = {
  onProcessJobClick: func.isRequired,
};

export default ProcessJob;
