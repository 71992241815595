import { Auth } from '@aws-amplify/auth';
import { Outlet } from 'react-router-dom';

import userAttributes from 'utils/userAttributes';

const Settings = () => {
  Auth.currentAuthenticatedUser().then(user => {
    const userCreds = userAttributes(user);
    if (userCreds.isAdmin == 1) {
      return <Outlet />;
    }

    return '';
  });

  return <Outlet />;
};

export default Settings;
