import { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight, faPlus } from '@fortawesome/free-solid-svg-icons';
import { Auth } from 'aws-amplify';

import fetchUser from 'api/users/fetchUser';
import fetchPipelineByCompanyId from 'api/pipelines/fetchPipelineByCompanyId';
import fetchConfigurationsByPipelineId from 'api/configuration/fetchConfigurationsByPipelineId';

import PrimaryLink from 'components/_common/links/Primary';

import { ConfigurationPackageProvider } from './context/configurationPackageContext';

import Tab from '../_common/tab';

import Content from './Content';

import { emptyConfiguration } from './utils';

const Configurator = () => {
  const [configurations, setConfigurations] = useState([]);
  const [currentConfigurationId, setCurrentConfigurationId] = useState(null);
  const [pipelineId, setPipelineId] = useState();

  const handleTabClick = (e, configId) => {
    e.preventDefault();

    setCurrentConfigurationId(configId);
  };

  const refreshConfigsWithSelected = async configId => {
    const configs = await loadConfigs(pipelineId);

    if (configId == undefined || configId == null) {
      setCurrentConfigurationId(configs[0].id);
    } else {
      setCurrentConfigurationId(configId);
    }
  };

  const loadConfigs = async pipelineId => {
    return await fetchConfigurationsByPipelineId(pipelineId).then(configs => {
      configs.sort((a, b) => {
        if (a.name > b.name) return 1;
        if (a.name < b.name) return -1;
        return 0;
      });

      setConfigurations(configs);

      return configs;
    });
  };

  useEffect(() => {
    Auth.currentAuthenticatedUser().then(authUser => {
      fetchUser(authUser.username).then(user => {
        fetchPipelineByCompanyId(user.companyId).then(async pipeline => {
          setPipelineId(pipeline.id);

          const configs = await loadConfigs(pipeline.id);

          if (!currentConfigurationId) {
            setCurrentConfigurationId(configs[0].id);
          }
        });
      });
    });
  }, []);

  const handleAddConfiguration = e => {
    e.preventDefault();

    const newConfig = emptyConfiguration();
    newConfig.id = 0;
    newConfig.name = 'New configuration';

    configurations.push(newConfig);

    setCurrentConfigurationId(0);
  };

  const canAddConfiguration = () => {
    return configurations.find(config => config.id == 0) != null;
  };

  document.title = 'Configuration | DeepDoc by AAICO';

  return (
    configurations &&
    currentConfigurationId != null && (
      <ConfigurationPackageProvider>
        <Tab>
          <div className="row">
            <div className="col-2 g-0">
              <div className="border-bottom py-3">
                <PrimaryLink
                  href="#"
                  onClick={handleAddConfiguration}
                  disabled={canAddConfiguration()}
                >
                  <FontAwesomeIcon icon={faPlus} className="pe-2" />
                  Add Configuration
                </PrimaryLink>
              </div>
              <nav className="nav flex-column">
                {configurations.map(configuration => (
                  <li
                    key={configuration.id}
                    className={`d-flex justify-content-between align-items-center px-2 py-3 ${
                      currentConfigurationId === configuration.id
                        ? 'bg-light'
                        : ''
                    }`}
                  >
                    {currentConfigurationId === configuration.id && (
                      <>
                        <div>
                          <span className="fw-bold text-truncate pe-3">
                            {configuration.name}
                          </span>
                          <span
                            className={`badge bg-dark ${
                              !configuration.isDefault ? 'd-none' : ''
                            }`}
                          >
                            default
                          </span>
                        </div>
                        <FontAwesomeIcon
                          icon={faChevronRight}
                          className="opacity-25"
                        />
                      </>
                    )}
                    {currentConfigurationId !== configuration.id && (
                      <div className="fw-bold text-truncate pe-3">
                        <PrimaryLink
                          href="#"
                          onClick={e => handleTabClick(e, configuration.id)}
                          disabled={canAddConfiguration()}
                        >
                          <span className="fw-bold text-truncate pe-3">
                            {configuration.name}
                          </span>
                          <span
                            className={`badge bg-dark ${
                              !configuration.isDefault ? 'd-none' : ''
                            }`}
                          >
                            default
                          </span>
                        </PrimaryLink>
                      </div>
                    )}
                  </li>
                ))}
              </nav>
            </div>
            <div className="col-10 border-start ps-4">
              <Content
                pipelineId={pipelineId}
                configId={currentConfigurationId}
                updateFunc={refreshConfigsWithSelected}
              />
            </div>
          </div>
        </Tab>
      </ConfigurationPackageProvider>
    )
  );
};

export default Configurator;
