import mixpanel from 'mixpanel-browser';

mixpanel.init('70c04a1e7e5bf2c90065462ceff30791', {
  cross_subdomain_cookie: true,
  ip: true,
});

// eslint-disable-next-line no-undef
let env_check = process.env.NODE_ENV === 'production';
//let env_check = process.env.NODE_ENV === 'development';

let actions = {
  identify: id => {
    if (env_check) {
      mixpanel.identify(id);
    }
  },
  alias: id => {
    if (env_check) {
      mixpanel.alias(id);
    }
  },
  track: (name, props) => {
    if (env_check) {
      mixpanel.track(name, props);
    }
  },
  people: {
    set: props => {
      if (env_check) {
        mixpanel.people.set(props);
      }
    },
  },
};

export let Mixpanel = actions;
