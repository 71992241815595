import { Amplify } from 'aws-amplify';
import { Authenticator } from '@aws-amplify/ui-react';
import { ToastContainer } from 'react-toastify';

import ErrorBoundry from 'components/Errors';
import AppRoutes from 'components/AppRoutes';

import awsconfig from './aws-exports';

import './i18n';

import './main.css';
import './app.css';

import SiteTimeout from 'components/SiteTimeout';
import { CurrentUserProvider } from 'contexts/currentUserContext';

Amplify.configure({
  ...awsconfig,
  cookieStorage: {
    // eslint-disable-next-line no-undef
    domain: process.env.REACT_APP_COOKIE_STORAGE_DOMAIN,
    path: '/',
    expires: 365,
    sameSite: 'strict',
    secure: true,
  },
});

const App = () => {
  return (
    <ErrorBoundry fallback="Error">
      <Authenticator.Provider>
        <CurrentUserProvider>
          <SiteTimeout />
          <AppRoutes />
          <ToastContainer
            position="bottom-right"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick={false}
            rtl={false}
            pauseOnFocusLoss
            draggable={false}
            pauseOnHover
            theme="light"
          />
        </CurrentUserProvider>
      </Authenticator.Provider>
    </ErrorBoundry>
  );
};

export default App;
