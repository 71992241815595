import { bool } from 'prop-types';

import {
  useConfigurationPackage,
  useConfigurationPackageUpdate,
} from 'components/Settings/Configuration/context/configurationPackageContext';
import {
  emptySection,
  reorderSections,
} from 'components/Settings/Configuration/utils';

import PrimaryLink from 'components/_common/links/Primary';

const AddSection = ({ readOnly }) => {
  const configurationPackage = useConfigurationPackage();
  const setConfigurationPackage = useConfigurationPackageUpdate();

  const handleAddSectionClick = e => {
    e.preventDefault();

    if (readOnly) {
      return;
    }

    let section = emptySection();
    section.id =
      configurationPackage.sections == undefined
        ? 1
        : Math.max(
            ...configurationPackage.sections.map(section => section.id)
          ) + 1;
    section.configId = configurationPackage.configuration.id;
    section.name = 'New Section';
    section.bookmarkType = true;

    if (configurationPackage.configuration.sections == undefined) {
      configurationPackage.configuration.sections = [];
    }
    configurationPackage.configuration.sections.push(section);
    reorderSections(configurationPackage.configuration);

    setConfigurationPackage(existing => ({
      ...existing,
      ...configurationPackage.configuration,
    }));

    const element = document.getElementById('section');
    element.scrollIntoView();
  };

  return (
    <PrimaryLink href="#" onClick={handleAddSectionClick} disabled={readOnly}>
      Add Section
    </PrimaryLink>
  );
};

AddSection.defaultProps = { readOnly: false };

AddSection.propTypes = { readOnly: bool };

export default AddSection;
