import executeApiCall from 'utils/executeApiCall';

export default function (configId) {
  try {
    const endpoint = `/api/configuration/${configId}`;

    return executeApiCall(endpoint, {
      method: 'DELETE',
    }).then(response => {
      return response.data;
    });
  } catch (error) {
    return Promise.reject(error);
  }
}
