import { useNavigate, useParams } from 'react-router-dom';
import { useEffect } from 'react';

import fetchRoute from 'api/router/fetchRoute';

import Loader from 'components/_common/Loader';

const Router = () => {
  const { id } = useParams();
  var navigate = useNavigate();

  document.title = 'Redirect | DeepDoc by AAICO';

  useEffect(() => {
    fetchRoute(id)
      .then(response => {
        window.location.href = response;
      })
      .catch(() => {
        navigate('/login');
      });
  }, []);

  return <Loader />;
};

export default Router;
