import { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { Auth } from '@aws-amplify/auth';

import AlertMessage from 'components/_common/alertMessage';
import MissingParams from 'components/Errors/MissingParams';
import ConfirmForm from './Form';

const Confirm = () => {
  const navigate = useNavigate();
  let [searchParams] = useSearchParams();

  const [loading, setLoading] = useState(true);
  const [missingParams, setMissingParams] = useState(false);

  const [alertType, setAlertType] = useState();
  const [alertMessage, setAlertMessage] = useState();

  const username = !searchParams.get('user_name')
    ? ''
    : decodeURIComponent(searchParams.get('user_name'));

  const action = searchParams.get('action');

  useEffect(() => {
    document.title = 'Confirm';
    if (!username && !action) {
      setMissingParams(true);
    }
    if (action == 'confirmuser') {
      setAlertType('info');
      setAlertMessage('Please confirm your account.');
    }
  }, []);

  async function resendConfirmationCode(event, username, errors) {
    event.preventDefault();
    try {
      if (
        username.length == 0 ||
        Object.prototype.hasOwnProperty.call(errors, 'username')
      ) {
        setAlertType('error');
        setAlertMessage(!errors.username ? 'Enter Email' : errors.username);
      } else {
        await Auth.resendSignUp(username);
        setAlertType('success');
        setAlertMessage('A new code has been sent. Please check your email.');
      }
    } catch (err) {
      setAlertType('error');
      setAlertMessage(err.message);
    }
  }

  const confirmSignUp = async ({ username, code }) => {
    try {
      await Auth.confirmSignUp(username, code)
        .then(() => {
          setLoading(false);
        })
        .catch(error => {
          setAlertType('error');
          setAlertMessage(error.message);
        });
    } catch (error) {
      setAlertType('error');
      setAlertMessage(error.message);
    }
  };

  function handleOnClick(e) {
    e.preventDefault();
    navigate('/upload');
  }

  return missingParams ? (
    <MissingParams />
  ) : (
    <>
      {alertType && alertMessage && (
        <AlertMessage
          type={alertType}
          message={alertMessage}
          showClose={false}
          dismissSeconds={10}
        />
      )}
      {loading && (
        <ConfirmForm
          username={username}
          reSendCode={resendConfirmationCode}
          confirmSignUp={confirmSignUp}
        />
      )}
      {!loading && (
        <h3 className="text-black">
          Thank you for confirming.
          <a href="#" onClick={handleOnClick} className="d-block mt-2">
            Login
          </a>
        </h3>
      )}
    </>
  );
};

export default Confirm;
