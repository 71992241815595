import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { Auth } from '@aws-amplify/auth';

import PrimaryButton from 'components/_common/buttons/Primary';
import Logo from 'components/_common/Logo';

import { Mixpanel } from 'utils/mixpanel';

const componentName = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const emailInputRef = useRef();

  document.title = 'Reset Password | DeepDoc by AAICO';

  const formSubmitHandler = async event => {
    event.preventDefault();
    const username = emailInputRef.current.value;

    Auth.forgotPassword(username)
      .then(() => {
        Mixpanel.track('Forgot password request', { username: username });

        navigate('/change', {
          state: {
            username,
            message:
              'If your email exists in our system, a verification code will be sent to your email.',
          },
        });
      })
      .catch(err => {
        toast.error(err.message, { autoClose: 10000 });
      });
  };

  return (
    <form
      className="needs-validation"
      noValidate=""
      autoComplete="off"
      onSubmit={formSubmitHandler}
    >
      <div className="text-center mb-4 d-flex justify-content-center">
        <div className="align-self-center">
          <Link to="/login" className="pe-3">
            <FontAwesomeIcon icon={faArrowLeft} size="xl" />
          </Link>
          <Logo />
        </div>
      </div>
      <div className="mb-3 col text-center fs-6">
        {t('Common_Reset_Password_Prompt')}
      </div>
      <div className="row mb-2">
        <div className="input-group mb-3">
          <input
            ref={emailInputRef}
            type="email"
            className="form-control form-control-lg"
            placeholder="Email"
            aria-label="Email"
            required
          />
        </div>
        <div className="invalid-feedback">Email is invalid</div>
      </div>
      <div className="text-center">
        <PrimaryButton type="submit">
          {t('Common_Send_Code_Prompt')}
        </PrimaryButton>
      </div>
    </form>
  );
};

export default componentName;
