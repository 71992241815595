import { useEffect, useState } from 'react';

import fetchCompanies from 'api/companies/fetchCompanies';

import Page2 from 'components/_common/Page2';

import { formatISODateTime } from 'utils/formatDateTme';
import CompanyHeader from './Header';

const Companies = () => {
  const [companies, setCompanies] = useState();

  document.title = 'Companies | DeepDoc by AAICO';

  useEffect(() => {
    fetchCompanies().then(response => {
      setCompanies(response);
    });
  }, []);

  return (
    <>
      {companies && (
        <Page2 header={<CompanyHeader />}>
          <table className="table">
            <thead>
              <tr>
                <th scope="col">Id</th>
                <th scope="col">Name</th>
                <th scope="col">Address</th>
                <th scope="col">City</th>
                <th scope="col" className="text-center">
                  State
                </th>
                <th scope="col">Country</th>
                <th scope="col">Zip</th>
                <th scope="col">Phone</th>
                <th scope="col">Status</th>
                <th scope="col">Date Active</th>
              </tr>
            </thead>
            <tbody>
              {companies.map((p, idx) => (
                <tr key={idx}>
                  <td>{p.id}</td>
                  <td>{p.name}</td>
                  <td>
                    {p.addressLine1}
                    {p.addressLine2 && '<br />' + p.addressLine2}
                  </td>
                  <td>{p.city}</td>
                  <td className="text-center">{p.state}</td>
                  <td>{p.country}</td>
                  <td>{p.postalCode}</td>
                  <td>{p.phone}</td>
                  <td>{p.status}</td>
                  <td>{formatISODateTime(p.activeDate)}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </Page2>
      )}
    </>
  );
};

export default Companies;
