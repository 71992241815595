import { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownload } from '@fortawesome/free-solid-svg-icons';

import downloadJobByFileGuid from 'api/downloads/downloadJobByFileGuid';

import Loader from 'components/_common/Loader';
import White from 'components/_common/buttons/White';
import Logo from 'components/_common/Logo';

const Download = () => {
  const navigate = useNavigate();

  const [searchParams] = useSearchParams();
  const [downloading, setDownloading] = useState(true);

  const [message, setMessage] = useState();

  document.title = 'Downloader';

  useEffect(() => {
    const fileId = searchParams.get('fileId');
    const userId = searchParams.get('userId');
    const filename = searchParams.get('filename');
    const type = searchParams.get('type');

    if (fileId && userId && filename && type) {
      if (type.toLowerCase() == 'organize') {
        downloadJob(fileId, userId, filename);
      }
    } else {
      setMessage('Invalid Parameters provided');
      setDownloading(false);
    }
  }, []);

  function downloadJob(fileId, userId, filename) {
    downloadJobByFileGuid(fileId, userId)
      .then(response => {
        var blob = new Blob([response]);
        var url = window.URL || window.webkitURL;
        var link = url.createObjectURL(blob);

        var a = document.createElement('a');
        a.setAttribute('download', filename);

        a.setAttribute('href', link);
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);

        setMessage('File has been downloaded');
        setDownloading(false);
      })
      .catch(() => {
        setMessage('There was a problem downloading the file');
        setDownloading(false);
      });
  }

  const handleLogin = () => {
    navigate('/login');
  };

  return (
    <>
      {downloading && <Loader label="Downloading..." />}
      {!downloading && (
        <div className="d-flex justify-content-center align-items-center h-100 text-white">
          <div className="d-flex flex-column text-center border p-5">
            <div>
              <Logo dark={false} />
            </div>
            <div className="pt-5">
              <FontAwesomeIcon icon={faDownload} size="7x" />
            </div>
            <div className="fs-2 pt-5 fw-bold">Website Downloader</div>
            <div className="fs-5 pt-3">{message}</div>
            <div className="pt-5">
              <White type="button" onClick={handleLogin}>
                Login
              </White>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Download;
