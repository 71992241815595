import { Dropdown } from 'bootstrap';
import { uniqueId } from 'lodash';
import { bool, number } from 'prop-types';
import { useEffect, useRef, useState } from 'react';

import {
  useConfigurationPackage,
  useConfigurationPackageUpdate,
} from 'components/Settings/Configuration/context/configurationPackageContext';
import useClickOutside from 'hooks/useClickOutside';
import { uniqueDocumentTypes } from 'components/Settings/Configuration/utils';

const AddDocument = ({ sectionId, readOnly }) => {
  const [id] = useState(uniqueId());
  const [dropdown, setDropdown] = useState();
  const dropdownRef = useRef();
  const configurationPackage = useConfigurationPackage();
  const setConfigurationPackage = useConfigurationPackageUpdate();

  useEffect(() => {
    var dd = document.getElementById('dropdown' + id);
    var temp = new Dropdown(dd, {
      autoClose: 'outside',
      reference: 'parent',
    });

    setDropdown(temp);
  }, []);

  useEffect(() => {}, [configurationPackage.configuration.sections]);

  useClickOutside(dropdownRef, () => {
    if (dropdown) {
      dropdown.hide();
    }
  });

  function showDropdown(e) {
    e.preventDefault();

    if (readOnly) {
      return;
    }

    dropdown.toggle();
  }

  const handleAddDocument = (e, sectionId, documentTypeId) => {
    e.preventDefault();

    if (readOnly) {
      return;
    }

    let section = configurationPackage.configuration.sections.find(
      item => item.id == sectionId
    );
    if (section) {
      var pos =
        section.documents.length > 0
          ? Math.max(...section.documents.map(doc => doc.position)) + 1
          : 0;
      section.documents.push({
        configSectionId: sectionId,
        documentTypeId: documentTypeId,
        position: pos,
      });
    }

    setConfigurationPackage(existing => ({
      ...existing,
      ...configurationPackage.configuration,
    }));

    dropdown.hide();
  };

  return (
    <div className="dropdown" ref={dropdownRef}>
      <button
        className="btn btn-sm dropdown-toggle"
        type="button"
        data-bs-toggle="dropdown"
        aria-expanded="false"
        onClick={e => showDropdown(e, id)}
      >
        Add document
      </button>
      <ul id={'dropdown' + id} className="dropdown-menu">
        {uniqueDocumentTypes(configurationPackage).map(type => (
          <li key={type.id}>
            <a
              className="dropdown-item"
              href="#"
              onClick={e => handleAddDocument(e, sectionId, type.id)}
            >
              {type.name.replace('_', '')}
            </a>
          </li>
        ))}
      </ul>
    </div>
  );
};

AddDocument.defaultProps = {
  readOnly: false,
};

AddDocument.propTypes = {
  sectionId: number.isRequired,
  readOnly: bool,
};

export default AddDocument;
