import executeApiCall from 'utils/executeApiCall';

export default function (pipelineId) {
  try {
    const endpoint = `/api/configuration/pipeline/${pipelineId}/default`;

    return executeApiCall(endpoint, {
      method: 'GET',
    }).then(response => {
      return response.data;
    });
  } catch (error) {
    return Promise.reject(error);
  }
}
