import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

const resources = {
  en: {
    translation: {
      Common_Username: 'Username',
      Common_Password: 'Password',
      Common_New_Password: 'New Password',
      Common_Remember_Me: 'Remember me',
      Commom_Forgot_Password: 'Forgot password?',
      Common_Sign_In: 'Sign in',
      Common_Show_Password: 'Show Password',
      Common_Hide_Password: 'Hide Password',
      Common_Login_Prompt: 'Log in',
      Common_Add_User_Prompt: 'Add User',
      Common_Edit_User_Prompt: 'Edit User',
      Common_Reset_Password_Prompt: 'Reset your password',
      Common_Change_Password_Prompt: 'Change your password',
      Common_Send_Code_Prompt: 'Send Code',
      Common_Submit_Prompt: 'Submit',
      Common_Next_Prompt: 'Next',
      Common_Go_Back_Prompt: 'Back',
      Common_Transfer_Prompt: 'Transfer',
      Common_Stop_Prompt: 'Stop',
      Common_Change_Password: 'Change Password',
      Common_Verification_Code: 'Verification Code',
      Common_Required: 'Required',
    },
  },
  fr: {
    translation: {
      // eslint-disable-next-line prettier/prettier
      Common_Username: 'Nom d\'utilisateur',
      Common_Password: 'Mot de passe',
      Common_Remember_Me: 'Se souvenir de moi',
      Commom_Forgot_Password: 'Mot de passe oublié?',
      // eslint-disable-next-line prettier/prettier
      Common_Sign_In: 'S\'identifier',
      Common_Add_User_Prompt: 'Ajouter un utilisateur',
      Common_Edit_User_Prompt: 'Modifier l’utilisateur',
      Common_Required: 'Requis',
      Common_Phone_Number: 'numéro de téléphone',
      Common_Show_Password: 'Afficher le mot de passe',
      Common_Hide_Password: 'Cacher le mot de passe',
      //Common_Login_Prompt: 'Connectez-vous à votre compte',
    },
  },
  ru: {
    translation: {
      Common_Username: 'Логин',
      Common_Password: 'Пароль',
      Common_Remember_Me: 'Запомнить меня',
      Commom_Forgot_Password: 'Забыли пароль?',
      Common_Sign_In: 'Авторизоваться',
      Common_Required: 'обязательно',
      Common_New_Password: 'новый пароль',
      Common_Show_Password: 'Показать пароль',
      Common_Hide_Password: 'Скрыть пароль',
      Common_Add_User_Prompt: 'Добавить пользователя',
      Common_Edit_User_Prompt: 'Изменить пользователя',
      //Common_Login_Prompt: 'Войти в аккаунт',
    },
  },
};

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    fallbackLng: 'en',
    debug: false,
    resources,
    interpolation: {
      escapeValue: true,
    },
    lng: window.navigator.language,
  });

export default i18n;
