/* eslint-disable no-undef */
import { Auth } from '@aws-amplify/auth';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { Authenticator } from '@aws-amplify/ui-react';
import { toast } from 'react-toastify';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { Formik, Form, Field } from 'formik';
import {
  CognitoUserPool,
  CookieStorage,
  AuthenticationDetails,
  CognitoUser,
} from 'amazon-cognito-identity-js';

import fetchUser from 'api/users/fetchUser';
import loginEvent from 'api/auth/loginEvent';

import Logo from 'components/_common/Logo';
import PrimaryButton from 'components/_common/buttons/Primary';
import PrimaryLink from 'components/_common/links/Primary';

import { Mixpanel } from 'utils/mixpanel';
import { validateEmail, validateField } from 'utils/validateFields';

import NewPassword from './NewPassword';

const login = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [userLoggedIn, setUserLoggedIn] = useState(true);

  //let from = location.state?.from?.pathname || '/';

  const [newPassword, setNewPassword] = useState();

  function handleForgotPassword(e) {
    e.preventDefault();

    navigate('/reset');
  }

  document.title = 'Login | DeepDoc by AAICO';

  useEffect(() => {
    Auth.currentAuthenticatedUser({ bypassCache: true })
      .then(() => {
        //navigate(from, { replace: true });
      })
      .catch(err => {
        setUserLoggedIn(false);
      });
  }, []);

  const formSubmitHandler = async ({ username, password, newPassword }) => {
    setLoading(true);

    Auth.signIn(username, password)
      .then(async user => {
        if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
          if (!newPassword) {
            setNewPassword(true);

            toast.info('Please enter new password');

            setLoading(false);
            return;
          }
          try {
            const response = await Auth.completeNewPassword(
              user, // the Cognito User Object
              newPassword // the new password
            );
            if (response) {
              navigate('/upload');
            }
          } catch (err) {
            setLoading(false);
            Auth.signOut();

            toast.error(err.message);
          }
        } else if (
          user.challengeName === 'SMS_MFA' ||
          user.challengeName === 'SOFTWARE_TOKEN_MFA'
        ) {
          setLoading(false);
          //Need to setup for MFA/2FA
        } else {
          fetchUser(user.username)
            .then(() => {
              let userPool = new CognitoUserPool({
                UserPoolId: process.env.REACT_APP_COGNITO_USER_POOL,
                ClientId: process.env.REACT_APP_AWS_USER_POOLS_WEB_CLIENT_ID,
                Storage: new CookieStorage({
                  secure: false,
                  domain: process.env.REACT_APP_COOKIE_STORAGE_DOMAIN,
                }),
              });

              let authenticationDetails = new AuthenticationDetails({
                Username: username,
                Password: password,
              });

              let userData = {
                Username: username,
                Pool: userPool,
                Storage: new CookieStorage({
                  secure: true,
                  domain: process.env.REACT_APP_COOKIE_STORAGE_DOMAIN,
                }),
              };

              Mixpanel.identify(username);
              Mixpanel.track('Successful login');
              Mixpanel.people.set({
                $first_name: user.attributes.given_name,
                $last_name: user.attributes.family_name,
                $email: user.attributes.email,
              });

              loginEvent()
                .then(() => {
                  let cognitoUser = new CognitoUser(userData);

                  cognitoUser.authenticateUser(authenticationDetails, {
                    onSuccess: () => {
                      navigate('/upload');
                    },
                    onFailure: () => {
                      setLoading(false);
                      Auth.signOut();

                      toast.error('Please Contact Support');
                    },
                  });
                })
                .catch(() => {
                  Auth.signOut();

                  setLoading(false);
                });
            })
            .catch(() => {
              Auth.signOut();
              toast.error('Invalid User');
              Mixpanel.track('Unsuccessful login');
              setLoading(false);
            });
        }
      })
      .catch(error => {
        Mixpanel.track('Unsuccessful login');

        switch (error.code) {
          case 'UserNotConfirmedException':
            navigate(
              `/signup/confirm?action=confirmuser&user_name=${username}`
            );
            break;
          case 'NotAuthorizedException':
            toast.error(error.message);
            break;
          default:
            toast.error(error.message);
            break;
        }
        setLoading(false);
      });
  };

  function handleGoBack() {
    navigate('/upload');
  }

  return !userLoggedIn ? (
    <>
      <div className="d-none">
        <Authenticator />
      </div>
      <Formik
        initialValues={{
          code: '',
          username: '',
          password: '',
          newPassword: '',
        }}
        onSubmit={formSubmitHandler}
        enableReinitialize
      >
        {({ errors, touched }) => (
          <Form>
            <div className="text-center mb-4">
              <Logo />
            </div>
            <div className="mb-3 col text-center fs-6">
              {t('Common_Login_Prompt')}
            </div>
            <div className="mb-3 col text-center fs-6">
              <div id="pageError" className="invalid-feedback">
                Please choose a username.
              </div>
            </div>
            <div className="mb-3">
              <div className="input-group mb-3">
                <Field
                  type="text"
                  className={
                    touched.username && errors.username
                      ? 'form-control form-control-lg is-invalid rounded-2'
                      : 'form-control form-control-lg'
                  }
                  placeholder="Email"
                  aria-label="Email"
                  name="username"
                  validate={validateEmail}
                />
                {touched.username && errors.username && (
                  <div className="invalid-feedback text-center">
                    {errors.username}
                  </div>
                )}
              </div>
            </div>
            <div className="input-group mb-4">
              <Field
                type="password"
                className={
                  touched.password && errors.password
                    ? 'form-control form-control-lg is-invalid rounded-2'
                    : 'form-control form-control-lg'
                }
                placeholder={t('Common_Password')}
                aria-label={t('Common_Password')}
                name="password"
                validate={validateField}
              />
              {touched.password && errors.password && (
                <div className="invalid-feedback text-center">
                  {errors.password}
                </div>
              )}
            </div>
            {newPassword && <NewPassword touched={touched} errors={errors} />}
            <div className="row mb-4">
              <div className="col-12 text-center">
                <PrimaryLink href="" onClick={handleForgotPassword}>
                  {t('Commom_Forgot_Password')}
                </PrimaryLink>
              </div>
            </div>
            <div className="text-center mb-4">
              <PrimaryButton type="submit" processing={loading}>
                Log In with DeepDoc
              </PrimaryButton>
            </div>
            <div className="text-center fw-bold text-smaller">
              Dont have an account?{' '}
              <PrimaryLink href="/signup">Sign up</PrimaryLink> for free.
            </div>
          </Form>
        )}
      </Formik>
    </>
  ) : (
    <div className="text-center">
      <div className="mb-4">
        <Logo />
      </div>
      <div className="fs-4 pb-4">You are already logged in</div>
      <div className="">
        <PrimaryButton type="button" onClick={handleGoBack}>
          <FontAwesomeIcon icon={faArrowLeft} className="me-2" />
          Back
        </PrimaryButton>
      </div>
    </div>
  );
};

export default login;
