import { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';

import AlertMessage from 'components/_common/alertMessage';
import Page from 'components/_common/Page1';
import Filter from 'components/_common/Filter';

import filterEnum from 'components/_common/constants/jobFilterEnum';

import FileLobbyItems from './FileLobbyItems';
import Files from './Files';

const Jobs = () => {
  const [alertMessage, setAlertMessage] = useState();
  const [filter, setFilter] = useState(filterEnum.QUEUED);
  const [search, setSearch] = useState();
  const [myFiles, setMyFiles] = useState(true);

  document.title = 'My Jobs | DeepDoc by AAICO';

  useEffect(() => {
    setAlertMessage('');
  }, [filter]);

  function handleFilterClick(filter) {
    setFilter(parseInt(filter));
  }

  return (
    <>
      {alertMessage && (
        <AlertMessage
          type="error"
          message={alertMessage}
          showClose={false}
          dismissSeconds={10}
        />
      )}
      <Page
        title="My Jobs"
        subTitle="Please use the filter to view your jobs based on Queued, In Progress and Complete"
      />
      <div className="d-flex flex-column flex-md-row justify-content-between pb-4 pb-md-0">
        <div className="align-items-start pb-3">
          <div className="btn-group d-flex d-md-inline-flex" role="group">
            <button
              type="button"
              className={`btn btn-outline-secondary ${myFiles ? 'active' : ''}`}
              onClick={() => setMyFiles(true)}
            >
              Yours
            </button>
            <button
              type="button"
              className={`btn btn-outline-secondary ${
                !myFiles ? 'active' : ''
              }`}
              onClick={() => setMyFiles(false)}
            >
              Shared
            </button>
          </div>
        </div>
        <div className="align-items-start align-items-md-middle">
          <>
            <div className="input-group mb-3">
              <input
                id="search"
                type="text"
                className="form-control"
                placeholder="search job name"
                aria-label="search"
                onChange={e => setSearch(e.target.value)}
              />
              <button className="btn border" type="button">
                <FontAwesomeIcon icon={faSearch} />
              </button>
            </div>
          </>
        </div>
        <div className="align-items-start align-items-md-end">
          <Filter
            label="Filter by"
            onClick={handleFilterClick}
            defaultFilter={filter}
          />
        </div>
      </div>
      {filter === filterEnum.QUEUED && (
        <FileLobbyItems
          search={search}
          companyFiles={!myFiles}
          setAlertMessage={setAlertMessage}
        />
      )}
      {(filter === filterEnum.COMPLETE || filter === filterEnum.INPROGRESS) && (
        <Files
          search={search}
          filter={filter}
          companyFiles={!myFiles}
          setAlertMessage={setAlertMessage}
        />
      )}
    </>
  );
};

export default Jobs;
