import { useEffect, useState } from 'react';

import fetchSummaryStatuses from 'api/lookup/fetchSummaryStatuses';

import Page2 from 'components/_common/Page2';

import SummariesGrid from './Grid';
import Filters from './Filters';

const Summaries = () => {
  const [loading, setLoading] = useState(true);

  const [summaryStatuses, setSummaryStatuses] = useState();
  const [fileStatuses, setFileStatuses] = useState([
    { id: 30, name: 'Cloned' },
  ]);

  const [currentSummaryStatuses, setCurrentSummaryStatuses] = useState([
    1, 2, 3, 4,
  ]);
  const [currentFileStatuses, setCurrentFileStatuses] = useState([30]);

  document.title = 'Summaries | DeepDoc by AAICO';

  useEffect(() => {
    fetchSummaryStatuses().then(response => {
      response.sort((a, b) => {
        if (a.id > b.id) return 1;
        if (a.id < b.id) return -1;
        return 0;
      });

      setSummaryStatuses(response);
      setLoading(false);
    });
  }, []);

  const handleSummaryStatusClick = (e, id) => {
    e.preventDefault();

    if (summaryStatuses == null || summaryStatuses.length == 0) {
      setCurrentSummaryStatuses(a => [...a, id]);

      return;
    }

    var recordIndex = currentSummaryStatuses.findIndex(item => item == id);

    if (recordIndex >= 0) {
      setCurrentSummaryStatuses(a => [
        ...a.slice(0, recordIndex),
        ...a.slice(recordIndex + 1, a.length),
      ]);
    } else {
      setCurrentSummaryStatuses(a => [...a, id]);
    }
  };

  const handleFileStatusClick = (e, id) => {
    e.preventDefault();

    if (fileStatuses == null || fileStatuses.length == 0) {
      setCurrentFileStatuses(a => [...a, id]);

      return;
    }

    var recordIndex = currentFileStatuses.findIndex(item => item == id);

    if (recordIndex >= 0) {
      setCurrentFileStatuses(a => [
        ...a.slice(0, recordIndex),
        ...a.slice(recordIndex + 1, a.length),
      ]);
    } else {
      setCurrentFileStatuses(a => [...a, id]);
    }
  };

  return (
    !loading &&
    summaryStatuses &&
    fileStatuses && (
      <Page2 header="Summaries">
        <div className="border border-secondary">
          <div className="row">
            <div className="col-12">
              <Filters
                currentSummaryStatuses={currentSummaryStatuses}
                summaryStatuses={summaryStatuses}
                summaryStatusClick={handleSummaryStatusClick}
                currentFileStatuses={currentFileStatuses}
                fileStatuses={fileStatuses}
                fileStatusClick={handleFileStatusClick}
              />
            </div>
          </div>
          <div className="border-top border-secondary">
            <SummariesGrid
              currentSummaryStatuses={currentSummaryStatuses}
              currentFileStatuses={fileStatuses
                .map(({ id }) => id)
                .filter(item => !currentFileStatuses.includes(item))}
            />
          </div>
        </div>
      </Page2>
    )
  );
};

export default Summaries;
