import { bool, shape } from 'prop-types';
import { useEffect, useState } from 'react';

import {
  useConfigurationPackage,
  useConfigurationPackageUpdate,
} from 'components/Settings/Configuration/context/configurationPackageContext';

const Bookmarks = ({ section, readOnly }) => {
  const configurationPackage = useConfigurationPackage();
  const setConfigurationPackage = useConfigurationPackageUpdate();

  const [sectionToUpdate, setSectionToUpdate] = useState();

  useEffect(() => {
    setSectionToUpdate(
      configurationPackage.configuration.sections.find(
        item => item.id == section.id
      )
    );
  }, []);

  const handlePhysicianClick = () => {
    if (readOnly) {
      return;
    }

    sectionToUpdate.bookmarkPhysician = !sectionToUpdate.bookmarkPhysician;

    setConfigurationPackage(existing => ({
      ...existing,
      ...configurationPackage.configuration,
    }));
  };

  const handleFacilityClick = () => {
    if (readOnly) {
      return;
    }

    sectionToUpdate.bookmarkFacility = !sectionToUpdate.bookmarkFacility;

    setConfigurationPackage(existing => ({
      ...existing,
      ...configurationPackage.configuration,
    }));
  };

  return (
    <>
      <div className="text-muted pb-2 text-smaller">
        Include the following document details in Table of Contents and
        Bookmarks
      </div>
      <div className="d-flex">
        <div className="form-check form-switch pe-4 pb-2">
          <input
            className="form-check-input"
            type="checkbox"
            checked={section.bookmarkPhysician}
            id={`physician${section.id}`}
            onChange={handlePhysicianClick}
            disabled={readOnly}
          />
          <label
            className="form-check-label"
            htmlFor={`physician${section.id}`}
          >
            Physician Name
          </label>
        </div>
        <div className="form-check form-switch">
          <input
            className="form-check-input"
            type="checkbox"
            checked={section.bookmarkFacility}
            id={`facility${section.id}`}
            onChange={handleFacilityClick}
            disabled={readOnly}
          />
          <label className="form-check-label" htmlFor={`facility${section.id}`}>
            Facility Name
          </label>
        </div>
      </div>
    </>
  );
};

Bookmarks.defaultProps = {
  readOnly: false,
};

Bookmarks.propTypes = {
  section: shape({}).isRequired,
  readOnly: bool,
};

export default Bookmarks;
