import { func, oneOf, string } from 'prop-types';

import filterEnum from '../constants/jobFilterEnum';

const Filter = ({ label, defaultFilter, onClick, filterToSkip }) => {
  function handleClick(e) {
    e.preventDefault();

    onClick(e.target.value);
  }

  return (
    <div className="d-flex justify-content-end align-items-center">
      <div className="text-muted fs-6 fw-bold pe-2 text-nowrap">{label}</div>
      <select
        className="form-select form-select "
        aria-label="filter"
        defaultValue={defaultFilter}
        onChange={handleClick}
      >
        {(filterToSkip == undefined || filterToSkip != filterEnum.QUEUED) && (
          <option value={filterEnum.QUEUED}>Queued</option>
        )}
        {(filterToSkip == undefined ||
          filterToSkip != filterEnum.INPROGRESS) && (
          <option value={filterEnum.INPROGRESS}>In Progress</option>
        )}
        {(filterToSkip == undefined || filterToSkip != filterEnum.COMPLETE) && (
          <option value={filterEnum.COMPLETE}>Complete</option>
        )}
      </select>
    </div>
  );
};

Filter.defaultProps = {
  filterToSkip: undefined,
};

Filter.propTypes = {
  label: string.isRequired,
  defaultFilter: oneOf(Object.values(filterEnum)).isRequired,
  onClick: func.isRequired,
  filterToSkip: oneOf(Object.values(filterEnum)),
};

export default Filter;
