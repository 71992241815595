import { useEffect, useState } from 'react';
import { Outlet } from 'react-router-dom';
import { Offcanvas } from 'bootstrap/dist/js/bootstrap';
import { uniqueId } from 'lodash';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleXmark } from '@fortawesome/free-regular-svg-icons';

import { useNavigate } from 'react-router-dom/dist';

const Pages = () => {
  const navigate = useNavigate();
  const [id] = useState(uniqueId());

  useEffect(() => {
    const countdown = setTimeout(() => {
      var myOffcanvas = document.getElementById('canvas' + id);
      var bsOffcanvas = new Offcanvas(myOffcanvas, {
        backdrop: false,
        scroll: true,
      });
      bsOffcanvas.show();
    }, 500);

    return () => clearTimeout(countdown);
  }, []);

  function handleCloseClick(e) {
    e.preventDefault();

    navigate('/upload');
  }

  return (
    <div
      className="offcanvas offcanvas-end"
      tabIndex="-1"
      data-bs-backdrop="false"
      id={'canvas' + id}
      style={{ width: '95%', maxWidth: '115rem' }}
    >
      <div className="offcanvas-header pt-4 ps-4 pb-3">
        <a
          href=""
          aria-label="Close"
          onClick={e => handleCloseClick(e, 'canvas' + id)}
        >
          <FontAwesomeIcon icon={faCircleXmark} size="2x" />
        </a>
      </div>
      <div className="offcanvas-body">
        <div className="px-md-5 px-3">
          <Outlet />
        </div>
      </div>
    </div>
  );
};

export default Pages;
