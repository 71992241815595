import { useEffect, useRef, useState } from 'react';
import { func, string } from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserClock } from '@fortawesome/free-solid-svg-icons';

const Countdown = ({ endProcess, onTimerFinish }) => {
  const Ref = useRef(null);
  const [summaryLate, setSummaryLate] = useState(false);
  const [timer, setTimer] = useState({
    hours: '-',
    minutes: '-',
    seconds: '-',
  });

  Date.prototype.addHours = function (h) {
    this.setHours(this.getHours() + h);
    return this;
  };

  const getTimeRemaining = () => {
    let total;
    if (endProcess) {
      var x = new Date(endProcess);
      total = x.addHours(10) - new Date();
    }
    const seconds = Math.floor((total / 1000) % 60);
    const minutes = Math.floor((total / 1000 / 60) % 60);
    const hours = Math.floor((total / 1000 / 60 / 60) % 24);

    return {
      total,
      hours,
      minutes,
      seconds,
    };
  };

  useEffect(() => {
    if (endProcess == undefined) {
      return;
    }

    let id;
    if (getTimeRemaining().total > 0) {
      id = setInterval(() => {
        startTimer();
      }, 1000);

      Ref.current = id;
    } else {
      setSummaryLate(true);
    }

    return () => {
      if (id) {
        return clearInterval(id);
      }
    };
  }, []);

  const startTimer = () => {
    let { total, hours, minutes, seconds } = getTimeRemaining();

    if (total >= 0) {
      setTimer({
        hours: hours > 9 ? hours : '0' + hours,
        minutes: minutes > 9 ? minutes : '0' + minutes,
        seconds: seconds > 9 ? seconds : '0' + seconds,
      });
    }

    if (total < 0) {
      setSummaryLate(true);

      setTimer({
        hours: '-',
        minutes: '-',
        seconds: '-',
      });

      if (Ref.current) {
        clearInterval(Ref.current);

        if (onTimerFinish != undefined) {
          onTimerFinish();
        }
      }
    }
  };

  return !summaryLate ? (
    <>
      <div>Summary due in:</div>
      <div className="d-flex">
        <div
          className="bg-primary text-white p-1 m-1 fs-4 shadow text-center"
          style={{ width: '4rem', height: '4rem' }}
        >
          {timer.hours} <div style={{ fontSize: 'x-small' }}>hours</div>
        </div>
        <div
          className="bg-primary text-white p-1 m-1 fs-4 shadow text-center"
          style={{ width: '4rem', height: '4rem' }}
        >
          {timer.minutes}
          <div style={{ fontSize: 'x-small' }}>minutes</div>
        </div>
        <div
          className="bg-primary text-white p-1 m-1 fs-4 shadow text-center"
          style={{ width: '4rem', height: '4rem' }}
        >
          {timer.seconds}
          <div style={{ fontSize: 'x-small' }}>seconds</div>
        </div>
      </div>
      <div className="d-flex" />
    </>
  ) : (
    <div className="text-danger">
      <FontAwesomeIcon icon={faUserClock} size="2x" />
      <span className="fs-3 ps-2">Running Late</span>
    </div>
  );
};

Countdown.defaultProps = {
  endProcess: undefined,
  onTimerFinish: undefined,
};

Countdown.propTypes = {
  endProcess: string,
  onTimerFinish: func,
};

export default Countdown;
