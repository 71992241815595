// import image404 from 'images/404-error.png';

const NetworkError = () => {
  document.title = '404 - Page Not Found';

  return (
    <div className="row h-100 g-0">
      <div className="col-md-6 col-12">
        <div className="d-flex align-items-center h-100 ps-5">
          <div />
        </div>
      </div>
      <div className="col-md-6 col-12 d-lg-block d-none bg-light">
        <div className="d-flex flex-column align-items-center justify-content-center h-100">
          <div>
            {/* <img
              src={image404}
              style={{ height: '100%', maxHeight: '35rem' }}
              loading="lazy"
            /> */}
          </div>
          <br />
          <div>
            <h2 className="display-5">Page Not Found</h2>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NetworkError;
