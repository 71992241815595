import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { string } from 'prop-types';

import { Storage } from '@aws-amplify/storage';

import fetchByFileId from 'api/file/fetchByFileId';
import clone from 'api/fileLobby/clone';
import insertBatch from 'api/fileLobby/insertBatch';

import Primary from 'components/_common/buttons/Primary';

import convertFileToJob from 'converters/fileToJob';

import keepAlive from 'utils/keepAlive';
import { Mixpanel } from 'utils/mixpanel';

import { useJobToCloneUpdate } from '../context/jobToCloneContext';

import StepsEnum from './propTypes/constants/stepsEnum';

import Uploader from './Uploader';
import Legal from './Legal';
import Transfer from './Transfer';
import Finish from './Finish';

import './style.css';

const Wizard = ({ uploadId, cloneJobId }) => {
  const navigate = useNavigate();

  const [currentStep, setCurrentStep] = useState(StepsEnum.UPLOADER);
  const [filesToUpload, setFilesToUpload] = useState([]);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [filesUploaded, setFilesUploaded] = useState(0);
  const [cancelKey, setCancelKey] = useState();

  const [summarize, setSummarize] = useState(false);
  const [isExpress, setIsExpress] = useState(false);
  const [configId, setConfigId] = useState();
  const [jobName, setJobName] = useState('');
  const [message, setMessage] = useState('');
  const [legalConfirmed, setLegalConfirmed] = useState(false);

  const [canProceed, setCanProceed] = useState(false);

  const jobCloneUpdate = useJobToCloneUpdate();
  const [jobClone, setJobClone] = useState();

  useEffect(() => {
    setCanProceed(isValid());
  }, [jobName, filesToUpload, legalConfirmed, currentStep]);

  useEffect(() => {
    if (cloneJobId) {
      fetchByFileId(cloneJobId).then(file => {
        const job = convertFileToJob(file);

        jobCloneUpdate(job);
        setJobClone(job);
      });
    }
  }, [cloneJobId]);

  Storage.configure({
    customPrefix: {
      public: '',
      protected: 'protected/',
      private: 'private/',
    },
  });

  function handleNextStep() {
    switch (currentStep) {
      case StepsEnum.UPLOADER:
        setCurrentStep(StepsEnum.LEGAL);

        break;
      case StepsEnum.LEGAL:
        setCurrentStep(StepsEnum.TRANSFER);
        uploadFilesAsync();

        break;
      case StepsEnum.TRANSFER:
        setCurrentStep(StepsEnum.CANCEL);
        cancelUploads();

        break;
      case StepsEnum.CANCEL:
        break;
      case StepsEnum.FINISH:
        navigate('/jobs');

        break;
    }
  }

  function buttonText() {
    switch (currentStep) {
      case StepsEnum.UPLOADER:
      case StepsEnum.LEGAL:
        return 'Transfer';
      case StepsEnum.TRANSFER:
        return 'Cancel';
      case StepsEnum.FINISH:
        return 'Continue';
      default:
        return 'Transfer';
    }
  }

  function isValid() {
    switch (currentStep) {
      case StepsEnum.UPLOADER:
        if (
          jobName === '' ||
          filesToUpload.length === 0 ||
          filesToUpload.filter(item => {
            return !item.existing;
          }).length === 0
        ) {
          return false;
        }

        break;
      case StepsEnum.LEGAL:
        if (!legalConfirmed) {
          return false;
        }
        break;
    }

    return true;
  }

  const cancelUploads = async () => {
    if (cancelKey) {
      const promise = Storage.get(cancelKey);

      Storage.cancel(promise, 'my message for cancellation');

      try {
        await promise;
      } catch (error) {
        // We can confirm the error is thrown by the cancellation here
        if (Storage.isCancelError(error)) {
          console.error(error.message); // "my message for cancellation"
        }
      }

      navigate('/upload?', { replace: true });
    }
  };

  const uploadFilesAsync = async () => {
    try {
      let completeBytes = [];
      let progressPercent = 0;
      let fileCounter = filesToUpload.filter(item => {
        return item.existing;
      }).length;

      const filteredList = filesToUpload.filter(item => {
        return !item.existing;
      });

      const totalBytes = filteredList.reduce((n, { bytes }) => n + bytes, 0);

      for (let i = 0; i < filteredList.length; i++) {
        setFilesUploaded(i);
        await Storage.put(
          `${uploadId}/${filteredList[i].fileName}`,
          filteredList[i].file,
          {
            contentType: filteredList[i].type,
            progressCallback(progress) {
              keepAlive();

              if ('key' in progress) {
                setCancelKey(progress.key);

                completeBytes[i] = progress.loaded;
              } else {
                completeBytes[i] = filteredList[i].bytes;

                // fileCounter++;
                //setFilesUploaded(fileCounter);
              }

              progressPercent = Math.round(
                (completeBytes.reduce((a, b) => a + b, 0) / totalBytes) * 100
              );

              if (fileCounter === filteredList.length) {
                progressPercent = 100;
              }

              setUploadProgress(progressPercent);
            },
          }
        );

        //Deletes file and type properties from object
        delete filteredList[i].file;
        delete filteredList[i].type;
      }

      const responsePackage = {
        files: filteredList,
        jobType: summarize ? 'summarize' : 'organize',
        isExpress: isExpress,
        configId: configId,
        jobName: jobName,
        message: message,
      };

      if (jobClone) {
        var response = await clone(jobClone.fileGuid, uploadId);

        responsePackage.parentId = jobClone.id;
        if (response && response.length > 0) {
          response.map(cloneFile => {
            responsePackage.files.push({
              fileName: cloneFile.filename,
              original_fileName: cloneFile.originalFilename,
              bytes: cloneFile.size,
              bucket: 'file-lobby',
              key: cloneFile.key,
            });
          });
        }
      }

      const batchObjJSON = JSON.stringify(responsePackage);

      await Storage.put(`${uploadId}/${uploadId}.json`, batchObjJSON, {
        level: 'public',
        contentType: 'application/json',
      });

      handleUploadComplete(responsePackage);
    } catch (error) {
      console.error('Error uploading file: ', error);
    }
  };

  const handleUploadComplete = responsePackage => {
    const postData = {
      batchId: uploadId,
      config: JSON.stringify(responsePackage),
    };

    insertBatch(JSON.stringify(postData));

    Mixpanel.track('Upload complete', { batchId: uploadId });

    setTimeout(() => {
      setCurrentStep(StepsEnum.FINISH);
    }, 3000);
  };

  return (
    <form>
      <div className="uploader shadow">
        {currentStep == StepsEnum.UPLOADER && (
          <Uploader
            isExpress={isExpress}
            setIsExpress={setIsExpress}
            configId={configId}
            setConfigId={setConfigId}
            jobName={jobName}
            setJobName={setJobName}
            message={message}
            setMessage={setMessage}
            summarize={summarize}
            setSummarize={setSummarize}
            filesToUpload={filesToUpload}
            setFilesToUpload={setFilesToUpload}
            fileBatchId={uploadId}
          />
        )}
        {currentStep == StepsEnum.LEGAL && (
          <Legal
            onClickBack={() => {
              setCurrentStep(StepsEnum.UPLOADER);
            }}
            onClickConfirm={e => {
              setLegalConfirmed(e);
              isValid();
            }}
            title={'Legal Notice'}
            formCheckLabel={'I confirm that:'}
          >
            <ul className="mt-1">
              <li>
                I am authorized by my employer to submit this Job and incur the
                associated fees to my employer&#39;s account
              </li>
              <li>
                I have uploaded all relevant files for the Job and understand
                that I won&#39;t be able to make any changes or upload any
                additional files while the Job is being completed
              </li>
              <li>
                I understand that I have a fifteen (15) minute grace period,
                from confirmation of the Job below, during which I may cancel
                this Job, after which the Job shall be non-refundable; and
              </li>
              <li>
                I have read and agree to the&nbsp;
                <a
                  href="https://assets.deepdoc.net/documents/DeepDoc_Terms_of_Service_2023.03.08.pdf"
                  target="_blank"
                  rel="noreferrer"
                >
                  DeepDoc Terms of of Service&nbsp;
                </a>
                and&nbsp;
                <a
                  href="https://assets.deepdoc.net/documents/DeepDoc_Business_Associate_Agreement_2023.03.08.pdf"
                  target="_blank"
                  rel="noreferrer"
                >
                  Business Associate Agreement
                </a>
              </li>
            </ul>
          </Legal>
        )}
        {(currentStep == StepsEnum.TRANSFER ||
          currentStep == StepsEnum.CANCEL) && (
          <Transfer
            percentComplete={uploadProgress ?? 0}
            filesCount={filesToUpload.length}
            filesUploaded={filesUploaded + 1}
            totalBytes={filesToUpload.reduce((n, { bytes }) => n + bytes, 0)}
          />
        )}
        {currentStep == StepsEnum.FINISH && <Finish />}
        <div className="uploader-bottom">
          {currentStep == StepsEnum.CANCEL && (
            <div className="d-flex justify-content-evenly">
              <Primary
                type="button"
                onClick={() => setCurrentStep(StepsEnum.TRANSFER)}
              >
                No
              </Primary>
              <Primary type="button" onClick={() => cancelUploads()}>
                Yes
              </Primary>
            </div>
          )}
          {currentStep != StepsEnum.CANCEL && (
            <Primary
              type="button"
              onClick={() => handleNextStep()}
              disabled={!canProceed}
            >
              {buttonText()}
            </Primary>
          )}
        </div>
      </div>
    </form>
  );
};

Wizard.defaultProps = {
  cloneJobId: undefined,
};

Wizard.propTypes = {
  uploadId: string.isRequired,
  cloneJobId: string,
};

export default Wizard;
