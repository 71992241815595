import { arrayOf, func, number, shape } from 'prop-types';
import FileStatuses from './FileStatuses';
import SummaryStatuses from './SummaryStatuses';

const Filters = ({
  summaryStatuses,
  currentSummaryStatuses,
  summaryStatusClick,
  fileStatuses,
  currentFileStatuses,
  fileStatusClick,
}) => {
  return (
    <div className="bg-white pb-3">
      <div className="d-flex p-3 bg-light">
        <div className="fs-5">Filters</div>
      </div>
      <div className="d-flex flex-wrap">
        <div className="p-4 pb-sm-3">
          <SummaryStatuses
            statuses={summaryStatuses}
            currentStatuses={currentSummaryStatuses}
            statusClick={summaryStatusClick}
          />
        </div>
        <div className="p-4 pb-sm-3">
          <FileStatuses
            statuses={fileStatuses}
            currentStatuses={currentFileStatuses}
            statusClick={fileStatusClick}
          />
        </div>
      </div>
    </div>
  );
};

Filters.defaultProps = {
  currentSummaryStatuses: [],
  currentFileStatuses: [],
};

Filters.propTypes = {
  summaryStatuses: arrayOf(shape({})).isRequired,
  currentSummaryStatuses: arrayOf(number),
  summaryStatusClick: func.isRequired,
  fileStatuses: arrayOf(shape({})).isRequired,
  currentFileStatuses: arrayOf(number),
  fileStatusClick: func.isRequired,
};

export default Filters;
