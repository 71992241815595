import executeApiCall from 'utils/executeApiCall';

export default function (data) {
  try {
    const endpoint = '/api/configuration/';

    return executeApiCall(endpoint, {
      method: 'POST',
      data: data,
    }).then(response => {
      return response.data;
    });
  } catch (error) {
    return Promise.reject(error);
  }
}
