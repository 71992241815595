import { bool, number, shape, string } from 'prop-types';

export default shape({
  id: number,
  cognitoId: string,
  email: string,
  firstName: string,
  lastName: string,
  companyId: number,
  isActive: bool,
  phone: string,
});
