import axios from 'axios';
import { Auth } from '@aws-amplify/auth';
import { toast } from 'react-toastify';

//import HandleApiError from './handleApiError';

export function executeAnonymousApiCall(endpoint, config) {
  return baseUrl().then(response => {
    const derivedConfig = {
      displayAlert: true,
      url: response.data + endpoint,
      headers: {
        'Content-Type': 'application/json',
      },
      ...config,
    };

    return makeApiCall(derivedConfig);
  });
}

function baseUrl() {
  const config = {
    url: '/api/configuration?value=baseUrl',
    headers: {
      'Content-Type': 'application/json',
    },
  };

  return makeApiCall(config, true);
}

export function executeLocalApiCall(endpoint, config) {
  const derivedConfig = {
    url: endpoint,
    headers: {
      'Content-Type': 'application/json',
    },
    ...config,
  };

  return makeApiCall(derivedConfig);
}

export default function executeApiCall(endpoint, config) {
  return baseUrl().then(response => {
    return Auth.currentSession().then(res => {
      let accessToken = res.getAccessToken();
      let jwt = accessToken.getJwtToken();
      var headers = {
        headers: { 'Content-Type': 'application/json', Authorization: jwt },
      };

      const derivedConfig = {
        displayAlert: true,
        url: response.data + endpoint,
        ...config,
        ...headers,
      };

      return makeApiCall(derivedConfig);
    });
  });
}

function makeApiCall(config) {
  return axios(config)
    .then(response => response)
    .catch(error => {
      if (config.displayAlert) {
        if (error.response && 'status' in error.response) {
          switch (error.response.status) {
            case 400:
              if ('errors' in error.response.data) {
                if (error.response.data.errors.length > 0) {
                  switch (error.response.data.errors[0].code) {
                    case 9: // Invalid user
                      toast.error('You do not belong to this environment', {
                        autoClose: 10000,
                      });
                      break;
                    case 14: //invalid user
                      toast.error('"User doesn\'t belong to this company."');
                      break;
                    case 16: // Invalid Manager
                      toast.error('Only an admin can complete the summary.', {
                        autoClose: 10000,
                      });
                      break;
                    case 17: //invalid summarizer
                      toast.error(
                        'You are not an authorized summarizer for this job'
                      );
                      break;
                    case 20: // Invalid File
                      toast.error('Invalid job, not found', {
                        autoClose: 10000,
                      });
                      break;
                    case 21: // Invalid Summary
                      toast.error('Summary is invalid or not found.', {
                        autoClose: 10000,
                      });
                      break;
                    case 22: // Invalid Roles
                      toast.error(
                        'Only an admin or assigned summarizer can initialize a summary.'
                      );
                      break;
                    case 23: // AiSummaryMissing
                      break;
                    case 26: //Invalid Phone Number Format
                      toast.error('Invalid phone number format');
                      break;
                    case 27: //Username Already Exists
                      toast.error('Username Already Exists');
                      break;
                    case 28: //Too Many Requests
                      toast.error('Too many requests. Please try again later.');
                      break;
                    case 29: //User Not Found
                      toast.error('User not found');
                      break;
                  }
                }
              }
              break;
            case 500:
              if (
                error.code == 'ERR_NETWORK' ||
                error.code == 'ERR_BAD_RESPONSE'
              ) {
                return toast.error('Network Error', { autoClose: 10000 });
              }
              break;
          }
        }
      }
      throw error;
    });
}
