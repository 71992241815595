import { node } from 'prop-types';
import { createContext, useContext, useState } from 'react';

const ConfigurationPackageContext = createContext();
const ConfigurationPackageUpdateContext = createContext();

export function useConfigurationPackage() {
  return useContext(ConfigurationPackageContext);
}

export function useConfigurationPackageUpdate() {
  return useContext(ConfigurationPackageUpdateContext);
}

export function ConfigurationPackageProvider({ children }) {
  const [configuration, setConfiguration] = useState();

  return (
    <ConfigurationPackageContext.Provider value={configuration}>
      <ConfigurationPackageUpdateContext.Provider value={setConfiguration}>
        {children}
      </ConfigurationPackageUpdateContext.Provider>
    </ConfigurationPackageContext.Provider>
  );
}

ConfigurationPackageProvider.propTypes = {
  children: node.isRequired,
};
