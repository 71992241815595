import { useLocation, Navigate } from 'react-router-dom';
import { node } from 'prop-types';
import { Auth } from '@aws-amplify/auth';
import { useEffect, useState } from 'react';

export function RequireAuth({ children }) {
  const location = useLocation();
  const [authenticated, setAuthenticated] = useState(undefined);

  const isLoggedIn = () => {
    Auth.currentAuthenticatedUser()
      .then(() => {
        setAuthenticated(true);
      })
      .catch(() => {
        setAuthenticated(false);
      });
  };

  useEffect(() => {
    isLoggedIn();
  }, []);

  return (
    authenticated !== undefined && (
      <>
        {authenticated && children}
        {!authenticated && (
          <Navigate to="/login" state={{ from: location }} replace />
        )}
      </>
    )
  );
}

RequireAuth.propTypes = {
  children: node.isRequired,
};

export default RequireAuth;
