import { useEffect, useState } from 'react';

import fetchPipelines from 'api/pipelines/fetchPipelines';

import Page2 from 'components/_common/Page2';

const Pipelines = () => {
  const [pipelines, setPipelines] = useState();

  document.title = 'Pipelines | DeepDoc by AAICO';

  useEffect(() => {
    fetchPipelines().then(response => {
      setPipelines(response);
    });
  }, []);

  return (
    <>
      {pipelines && (
        <Page2 header="Pipelines">
          <table className="table">
            <thead>
              <tr>
                <th scope="col">Name</th>
                <th scope="col">Bucket</th>
                <th scope="col">Endpoint</th>
                <th scope="col" className="text-center">
                  Company Id
                </th>
                <th scope="col">Domain</th>
              </tr>
            </thead>
            <tbody>
              {pipelines.map((p, idx) => (
                <tr key={idx}>
                  <td>{p.nameFamiliar}</td>
                  <td>{p.nameBackend}</td>
                  <td>{p.hilApiEndpoint}</td>
                  <td className="text-center">{p.companyId}</td>
                  <td>{p.domain}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </Page2>
      )}
    </>
  );
};

export default Pipelines;
