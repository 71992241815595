import { faDownload } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { func } from 'prop-types';
import { useState } from 'react';
import { toast } from 'react-toastify';

import PrimaryLink from 'components/_common/links/Primary';
import Uploader from 'components/_common/Uploader';

import summaryStatusEnum from 'components/_common/constants/summaryStatusEnum';

import uploadSummaryFile from 'api/summaries/uploadSummaryFile';

import summaryPropType from 'propTypes/summaryPropType';

import ConfirmPopup from '../_common/ConfirmPopup';

const Working = ({ summary, onDownloadClick, onSaveClick }) => {
  const [loading, setLoading] = useState(false);

  const handleDownloadClick = e => {
    e.preventDefault();

    onDownloadClick('working');
  };

  const handleSaveClick = e => {
    if (e) {
      e.preventDefault();
    }

    onSaveClick(e, summaryStatusEnum.AWAITING_APPROVAL, setLoading);
  };

  const handleFiles = files => {
    toast.promise(uploadSummaryFile(summary.summaryGuid, files), {
      pending: 'Uploading summary...',
      success: 'Summary has been uploaded successfully',
      error: 'There was a problem uploading summary',
    });
  };

  return (
    <div className="card">
      <div className="card-body">
        <div className="card-title">
          <span className="fs-5">Working Summary</span>
        </div>
        <div className="pb-3">
          <PrimaryLink
            onClick={handleDownloadClick}
            title="Download working summary"
            href=""
          >
            <FontAwesomeIcon icon={faDownload} className="pe-2" />
            Download
          </PrimaryLink>
        </div>
        {summary.status == summaryStatusEnum.IN_PROGRESS && (
          <>
            <div className="pb-3">
              <Uploader
                caption="Upload"
                title="Upload working summary"
                onFileChange={handleFiles}
                accept=".docx"
              />
            </div>
            <ConfirmPopup
              prompt="Do you want to submit this summary for approval?"
              caption="Ready For Approval"
              processing={loading}
              onSaveClick={handleSaveClick}
            />
          </>
        )}
      </div>
    </div>
  );
};

Working.propTypes = {
  summary: summaryPropType.isRequired,
  onDownloadClick: func.isRequired,
  onSaveClick: func.isRequired,
};

export default Working;
