import { bool, shape } from 'prop-types';

import { useConfigurationPackage } from 'components/Settings/Configuration/context/configurationPackageContext';

import AddDocument from './AddDocument';
import Document from './Document';
import Bookmarks from './Bookmarks';
import Header from './Header';

const Section = ({ section, readOnly }) => {
  const configurationPackage = useConfigurationPackage();

  return (
    <div className="border bg-white mb-3 shadow-sm">
      <Header section={section} readOnly={readOnly} />
      <div>
        <div className="p-3">
          <div className="row">
            <div className="col-6">
              <div className="px-2">
                <div className="pb-2 border-bottom d-flex justify-content-between">
                  <div className="fw-bold text-muted ">Documents</div>
                  <AddDocument sectionId={section.id} readOnly={readOnly} />
                </div>
                <div>
                  {section.documents &&
                    section.documents.map(doc => (
                      <Document
                        key={doc.documentTypeId}
                        document={doc}
                        title={
                          configurationPackage.types.find(
                            type => type.id == doc.documentTypeId
                          ).name
                        }
                        readOnly={readOnly}
                      />
                    ))}
                  {(section.documents == undefined ||
                    section.documents.length == 0) && (
                    <div className="p-3 text-center">
                      This section currently has no document types allocated to
                      it!
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className="col-6 border-start">
              <div className="px-2">
                <div className="pb-2 fw-bold text-muted border-bottom">
                  Table of Contents & Bookmarks
                </div>
                <div className="py-2">
                  <Bookmarks section={section} readOnly={readOnly} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

Section.defaultProps = { readOnly: false };

Section.propTypes = {
  section: shape({}).isRequired,
  readOnly: bool,
};

export default Section;
