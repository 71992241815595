const convertFileToJob = file => {
  var job = {
    id: file.id,
    fileGuid: file.fileGuid,
    batchId: undefined,
    isCancelled: false,
    inProcess: false,
    startProcess: undefined,
    userId: file.userId,
    user: file.user,
    analystId: file.analystId,
    analyst: file.analyst,
    summarizerId: file.summarizerId,
    summarizer: file.summarizer,
    createdAt: file.createdAt,
    jobName: file.jobName ?? file.originalName,
    jobType: file.summarize ? 'Organize & Summarize' : 'Organize',
    message: file.instructions,
    isExpress: file.rush,
    original: file.originalName,
    size: file.fileSize,
    pageCount: file.pageCount,
    statusId: file.currentStatus.statusId,
    summarize: file.summarize,
    jobFiles: [],
    parentId: file.parentId,
    configName: file.configName,
  };

  if (file.jobFiles && file.jobFiles.length > 0) {
    file.jobFiles.map(jobFile => {
      job.jobFiles.push({
        size: jobFile.size,
        filename: jobFile.name,
        original: jobFile.name,
      });
    });
  }

  return job;
};

export default convertFileToJob;
