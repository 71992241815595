import executeApiCall from 'utils/executeApiCall';

export default function (id, files) {
  try {
    const endpoint = `/api/summaries/${id}/file`;

    let formData = new FormData();
    formData.append('file', files[0]);

    return executeApiCall(endpoint, {
      method: 'POST',
      data: formData,
      headers: { 'Content-Type': 'multipart/form-data' },
    }).then(response => {
      return response.data;
    });
  } catch (error) {
    return Promise.reject(error);
  }
}
