import { node } from 'prop-types';
import { createContext, useContext, useState } from 'react';

const JobToCloneContext = createContext();
const JobToCloneUpdateContext = createContext();

export function useJobToClone() {
  return useContext(JobToCloneContext);
}

export function useJobToCloneUpdate() {
  return useContext(JobToCloneUpdateContext);
}

export function JobToCloneProvider({ children }) {
  const [userInfo, setUserInfo] = useState();

  return (
    <JobToCloneContext.Provider value={userInfo}>
      <JobToCloneUpdateContext.Provider value={setUserInfo}>
        {children}
      </JobToCloneUpdateContext.Provider>
    </JobToCloneContext.Provider>
  );
}

JobToCloneProvider.propTypes = {
  children: node.isRequired,
};
