import executeApiCall from 'utils/executeApiCall';

export default function (user_id, data) {
  try {
    const endpoint = `/api/users/${user_id}/roles`;

    return executeApiCall(endpoint, {
      method: 'POST',
      data: data,
    }).then(response => {
      return response.data;
    });
  } catch (error) {
    return Promise.reject(error);
  }
}
